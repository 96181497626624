import React from 'react'
import styles from './PagePreloader.module.scss'

const PagePreloader = () =>
{
    return (
        <div className={styles.pagePreloaderWrapper} />
    )
}

export default PagePreloader