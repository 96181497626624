import {RemoveScroll} from "react-remove-scroll";
import {Box, Modal, useIsFocusVisible} from "@mui/material";
import styles from './styles.module.css'
import ModalHeader from "../ModalHeader";
import React, {forwardRef, useEffect, useState, useMemo} from "react";
import {useDetectKeyboardOpen} from "../../../components/utils/hooks";
import cn from "classnames";

const BottomModal = forwardRef(({onClose = () => {}, title='', ...props}, ref) => {

    const isIos = useMemo(() =>
    {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return [
                'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }

        return false;
    }, [])

    const isKeyboardOpen = useDetectKeyboardOpen();
    const [mounted, setMounted] = useState(false)

    useEffect(()=>{
        setTimeout(()=>setMounted(true), 100)
        return () =>{
            setMounted(false)
        }
    }, [])

    const boxStyle = useMemo(() =>
    {
        /*
        if (isKeyboardOpen && props.useKeyboardDetect && isIos) {
            if (props.init) {
                return {maxHeight: 'calc(100vh - 18px - ' + parseInt(isKeyboardOpen) + 'px)', marginBottom: Math.ceil(isKeyboardOpen) + 'px'};
            }
            return {maxHeight: 'calc(100vh - 18px - ' + parseInt(isKeyboardOpen) + 'px)'};
        }

        if (isKeyboardOpen && props.useKeyboardDetect && !isIos) {
            return {maxHeight: 'calc(100vh - 40px - ' + parseInt(isKeyboardOpen) + 'px)', bottom: parseInt(isKeyboardOpen) + 'px !important'};
        }
        */

        if (props.maxHeight) {
            return {maxHeight: props.maxHeight};
        }

        return {maxHeight: 'initial'};

    }, [isKeyboardOpen, props.useKeyboardDetect, props.maxHeight, isIos])

    return (
        <RemoveScroll>
            <Modal open={true}
                className={styles.backdrop}
                onClose={onClose}
            >
                <Box 
                    style={ boxStyle }
                    className={cn(styles.container, mounted && styles.mounted)}
                >
                    <ModalHeader onClose={onClose} title={title}/>
                    <div ref={ref} className={cn(styles.contentContainer, props.contentContainerClassName)}>
                        
                        {props.children}
                    </div>
                </Box>
            </Modal>
        </RemoveScroll>
    );
})

export default BottomModal
