import vanillaTextMask from 'vanilla-text-mask'
import {useEffect, useRef, useState} from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import sendRequest from "../../api";

export function useDetectKeyboardOpen (minKeyboardHeight = 300, defaultValue) {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);

    useEffect(() => {
        const listener = () => {
            const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height
            if (isKeyboardOpen != newState) {
                setIsKeyboardOpen(newState ? window.visualViewport.height : false);
            }
        };
        window.visualViewport.addEventListener('resize', listener);
        return () => {
            window.visualViewport.removeEventListener('resize', listener);
        };
    }, []);

    return isKeyboardOpen;
}

export function useTextMask(input, options) {
    useEffect(function () {
        const handler = input && vanillaTextMask(Object.assign({}, {inputElement: input}, options));

        return function () {
            if (handler) {
                handler.destroy();
            }
        };
    }, [input]);
}

export function useWindowDimensions() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        handleResize()
        return () => {
            try {
                window.removeEventListener('resize', handleResize)
            } catch (e) {
            }

        };
    }, []);

    return width;
}

export const useEscape = (className, callback) => {
    const containerRef = useRef(null);

    const onOuterClick = (e) => {
        if (e?.target === document.querySelector(`.${className}`)) {
            callback();
        }
    };

    const onEscape = (e) => {
        if (e.keyCode === 27) callback();
    };

    useEffect(() => {
        if (!containerRef.current) return;
        const el = containerRef.current;
        el.addEventListener('click', onOuterClick);
        document.addEventListener('keydown', onEscape);
        return () => {
            el.removeEventListener('click', onOuterClick);
            document.removeEventListener('keydown', onEscape);
        }
    }, [containerRef.current]);

    return containerRef;
};

export function useCoolDown(callback = (...args) => {}, timeout=600, async=false) {

    const timer = useRef(null)

    const [pending, setPending] = useState(false)

    const modifiedCallback = (...args) => {
        if (timer.current)
            clearTimeout(timer.current)

        timer.current = setTimeout(async () => {
            setPending(true)
            const result =  await callback(...args)
            setPending(false)
            return result
        }, timeout)
    }

    return async ? [modifiedCallback, pending] : modifiedCallback
}

export function useLastOrder () {
    const orders = useSelector(state => state.user?.orders?.items)
    const user = useSelector(state=>state.user)

    const [estTime, setEstTime] = useState(false)
    const [id, setId] = useState(false)
    const [extras, setExtras] = useState({})

    async function loadOrder (_id) {
        const payload = {
            apikey: user.apikey,
            id: _id
        }
        const response = await sendRequest('getOrderData', payload)
        if (response.order) {

            setEstTime(response.order?.COURRIER?.arrival_time || false)
            setId(_id)
            setExtras(response.order)
        }
    }


    const lastOrder = orders.find((order, index)=> {
        const orderAssemblyTimeEst = moment(order.deliveryDate+' '+(order.TIME.split('-')[0] || '00:00'), 'YYYY-MM-DD HH:mm')
        const visibilityInterval = [
            moment().subtract(1, 'hours'),
            moment().add(1, "hours"),
        ]
        const isNear = (order?.newStatus === 'created' || order?.newStatus === 'handed_over_for_picking' && orderAssemblyTimeEst.isBetween(...visibilityInterval)) || (order.newStatus === 'on_the_way')

        return (index === 0 && isNear)
    })

    useEffect(()=>{
        if (lastOrder?.ID)
            loadOrder(lastOrder.ID)
    }, [lastOrder?.ID, orders])

    if (!orders) return null

    if (!lastOrder) return null
    return {...lastOrder, arrival_time: id === lastOrder.ID ? estTime : false, ...extras}


}

export function getTimeBeforeAssembly (order) {

    const orderAssemblyTimeEst = moment(order.DATE+' '+(order.TIME.split('-')[0] || '00:00'),
        order.DATE?.split('-')?.[0]?.length === 4 ? 'YYYY-MM-DD HH:mm' :'DD-MM-YYYY HH:mm')
    return Math.max(0, orderAssemblyTimeEst.diff(moment(), 'minutes')-10)
}