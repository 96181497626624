import React from 'react'
import styles from './Pay.module.scss'
import SText from "../../../components/SText";
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import Colors from "../../../components/utils/Colors";

const Pay = () => {

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'Ðž Ð½Ð°Ñ'}</SText></div>
        </div>
        <div className={styles.container}>
            <div style={{marginBottom: 30}}><SText size={36} lineHeight={36} weight={500}>{'ÐžÐ¿Ð»Ð°Ñ‚Ð°'}</SText></div>
            <div style={{marginBottom: 10, marginLeft: 40}}>
                <SText>{'Ð‘Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ð¾Ð¹ Ð¿Ñ€Ð¸ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ð¸ Ð·Ð°ÐºÐ°Ð·Ð° Ð² Ð¸Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚-Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ. ÐŸÑ€Ð¸ Ð´Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ð¸ ÐºÐ°Ñ€Ñ‚Ñ‹, Ñ‡Ñ‚Ð¾Ð±Ñ‹ Ð¿Ñ€Ð¾Ð²ÐµÑ€Ð¸Ñ‚ÑŒ ÐºÐ¾Ñ€Ñ€ÐµÐºÑ‚Ð½Ð¾ÑÑ‚ÑŒ Ð²Ð²ÐµÐ´ÐµÐ½Ð½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ…, 11 Ñ€ÑƒÐ±Ð»ÐµÐ¹ Ñ…Ð¾Ð»Ð´Ð¸Ñ€ÑƒÐµÑ‚ÑÑ (Ð·Ð°Ð¼Ð¾Ñ€Ð°Ð¶Ð¸Ð²Ð°ÐµÑ‚ÑÑ) Ñƒ Ð²Ð°Ñ Ð½Ð° ÑÑ‡ÐµÑ‚Ñƒ, Ð° Ð·Ð°Ñ‚ÐµÐ¼ Ð°Ð²Ñ‚Ð¾Ð¼Ð°Ñ‚Ð¸Ñ‡ÐµÑÐºÐ¸ Ð²Ð¾Ð·Ð²Ñ€Ð°Ñ‰Ð°ÐµÑ‚ÑÑ.'}</SText>
            </div>
            <div style={{marginBottom: 10, marginLeft: 40}}><SText>{'ÐžÐ¿Ð»Ð°Ñ‚Ð° ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ Ð½Ð°Ð»Ð¸Ñ‡Ð½Ñ‹Ð¼Ð¸.'}</SText></div>
            <div style={{marginBottom: 20, marginLeft: 40}}>
                <SText>{'ÐžÐ¿Ð»Ð°Ñ‚Ð° ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ ÐºÐ°Ñ€Ñ‚Ð¾Ð¹ Ñ Ð¿Ð¾Ð¼Ð¾Ñ‰ÑŒÑŽ QR- ÐºÐ¾Ð´Ð° Ð½Ð° Ð½Ð°ÐºÐ»Ð°Ð´Ð½Ð¾Ð¹ Ð¸Ð»Ð¸ Ð¿Ð¾ ÑÑÑ‹Ð»ÐºÐµ.'}</SText></div>
            <div style={{marginBottom: 20}}><SText size={18} weight={700}
                                                   lineHeight={18}>{'Ð¤Ð°ÐºÑ‚Ð¸Ñ‡ÐµÑÐºÐ°Ñ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð·Ð°ÐºÐ°Ð·Ð° Ð² Ð¾Ð½Ð»Ð°Ð¹Ð½-Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ Ð¼Ð¾Ð¶ÐµÑ‚ Ð¾Ñ‚Ð»Ð¸Ñ‡Ð°Ñ‚ÑŒÑÑ Ð¾Ñ‚ Ð¿Ñ€ÐµÐ´Ð²Ð°Ñ€Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð¾Ð¹ Ð² ÑÐ»ÑƒÑ‡Ð°Ðµ:'}</SText>
            </div>
            <div style={{marginBottom: 10, marginLeft: 40}}><SText>{'Ð—Ð°Ð¼ÐµÐ½Ñ‹ Ð¸Ð»Ð¸ Ð¾Ñ‚ÑÑƒÑ‚ÑÑ‚Ð²Ð¸Ñ Ð½ÐµÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ñ… Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²'}</SText>
            </div>
            <div style={{marginBottom: 10, marginLeft: 40}}>
                <SText>{'ÐŸÑ€Ð¸ÑÑƒÑ‚ÑÑ‚Ð²Ð¸Ñ Ð² Ð·Ð°ÐºÐ°Ð·Ðµ Ð²ÐµÑÐ¾Ð²Ñ‹Ñ… Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð². ÐÐ° ÑÐ°Ð¹Ñ‚Ðµ ÑƒÐºÐ°Ð·Ð°Ð½Ð° Ñ‚Ð¸Ð¿Ð¸Ñ‡Ð½Ð°Ñ Ñ†ÐµÐ½Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð², Ð¿Ñ€Ð¾Ð´Ð°Ð²Ð°ÐµÐ¼Ñ‹Ñ… Ð½Ð° Ð²ÐµÑ, ÐºÐ¾Ñ‚Ð¾Ñ€Ð°Ñ Ð¼Ð¾Ð¶ÐµÑ‚ Ð±Ñ‹Ñ‚ÑŒ Ð¼ÐµÐ½ÑŒÑˆÐµ Ð¸Ð»Ð¸ Ð±Ð¾Ð»ÑŒÑˆÐµ Ñ„Ð°ÐºÑ‚Ð¸Ñ‡ÐµÑÐºÐ¾Ð¹'}</SText>
            </div>
            <div style={{marginBottom: 50, marginLeft: 40}}>
                <SText>{'ÐŸÑ€Ð¸ Ð¿Ñ€Ð¸ÐµÐ¼Ðµ Ð·Ð°ÐºÐ°Ð·Ð°, Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ñ€Ð¾Ð²ÐµÑ€ÑŒÑ‚Ðµ ÐµÐ³Ð¾ ÐºÐ¾Ð¼Ð¿Ð»ÐµÐºÑ‚Ð½Ð¾ÑÑ‚ÑŒ Ð¿Ð¾ Ð½Ð°ÐºÐ»Ð°Ð´Ð½Ñ‹Ð¼ Ð´Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ð°Ð¼.'}</SText>
            </div>
            <div style={{marginBottom: 15}}><SText size={16} weight={500}>{'Ð­ÐšÐžÐ‘ÐÐ›Ð›Ð«'}</SText></div>
            <div>
                <SText>{'Ð”Ð»Ñ Ñ‡Ð°ÑÑ‚Ð¸Ñ‡Ð½Ð¾Ð¹ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹ Ð·Ð°ÐºÐ°Ð·Ð° (Ð´Ð¾ 30%) Ð²Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹. 1 ÑÐºÐ¾Ð±Ð°Ð»Ð» = 1 Ñ€ÑƒÐ±Ð»ÑŒ.'}</SText>
            </div>
        </div>
    </div>
}

export default Pay