import React, {useEffect, useRef, useState} from 'react';
import styles from "../styles.module.scss"
import cn from "classnames";
import SText from "../../../../components/SText";

const Cell = ({value, isInFocus}) => {

    return <div className={styles.cell}>
        {value ?
            <SText size={22} weight={500} color={'#7f7f7f'}>{value}</SText> :
            <>
                <div className={cn(styles.caret, isInFocus && styles.caretBlink)}/>
                <div className={cn(styles.dot)}/>
            </>
        }
    </div>
}

const CodeInput = ({
                       cellsCount = 4, code, setCode = () => {
    }
                   }) => {
    const inputRef = useRef(null)
    const [isInFocus, setInFocus] = useState(false)

    const toggleFocus = (on = true) => () => {
        setInFocus(on)
        if (inputRef.current) {
            on ?
                inputRef.current.focus() :
                inputRef.current.blur()
        }
    }

    const handleChange = (e) => {
        const inp = inputRef.current
        inp.selectionStart = inp.selectionEnd = 5
        const newValue = e.target?.value?.replaceAll ? e.target?.value?.replaceAll('\n', '') :  e.target?.value
        const regex = /^\d{0,4}$/;
        (regex.test(newValue) || newValue == '') && setCode(newValue)
    }

    function mapValueToCellStates(value) {
        const digits = value.split('')
        return Array
            .from({length: cellsCount}, (_, i) => i)
            .map((index) => {
                return digits[index] || false
            })
    }

    useEffect(()=>{
        setTimeout(()=>toggleFocus(true),200)
    }, [])

    return <div onClick={toggleFocus(true)} onBlur={toggleFocus(false)}
                style={{display: 'flex', cursor: 'pointer', justifyContent: 'center'}}>
        <input ref={inputRef}
               className={styles.invisibleInput}
               autoComplete={'one-time-token'}
               id={'code'}
               onFocus={()=>setInFocus(true)}
               onBlur={()=>setInFocus(false)}
               autoCapitalize={false}
               autoFocus
               value={code}
               inputMode={'numeric'}
               onChange={handleChange}/>
        {mapValueToCellStates(code).map((v, index) => <Cell index={index} value={v} isInFocus={isInFocus && index === code.length}/>)}
    </div>
}

export default CodeInput