import moment from "moment";

const md5 = require('md5')

export function roundPrice (price) {
    price = Number(price)
    if (Math.abs(price) % 1 < 0.01)
        return numberWithSpaces(Math.floor(price))
    else
        return numberWithSpaces(price.toFixed(2)).replace('.',',')
}

export function createHash (number) {
    const str = moment().format('MM')+number+moment().format('DD')
    return md5(str)
}

export function getWeight (weight, units, amount = 1) {
    const isSmall = parseFloat(weight) < 1
    const [litres, kilos] = [isSmall ? 'Ð¼Ð»' : 'Ð»', isSmall ? 'Ð³' : 'ÐºÐ³']
    const multiplier = isSmall ?
        1000 * amount :
        amount

    return `${roundPrice(multiplier * parseFloat(weight))} ${units === 'Ð»' ? litres : kilos}`
}

export function shortAddress (address, withCity=false) {
    return address
    return address ?
        address.replace('Ð Ð¾ÑÑÐ¸Ñ, ', '').split(', ').splice(withCity ? 0 : 1).join(', ')
        : ''
}

export function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

export function numberWithSpaces(x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function dayMonth (momentDate) {
    if (!moment().isSame(momentDate, 'year'))
        return momentDate.format('LL').replace(' Ð³.', '')

    return momentDate.format('LL').replace(/ [0-9]{4} Ð³./gm, '').replace(/, [0-9]{4}/gm, '')
}

export function prettyDate (momentDate=moment(), withWeekday=false) {
    const now = moment()

    if (momentDate.isSame(now, 'day'))
        return 'Ð¡ÐµÐ³Ð¾Ð´Ð½Ñ'

    if (momentDate.isSame(now.add(1, 'days'), 'day'))
        return 'Ð—Ð°Ð²Ñ‚Ñ€Ð°'

    return withWeekday ? capitalize(momentDate.format('dddd'))+', '+dayMonth(momentDate) : dayMonth(momentDate)
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getCategoryName = (id) => {
    return window.store.getState().catalogue.categories.slave.map[id]?.name || ''
}