import React, {useState} from "react";
import styles from './AddressAndPayment.module.scss'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import address from '../../../assets/images/userAccount/personalData/address.svg'
import arrow from '../../../assets/images/userAccount/personalData/changeAddress.svg'
import {shortAddress} from "../../../components/utils/other";
import {useSelector} from "react-redux";
import MapDesktop from "../../Map/MapDesktop";
import {useWindowDimensions} from "../../../components/utils/hooks";
import {useNavigate} from "react-router-dom";
import InputMask from 'react-input-mask'
import BottomModal from "../../../mobile/components/BottomModal";
import cn from "classnames";

const AddressBlock = ({data, setter, errors, onClose, orderButtonClicked}) => {

    const user = useSelector(state => state.user)
    const [mapVisible, setMapVisible] = useState(false)
    const width = useWindowDimensions()
    const navigate = useNavigate()

    function onNameChange(e) {
        setter(prev => ({...prev, name: e.target.value}))
    }

    function onPhoneChange(e) {
        setter(prev => ({...prev, phone: e.target.value}))
    }

    function onAddressFieldChange(fieldName, value) {
        setter(prev => ({...prev, address: {...prev.address, [fieldName]: value}}))
    }

    if (width < 1024)

        return <BottomModal maxHeight={455} onClose={onClose}>
            <div style={{marginLeft: 25, marginBottom: 20}}><SText size={16} weight={500} lineHeight={20}
                                                                   color={Colors.darkGray}>{'Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð´Ð»Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText>
            </div>
            <div style={{padding: '0 20px'}}>
                <div style={{marginBottom: 20}} className={styles.inputRow}>
                    <TitledInput id={'name'} value={data.name} onChange={onNameChange}
                                 title={'Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ'}/>
                </div>

                <div onClick={() => {
                    if (width <= 1023) navigate('/map')
                    setMapVisible(true)
                }} className={styles.address}>
                    <img style={{marginRight: 12}} src={address}/>
                    <div style={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
                        {data.address.name ?
                            <SText size={16} weight={700} div lineHeight={20}
                                   color={Colors.darkGray}>{shortAddress(data.address.name, true)}</SText> :
                            <SText size={16} weight={700} div lineHeight={20}
                                   color={(errors.includes('address') && orderButtonClicked) ? Colors.red : Colors.green}>{'Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð°Ð´Ñ€ÐµÑ'}</SText>
                        }
                    </div>
                    <img style={{marginLeft: 12}} src={arrow}/>
                </div>

                <div style={{marginBottom: 20}} className={styles.addressDetails}>
                    <div style={{
                        maxWidth: '100%',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: "space-between",
                        gap: 14,
                        marginBottom: 14,
                    }}>
                        <TitledInput id={'number'} value={data.address.number}
                                     onChange={(value) => onAddressFieldChange('number', value.target.value)}
                                     title={'ÐšÐ²/Ð¾Ñ„Ð¸Ñ'}/>
                        <TitledInput id={'entrance'} value={data.address.entrance}
                                     onChange={(value) => onAddressFieldChange('entrance', value.target.value)}
                                     title={'ÐŸÐ¾Ð´ÑŠÐµÐ·Ð´'}/>
                        <TitledInput id={'floor'} value={data.address.floor}
                                     onChange={(value) => onAddressFieldChange('floor', value.target.value)}
                                     title={'Ð­Ñ‚Ð°Ð¶'}/>
                    </div>
                    <div className={styles.comment}>
                        <TitledInput id={'comment'} value={data.address.comment}
                                     onChange={(value) => onAddressFieldChange('comment', value.target.value)}
                                     title={'ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ'}/>
                    </div>
                </div>

                <div onClick={() => {
                    setTimeout(() => {
                        onClose()
                    }, 200)
                }} className={styles.saveBtn}>
                    <SText size={16} weight={500} color={'#fff'}>{'Ð¡Ð¾Ñ…Ñ€Ð°Ð½Ð¸Ñ‚ÑŒ'}</SText>
                </div>

            </div>
        </BottomModal>

    return <div>
        <div style={{marginBottom: 16}}><SText size={24} lineHeight={30} weight={700}
                                               color={Colors.darkGray}>{'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°'}</SText></div>
        <div style={{display: 'flex', gap: 12, alignItems: 'center', marginBottom: 29}}>
            <div style={{minWidth: 238, flex: 0, opacity: !!user.apikey ? 0.5 : 1, position: 'relative'}}
                 className={styles.inputRow}>
                {!!user.apikey && <div className={styles.disabled}/>}
                {errors.includes('phone') && orderButtonClicked &&
                    <div className={styles.phoneError}><SText size={12} weight={500} color={'#fff'}
                                                              lineHeight={12}>{'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ð½Ñ‹Ð¹ Ð½Ð¾Ð¼ÐµÑ€'}</SText>
                    </div>}
                <InputMask mask={'+7-999-999-99-99'}
                           maskChar={''}
                           alwaysShowMask={false}
                           value={data.phone}
                           disabled={!!user.apikey}
                           onChange={onPhoneChange}>
                    {(inputProps) => (<input
                        id={'phoneInput'}
                        placeholder={'ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°'}
                        type={'cel-phone'}
                        className={errors.includes('phone') && orderButtonClicked && styles.greenPlaceholder}
                        defaultValue={''}
                        inputMode={'tel'}
                        maxLength={16}
                        {...inputProps}
                    />)}
                </InputMask>
            </div>
            <div style={{flex: 1}} className={cn(styles.inputRow,)}>
                <TitledInput id={'name'} value={data.name} onChange={onNameChange}
                             title={'Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ'}/>
            </div>
        </div>
        <div onClick={() => {
            if (width <= 1023) navigate('/map')
            setMapVisible(true)
        }} className={styles.address}>
            <img style={{marginRight: 12}} src={address}/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {data.address.name ?
                    <SText className={styles.addressName} size={16} weight={700} div lineHeight={20}
                           color={Colors.darkGray}>{shortAddress(data.address.name, true)}</SText> :
                    <SText size={16} weight={700} div lineHeight={20}
                           color={(errors.includes('address') && orderButtonClicked) ? Colors.red : Colors.green}>{'Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð°Ð´Ñ€ÐµÑ'}</SText>
                }
            </div>
            <img style={{marginLeft: 12}} src={arrow}/>
        </div>
        <div className={styles.addressDetails}>
            <div style={{
                maxWidth: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: "space-between",
                gap: 8,
                marginBottom: 22,
            }}>

                <TitledInput id={'number'} value={data.address.number}
                             onChange={(value) => onAddressFieldChange('number', value.target.value)}
                             title={'ÐšÐ²/Ð¾Ñ„Ð¸Ñ'}/>
                <TitledInput id={'entrance'} value={data.address.entrance}
                             onChange={(value) => onAddressFieldChange('entrance', value.target.value)}
                             title={'ÐŸÐ¾Ð´ÑŠÐµÐ·Ð´'}/>
                <TitledInput id={'floor'} value={data.address.floor}
                             onChange={(value) => onAddressFieldChange('floor', value.target.value)}
                             title={'Ð­Ñ‚Ð°Ð¶'}/>

            </div>
            <div className={styles.comment}>
                <TitledInput id={'comment'} value={data.address.comment}
                             onChange={(value) => onAddressFieldChange('comment', value.target.value)}
                             title={'ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ'}/>
            </div>
        </div>
        {mapVisible && <MapDesktop onClose={() => setMapVisible(false)}/>}
    </div>
}

export const TitledInput = ({title, id, value, onChange}) => {

    return <div className={styles.styledInputWrapper}>
        <input onChange={onChange} value={value} type="text" id={id} placeholder={' '} className={styles.styledInput}/>
        <label htmlFor={id} className={styles.styledInputLabel}>{title}</label>
    </div>
}

export default AddressBlock
