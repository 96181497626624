import React, {useState} from "react";
import styles from './Products.module.scss'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import DeliveryPriceInfo from "./DeliveryPriceInfo";
import {useDispatch, useSelector} from "react-redux";
import {cartClear, cartMinus, cartPlus} from "../../../redux/reducers/cart";
import {BASE_DOMAIN} from "../../../api";
import {getWeight, roundPrice} from "../../../components/utils/other";
import garbage from '../../../assets/images/cart/garbage.svg'
import plus from '../../../assets/images/cart/plus.svg'
import minus from '../../../assets/images/cart/minus.svg'
import {useNavigate, useSearchParams} from "react-router-dom";
import RecommendedItems from "./RecommendedItems";
import clearCart from '../../../assets/images/cart/clearCartMobile.svg'
import clearCartDesktop from '../../../assets/images/cart/clearCartDesktop.svg'
import {useWindowDimensions} from "../../../components/utils/hooks";
import {useQueryParams} from "../../../components/utils/ModalSpectator";
import {useContext} from "react";
import HelperContext from "../../../redux/HelperContext";
import {Box, Modal} from "@mui/material";
import closeModal from "../../../assets/images/closeModal.svg";

const Products = ({mobile}) => {
    const items = useSelector(state => state.cart.order)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const width = useWindowDimensions()
    const [clearCartModal, setClearCartModal] = useState(false)

    const {
        totals,
    } = useContext(HelperContext)

    const onCartClear = () => {
        dispatch(cartClear())
        navigate('/')
    }

    return <div>
        <div className={styles.productsWrapper}>
            <div className={styles.header}>
                <SText size={24} weight={700} color={'#383838'}>{'ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°'}</SText>
                {width > 1023 ?
                    <div onClick={() => setClearCartModal(true)}
                         style={{display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer'}}><SText
                        style={{cursor: 'pointer', marginBottom: '5'}} size={12}
                        lineHeight={12}
                        weight={500}
                        color={Colors.red}>{'ÐžÑ‡Ð¸ÑÑ‚Ð¸Ñ‚ÑŒ ÐºÐ¾Ñ€Ð·Ð¸Ð½Ñƒ'}</SText> <img
                        style={{cursor: 'pointer'}}
                        src={clearCartDesktop}/></div> :
                    <img onClick={() => setClearCartModal(true)} style={{cursor: 'pointer'}} src={clearCart}/>}
            </div>
            <div style={{paddingBottom: 6, paddingTop: mobile ? 0 : 10}}>
                {items.map(item => <Product mobile={mobile} id={item} key={item}/>)}
            </div>
            {clearCartModal && <CartClearModal onClose={() => setClearCartModal(false)} onClear={onCartClear}/>}
        </div>
    </div>
}

export const CartClearModal = ({onClose, onClear}) => {

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Box className={styles.modal}>
            <div style={{marginBottom: 29, textAlign: 'center'}}><SText size={18} weight={500} color={'#515151'} lineHeight={22}>{'ÐžÑ‡Ð¸ÑÑ‚Ð¸Ñ‚ÑŒ ÐºÐ¾Ñ€Ð·Ð¸Ð½Ñƒ?'}</SText></div>
            <div className={styles.btnsWrapper}>
                <div onClick={onClear} className={styles.clearBtnGreen}><SText size={16} weight={500} color={'#fff'}>{'ÐžÐº'}</SText></div>
                <div onClick={onClose} className={styles.declineBtn}><SText size={16} weight={500} color={'#787878'}>{'ÐžÑ‚Ð¼ÐµÐ½Ð°'}</SText></div>
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeModal}/></div>
        </Box>
    </Modal>
}

export const Product = ({id, mobile}) => {
    const amount = useSelector(state => state.cart.items?.[id])
    const product = useSelector(state => state.catalogue.products?.[id])
    const dispatch = useDispatch()
    const {push} = useQueryParams()

    function onPreview() {
        push(['product', id])
    }

    function onPlus() {
        dispatch(cartPlus(id))
    }

    function onMinus() {
        dispatch(cartMinus(id))
    }

    if (!product || !amount) return null

    const isMax = product.availableones < amount + 1

    const isDiscount = !!product.old_price

    return <div className={styles.product}>
        {product.thumb ? <img onClick={onPreview} src={BASE_DOMAIN + product.thumb}/> :
            <img onClick={onPreview} src={'https://api.ecomarket.ru/imgs/no-foto.png'}/>}
        <div onClick={onPreview} className={styles.titleAndWeight}>
            <div className={styles.title}>
                <SText size={13} lineHeight={14} weight={500} color={Colors.darkGray}>{product.title}</SText>
            </div>
            <div style={{display: 'flex', gap: 8}}>
                <SText size={13} lineHeight={14} weight={700}
                       color={'#bfbfbf'}>{getWeight(product.weight, product.ed_izm)}</SText>
                {mobile && isDiscount &&
                    <SText style={{textDecoration: 'line-through'}} size={13} lineHeight={14} weight={700}
                           color={'#bfbfbf'}>{product.old_price + ' â‚½'}</SText>}
                {mobile && <SText size={13} lineHeight={14} weight={700}
                                  color={'#bfbfbf'}>{roundPrice(product.price) + ' â‚½'}</SText>}
            </div>
        </div>
        <div className={styles.toggler}>
            <div className={styles.minus} onClick={onMinus}>
                <div>
                    {amount > 1 ? <img src={minus}/> : <img src={garbage}/>}
                </div>
            </div>
            <SText size={14} weight={500} lineHeight={17} color={Colors.darkGray}>{amount}</SText>
            <div className={styles.plus} onClick={onPlus}>
                <div style={{opacity: isMax ? .5 : 1}}>
                    <img src={plus}/>
                </div>
            </div>
        </div>
        {!mobile && <div style={{flex: 1.2, textAlign: 'end'}}>
            {
                isDiscount ?
                    <div><SText style={{textDecoration: 'line-through'}} size={12} weight={700} lineHeight={14}
                                color={'#bfbfbf'}>{product.old_price + ' â‚½'}</SText></div> :
                    null
            }
            <div>
                <SText size={14} lineHeight={18} weight={700}
                       color={Colors.darkGray}>{roundPrice(product.price) + ' â‚½'}</SText>
            </div>
        </div>}
    </div>
}

export default Products
