import React, {useEffect, useState} from 'react';
import MapHelper from "./MapHelper";
import {useDispatch, useSelector} from "react-redux";
import styles from './Map.module.scss'
import MapLeftContent from "./MapLeftContent";
import closeModal from '../../assets/images/closeModal.svg'
import {Box, Modal} from "@mui/material";
import {updateAddress} from "../../redux/reducers/user";

const MapDesktop = ({onClose}) => {

    const address = useSelector(state => state.user.address)
    const [ad, setAd] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [mapHelper, setMapHelper] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [mapVisible, setMapVisible] = useState(false)
    const dispatch = useDispatch()
    const [gps, setGps] = useState([])

    function onSubmitAddress() {
        dispatch(updateAddress({
            name: ad,
            name_short: ad,
            gps: [gps[0], gps[1]],
        }))
        onClose()
    }

    useEffect(() => {
        setTimeout(() => {
            setMapVisible(true)
        }, 500)
    }, [])

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box className={styles.modal}>
                <div className={styles.modalMap}>
                    {mapVisible &&
                        <MapLeftContent onSubmitAddress={onSubmitAddress} disabled={disabled} mapHelper={mapHelper}
                                        suggestions={suggestions}
                                        setSuggestions={setSuggestions} address={ad} setAddress={e => setAd(e)}/>}
                    <MapHelper
                        setAdressAndGps={(address, gps) => {
                            setGps(gps)
                        }}
                        resetGps={() => {
                        }}
                        setDisabled={setDisabled}
                        ref={(e) => setMapHelper(e)}
                        inputAddress={ad}
                        setInputAddres={e => setAd(e)}
                        setSuggestions={(suggestions) => {
                            setSuggestions(suggestions)
                        }}
                        mapLoaded={() => {
                            setMapVisible(true)
                        }}
                        showButtons={'true'}
                        GPS={address.gps && address.gps[0] ? address.gps : [55.754638, 37.621633]}
                    />
                    {mapVisible && <img onClick={onClose} className={styles.closeModal} src={closeModal}/>}
                </div>
            </Box>
        </Modal>
    );
}

export default MapDesktop
