import React, {useEffect, useState} from "react";
import styles from "./Chat.module.scss";
import {Box, Modal} from "@mui/material";
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import plus from '../../assets/images/chat/addPhoto.svg'
import send from '../../assets/images/chat/sendMessage.svg'
import close from '../../assets/images/close.svg'
import {useDispatch, useSelector} from "react-redux";
import {fetchSupportChat, sendSupportMessage} from "../../redux/reducers/chats";
import moment from "moment";
import {BASE_DOMAIN} from "../../api";
import user from '../../assets/images/userAccount/courierZaglushka.png'
import deleteImg from '../../assets/images/closeModal.svg'
import {colorToFilter} from "../../components/utils/ColorFilter";
import emptyChat from "../../assets/images/chat/emptyChat.png";
import TextareaAutosize from "react-textarea-autosize";

const SupportChat = ({onClose}) => {
    const dispatch = useDispatch()
    const chat = useSelector(state => state.chats.supportChat) || {messages: []}
    const [text, onInputTextChanged] = useState('')
    const [isSending, setSending] = useState(false)

    const [attachedImage, setAttachedImage] = useState(null)

    async function onSend() {
        if (isSending || (text.trim() === '' && !attachedImage)) return

        setSending(true)

        const newMessage = {
            text,
            _image: attachedImage
        }

        onInputTextChanged('')
        setAttachedImage(null)
        await dispatch(sendSupportMessage(newMessage))
        setSending(false)
    }

    useEffect(() => {
        dispatch(fetchSupportChat())
    }, [])

    if (Object.keys(chat).length === 0) return null

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box className={styles.modal}>

            <div className={styles.header}>
                <SText size={16} lineHeight={19} weight={700} color={Colors.darkGray}>{'CÐ»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸'}</SText>
                <div onClick={onClose} className={styles.closeBtn}><img src={close}/></div>
            </div>

            {chat.messages.length === 0 ? <div className={styles.emptyChat}><img src={emptyChat}/></div> :
                <div className={styles.messagesContainer}>
                    {
                        chat.messages.map((item, index, array) => {

                            let dateVisible = false
                            if (array.length === index + 1)
                                dateVisible = true
                            else if (index !== 0) {
                                if (!moment(item.datetime.split(' ')[0]).isSame(array[index + 1].datetime.split(' ')[0], 'day')) {
                                    dateVisible = true
                                }
                            }

                            return <div>
                                {dateVisible &&
                                    <div className={styles.messageDate}><SText size={14} weight={500} lineHeight={14}
                                                                               color={'#adadad'}>{moment(item.datetime).format('LL')}</SText>
                                    </div>}
                                <Message isManager={item.direction === '0'} item={item}/>
                            </div>
                        })
                    }
                </div>}

            <div className={styles.footer}>
                {attachedImage && <div className={styles.loadedImg}>
                    <SText>{attachedImage.name}</SText>
                    <img style={{filter: colorToFilter(Colors.darkGray)}} onClick={() => setAttachedImage(null)}
                         src={deleteImg}/>
                </div>}
                <div className={styles.footerContent}>
                    <div className={styles.inputFile}>
                        <label htmlFor="input_file"><img src={plus}/></label>
                        <input id={'input_file'} type="file" onChange={e => setAttachedImage(e.target.files[0])}/>
                    </div>
                    <TextareaAutosize
                        value={text}
                        onKeyPress={e => {
                            if (e.keyCode === 13) {
                                onSend()
                            }
                        }}
                        onChange={e => {
                            onInputTextChanged(e.target.value)
                        }}
                        placeholder={'ÐÐ°Ð¿Ð¸ÑˆÐ¸Ñ‚Ðµ Ð²Ð°ÑˆÐµ ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ðµ'}
                        minRows={1}
                        maxRows={3}
                    />
                    <div onClick={onSend}>
                        <img src={send}/>
                    </div>
                </div>
            </div>
        </Box>
    </Modal>
}

const Message = ({item, isManager}) => {

    return <div style={{alignItems: isManager ? 'flex-start' : 'flex-end'}} className={styles.messageWrapper}>
        {isManager && <div className={styles.managerinfo}>
            {item.photo_manager ? <img src={BASE_DOMAIN + `/imgs/users/${item.manager}/thumb/` + item.photo_manager}/> :
                <img src={user}/>}
            <SText size={12} weight={500} lineHeight={15} color={'#adadad'}>{'Ð”Ð¸ÑÐ¿ÐµÑ‚Ñ‡ÐµÑ€ ' + item.name}</SText>
        </div>}
        <div style={{background: isManager ? '#f2f2f2' : '#abf69e'}} className={styles.message}>
            {item.attachment && <a target={'_blank'} href={item.attachment} rel="noreferrer">
                <div className={styles.messageImg}><img src={item.attachment}/></div>
            </a>}
            <SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{item.content}</SText>
            <div className={styles.messageTime}>
                <SText size={12} weight={500} lineHeight={15}
                       color={isManager ? '#adadad' : '#888'}>{moment(item.datetime).format('LT')}</SText>
            </div>
        </div>
    </div>
}

export default SupportChat