import { Button } from "@mui/material"
import SText from "../../../../components/SText"
import Colors from "../../../../components/utils/Colors"
import styles from "./DownloadAppModal.module.scss"
import { GOOGLE_PLAY, APPLE_STORE } from "../../../../constants/urls"

const DownloadAppModal = ({ isOpen, setClose }) => {
    return isOpen && (
        <div className={ styles.wrap }>
            <div className={ styles.window }>
                <SText size={ 16 } weight={ 500 }>Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾! ÐœÑ‹ Ð¿Ñ€Ð¸Ð½ÑÐ»Ð¸ Ð²Ð°Ñˆ Ð·Ð°ÐºÐ°Ð·!</SText>
                <img src="/icons/cross_gray.svg" alt="cross" className={ styles.cross } onClick={ () => setClose(false) } />
                <hr className={ styles.separator } />
                <SText size={ 16 } weight={ 900 }>Ð¥Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð´Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ, <br></br> Ð¾Ñ‚ÑÐ»ÐµÐ´Ð¸Ñ‚ÑŒ ÐºÑƒÑ€ÑŒÐµÑ€Ð° Ð¸Ð»Ð¸ ÑÐ²ÑÐ·Ð°Ñ‚ÑŒÑÑ <br></br>Ñ Ð´Ð¸ÑÐ¿ÐµÑ‚Ñ‡ÐµÑ€Ð¾Ð¼?</SText>
                <SText size={ 20 } weight={ 900 } color={ Colors.green }>Ð­Ñ‚Ð¾ ÑƒÐ´Ð¾Ð±Ð½Ð¾ ÑÐ´ÐµÐ»Ð°Ñ‚ÑŒ <br></br>Ð² Ð½Ð°ÑˆÐµÐ¼ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸!</SText>
                <div className={ styles.mobile }>
                    <img src={ "/images/mobile.png" } alt="mobile" />
                </div>
                <Button style={ { background: Colors.green, color: "#fff", padding: "19px 22px", borderRadius: "21px", fontFamily: "DINPro-Bold", textTransform: "none", fontSize: "16px" } }>Ð¡ÐºÐ°Ñ‡Ð°Ñ‚ÑŒ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ</Button>
                <div className={ styles.app_markets }>
                <a href={APPLE_STORE}>
                    <img src={ "/icons/apple_store.svg" } alt="apple store" />
                </a>
                <a href={GOOGLE_PLAY}>
                    <img src={ "/icons/google_play.svg" } alt="google play" />
                </a>
                </div>
                <div className={ styles.text_icon }>
                    <img src={ "/images/petard.png" } alt="petard" className={ styles.petard } />
                    <SText size={ 16 } weight={ 500 } className={ styles.text_center } style={ { textAlign: "left" } }>ÐœÑ‹ Ñ‚Ð°ÐºÐ¶Ðµ Ð´Ð°Ñ€Ð¸Ð¼ Ð²Ð°Ð¼ 300 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² <br></br> Ð½Ð° Ð²Ð°Ñˆ Ð¿ÐµÑ€Ð²Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð·, Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð½Ñ‹Ð¹ <br></br>Ñ‡ÐµÑ€ÐµÐ· Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ</SText>
                </div>
            </div>
        </div>
    )
}

export default DownloadAppModal