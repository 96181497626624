import React, {useContext, useEffect, useState} from "react";
import styles from './OrdersHistory.module.scss'
import SText from "../../components/SText";
import {useSelector} from "react-redux";
import {TempWrapper} from "../OrderTradeOff/OrderTradeOff";
import Colors from "../../components/utils/Colors";
import {Box, Modal} from "@mui/material";
import {roundPrice} from "../../components/utils/other";
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import {useNavigate} from "react-router-dom";
import HelperContext from "../../redux/HelperContext";
import closeBtn from "../../assets/images/closeModal.svg";

const AddProductModal = ({onClose}) => {
    const {remains, missedProducts, dismissTradeOff, mode, confirmTradeOff} = useContext(OrderProductXChangeContext)
    const navigate = useNavigate()
    const cart = useSelector(state => state.cart.order)
    const {totals} = useContext(HelperContext)
    const cartSum = totals.cart.actual
    const missedSum = missedProducts.reduce((sum, product) => {
        return sum + (Number(product?.sum) || 0)
    }, 0)
    const discount = mode === 'add' ?
        1 :
        1 - .2

    useEffect(() => {
        if (mode === 'none')
            navigate(-1)
    }, [mode])


    function onMainButtonPress() {
        cart.length ? confirmTradeOff() : navigate('/')
    }

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Box style={{display: 'flex'}}>
            <div className={styles.modal}>
                <div style={{marginBottom: 28}}><SText size={20} weight={900} lineHeight={25}
                                                       color={Colors.darkGray}>{'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²'}</SText></div>
                <div style={{marginBottom: 15}}><SText size={16} weight={700} lineHeight={16}
                                                       color={Colors.darkGray}>{cart.length ?
                    `Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¾ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ:` :
                    'Ð’Ñ‹ Ð¿Ð¾ÐºÐ° Ñ‡Ñ‚Ð¾ Ð½Ð¸Ñ‡ÐµÐ³Ð¾ Ð½Ðµ Ð´Ð¾Ð±Ð°Ð²Ð¸Ð»Ð¸ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ'}</SText></div>
                <div style={{marginBottom: 30}}>
                    {cart.map(id => <TempWrapper id={id} key={id} controllable/>)}
                </div>

                <div className={styles.buttons}>
                    <div onClick={onMainButtonPress} className={styles.mainBtn}>
                        <div className={styles.mainBtnText}>
                            {cart.length ?
                                `ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð´Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ðµ (${roundPrice((discount * cartSum) - missedSum)} â‚½)` :
                                'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð²Ñ‹Ð±Ð¾Ñ€Ñƒ'
                            }
                        </div>
                        <div className={styles.timer}>
                            <SText size={14} weight={700} lineHeight={21}
                                   color={remains.asSeconds() <= 35 ? Colors.red : Colors.darkGray}>{`${remains.minutes()}:${String(remains.seconds()).padStart(2, '0')}`}</SText>
                        </div>
                    </div>
                    <div onClick={dismissTradeOff} className={styles.skipBtn}><SText size={13} weight={700}
                                                                                     lineHeight={18}
                                                                                     color={'#fff'}>{'ÐžÑ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ'}</SText>
                    </div>
                </div>
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeBtn}/></div>
        </Box>
    </Modal>
}

export default AddProductModal