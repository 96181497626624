import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../mobile/screens/CatalogueV2/components/Header";
import { useQueryParams } from "../../components/utils/ModalSpectator";
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import { ProductContainer, useWindowDimensions } from "../../mobile/screens/Catalogue";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import ProductCard from "../../screens/ProductCard/ProductCard";
import sendRequest from "../../api";
import cn from "classnames";
import specificStyles from "./TagPage.module.scss";
import styles from "../../components/BigList/styles.module.css";
import { DIMENSIONS } from "../../mobile/utils";
import { Helmet } from "react-helmet";
import { thresholds } from "../..//constants";

export function isMobile() {
    return window.innerWidth <= thresholds.mobile
}

const TagPage = () => {
    const { width: windowWidth } = useWindowDimensions()
    const { url } = useParams();
    const user = useSelector(state => state.user)
    const categories = useSelector(state => state.catalogue.catV6.masterOrder)
    const products = useSelector(state => state.catalogue.products)
    const { altProducts } = useContext(OrderProductXChangeContext)
    const recommended = useSelector(state => state.user.personalRecommendations)
    const navigate = useNavigate()
    const { push } = useQueryParams()
    const [tagData, setTagData] = useState(false)


    useEffect(() => {
        if (window?.newUserOrderId) {
            setTimeout(() => {
                push(['auth', window.newUserPhone])
            }, 200)
        } else if (window.orderId) {
            setTimeout(() => {
                push(['orderDetails', window.orderId])
            }, 200)
        }
    }, [window?.orderId, window?.newUserOrderId])

    const getTagData = async () => {
        const tag = await sendRequest('getTagData', { url: url })
        setTagData(tag)
    }

    useEffect(() => {
        if (url) {
            getTagData();
        }
    }, [url])

    const breadcrumbTree = useMemo(() => {
        if (!tagData)
            return null

        let treeSteps = [];

        treeSteps.push({
            title: tagData.title,
            url: '/tags/' + tagData.url
        })

        treeSteps.push({
            title: 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ',
            url: ''
        })

        return treeSteps.reverse();
    }, [tagData])

    const productsCards = useMemo(() => {
        if (!tagData)
            return

        const cards = []
        tagData.products.map((productId) => {
            if (typeof products[productId] !== "undefined") {
                cards.push(
                    <div key={ productId } className={ specificStyles.productCard }>
                        <ProductCard id={ productId } />
                    </div>
                )
            }
        })

        return cards;

    }, [tagData, products])

    if (!tagData)
        return null


    return (
        <>
            <Helmet>
                <title>{ tagData.title }</title>
            </Helmet>
            { isMobile() ?
                <Header title={ tagData.title } />
                : null }

            <div className={ specificStyles.textContainer }>
                <Breadcrumbs breadcrumbTree={ breadcrumbTree } />
                <h1>{ tagData.title }</h1>
            </div>

            <div className={ specificStyles.container }>
                { productsCards }
            </div>
        </>
    );
}

export default TagPage