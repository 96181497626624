import React, {useEffect, useRef} from 'react'
import {useSelector} from "react-redux";
import {categoryColors, colorToFilter} from "../../../../components/utils/ColorFilter";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import styles from './styles.module.css'
import cn from "classnames";
import {useNavigate} from "react-router-dom";


const CategoryItem = ({id, isSelected=false, onSelect, onSelected}) => {
    const category = useSelector(state => state.catalogue.categories.master.map?.[id])
    const ref = useRef(null)
    const navigate = useNavigate()

    useEffect(()=>{
        if (isSelected)
            onSelected()
           // ref.current.scrollIntoView({block: 'start', inline: 'start', behavior: 'smooth'})
    }, [isSelected])

    function onPress () {
        navigate('/'+category.url)
        //onSelect(category.firstSubCat)
    }

    return <div className={styles.category} onClick={onPress} ref={ref}>
        <div style={{background: isSelected ? '#383838' : '#f2f2f2'}}
             className={styles.categoryCircle}>
            <img src={category.icon}
                 style={{
                     width: 48,
                     pointerEvents: 'none',
                     filter: isSelected ?
                         categoryColors[(Number(category.id) % categoryColors.length)] :
                         colorToFilter('#383838')
                 }}/>
        </div>
        <div className={styles.categoryTitle}>
            <SText weight={500} size={11}
                   className={styles.categoryName}
                   color={Colors.darkGray}>
                {category.name}
            </SText>
        </div>
    </div>
}


const SubCategoryItem = ({id, isSelected, onSelect, onSelected, onLayout}) => {
    const subCategory = useSelector(state=>state.catalogue.categories.slave.map?.[id])
    const itemRef = useRef(null)
    const navigate = useNavigate()


    const itemOffset = itemRef.current ? itemRef.current.getBoundingClientRect().x : 0
    useEffect(()=>{onLayout(itemOffset)}, [itemOffset])


    useEffect(()=>{
        if (isSelected) {
            onSelected()
        }
    }, [isSelected])

    function onPress () {
        navigate('/'+subCategory.url)
    }

    return <div onClick={onPress} ref={itemRef} className={cn(styles.subCategoryItem, isSelected && styles.scActive)}>
        <SText div className={styles.subCategoryName} color={isSelected ? '#fff' : Colors.darkGray} weight={700} size={14}>{subCategory.name}</SText>
    </div>
}

const offsets = {}
window.offsets = offsets

export const SubCategoryCarousel = ({currentSection, onSelect = ()=>{}}) => {

    const categories = useSelector(state => state.catalogue.categories.slave)
    const listRef = useRef(null)

    function scrollToIndex (index) {
        let scrollOffset = offsets[0] - 20

        if (listRef.current && offsets[index]) {
            const left = offsets[index] - 20 - scrollOffset
            listRef.current.scrollTo({
                left,
                behavior: "smooth"
            })
        }

    }

    return <div ref={listRef} className={cn(styles.scrollableRow, styles.scRow)}>
        {categories.order.map((id, index)=><SubCategoryItem
            key={id}
            id={id}
            onLayout={x=>offsets[index] = x}
            isSelected={currentSection == id}
            onSelected={()=>{
                scrollToIndex(index)
            }}
            onSelect={onSelect}
        />)}
    </div>
}
const CatalogueCarousel = ({currentSection, onSelect = ()=>{}}) => {

    const categories = useSelector(state => state.catalogue.categories.master)
    const listRef = useRef(null)

    function scrollToIndex (index) {
        if (listRef.current) {
            listRef.current.scrollTo({
                left: (index) * (54+28),
                behavior: "smooth"
            })
        }

    }

    return <div ref={listRef} className={styles.scrollableRow}>
        {categories.order.map((id, index)=><CategoryItem
            key={id}
            id={id}
            isSelected={currentSection == id}
            onSelected={()=>{
                scrollToIndex(index)
            }}
            onSelect={onSelect}
        />)}
    </div>
}

export default CatalogueCarousel
