import React, {useContext} from "react"
import styles from "./SearchPopUp.module.scss";
import SText from "../SText";
import Colors from "../utils/Colors";
import {ClickAwayListener} from "@mui/base";
import {SearchContext} from "../utils/search";
import {useSelector} from "react-redux";
import ProductCard from "../../screens/ProductCard/ProductCard";
import {useNavigate} from "react-router-dom";
import notFounded from "../../assets/images/notFoundSearch.svg";

const SearchPopUp = ({onClose}) => {
    const {results, query, loading} = useContext(SearchContext)
    const catalogue = useSelector(state => state.catalogue)
    /*    const navigate = useNavigate()*/

    const filteredResults = results.filter(id => !!catalogue.products[id])
    /*    const productsSubCats = filteredResults.map(productId => ({
            group: catalogue.products[productId].group,
            title: catalogue.products[productId].title,
            productId
        }))*/
    /*    let uniqueProductsSubCats = productsSubCats.reduce((acc, productSubCats) => {
            if (acc.map[productSubCats.group])
                return acc;

            acc.map[productSubCats.group] = true;
            acc.productsSubCats.push(productSubCats);
            return acc;
        }, {
            map: {},
            productsSubCats: []
        }).productsSubCats.slice(0, 3);*/

    /*    let groupsTitlesAndURL = uniqueProductsSubCats.map(group => ({
            title: catalogue.categories.slave.map[group.group]?.name,
            parentURL: catalogue.categories.master.map[catalogue.categories.slave.map[group.group]?.parentId]?.url,
            id: catalogue.categories.slave.map[group.group]?.id
        }))*/

    /*    const onCategoryClick = async (index, productId) => {
            navigate(groupsTitlesAndURL[index].parentURL, {state: {subCategory: groupsTitlesAndURL[index].id}})
            onClose()
            let a = `https://search.ecomarket.ru/index.php?r=site/click&key=${query}&id=${productId}`
            await fetch(a)
        }*/

    return <ClickAwayListener onClickAway={onClose}>
        <div className={styles.wrapper}>

            {/*<div style={{marginBottom: 23, marginLeft: 8}}>
                {
                    uniqueProductsSubCats.map((groupName, index) => {
                        return <div onClick={() => onCategoryClick(index,groupName.productId)}
                                    style={{marginBottom: 17, cursor: 'pointer', userSelect: 'none'}}>
                            <div style={{marginBottom: 3}}>
                                <SText size={16} lineHeight={16} weight={500}
                                       color={Colors.darkGray}>{groupName.title}</SText>
                            </div>
                            <div style={{width: 345, borderBottom: '1px solid #f4f5f9', paddingBottom: 11}}>
                                <SText size={12} lineHeight={16} weight={500}
                                       color={'#bdbdbd'}>{groupsTitlesAndURL[index].title}</SText>
                            </div>
                        </div>
                    })
                }
            </div>*/}

            {loading ? <div style={{display: 'grid', placeItems: 'center', height: '100%'}}><SText size={18}
                                                                                                      weight={700}>{'ÐŸÐ¾Ð¸ÑÐº...'}</SText>
                </div> : <div className={styles.products}>
                    {
                        filteredResults.map((productId, index) => <ProductCard key={index} string={query} id={productId}/>)
                    }
                </div>}

        </div>
    </ClickAwayListener>
}

export default SearchPopUp