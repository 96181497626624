import React, {useContext} from "react";
import styles from './FloatingCart.module.css'
import {useSelector} from "react-redux";
import {Product} from "../Products";
import DeliveryPriceInfo from "../DeliveryPriceInfo";
import {NavLink, useSearchParams} from "react-router-dom";
import SText from "../../../../components/SText";
import HelperContext from "../../../../redux/HelperContext";
import Colors from "../../../../components/utils/Colors";
import {roundPrice} from "../../../../components/utils/other";

const FloatingCart = ({onMouseLeave}) => {
    const items = useSelector(state => state.cart.order)
    const {totals, delivery} = useContext(HelperContext)
    const cartSum = totals.cart.actual
    const freeDeliveryFrom = delivery.discountedFrom
    const diff = freeDeliveryFrom - cartSum

    return <div onMouseLeave={onMouseLeave} className={styles.hover}>
        <div className={styles.wrapperFloatingCart}>

            <div className={styles.products}>
                {items.map(item => <Product id={item} key={item}/>)}
            </div>

            <div className={styles.info}>
                <div style={{margin: '0 -12px'}}><DeliveryPriceInfo/>
                </div>
                <div className={styles.totals}>
                    <div>
                        <div style={{marginBottom: 6}}>
                            <SText size={16} weight={700} lineHeight={20} color={Colors.darkGray}>{'Ð˜Ñ‚Ð¾Ð³: '}</SText>
                            <SText size={26} weight={900} lineHeight={32}
                                   color={Colors.darkGray}>{roundPrice(cartSum) + ' â‚½'}</SText>
                        </div>
                        {diff > 0 && <div>
                            <SText size={14} weight={500} lineHeight={17}
                                   color={'#747272'}>{'Ð‘ÐµÐ· ÑƒÑ‡ÐµÑ‚Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText>
                        </div>}
                    </div>
                    <NavLink to={'/cart'}>
                        <div className={styles.goToCart}>
                            <SText size={16} weight={700} lineHeight={20} color={'#fff'}>{'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð² ÐºÐ¾Ñ€Ð·Ð¸Ð½Ñƒ'}</SText>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    </div>
}

export default FloatingCart