import React, { useContext } from "react";
import styles from './PaymentAndPromocode.module.scss'
import HelperContext from "../../../redux/HelperContext";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import classNames from "classnames";
import selected from '../../../assets/images/userAccount/personalData/selectedCard.svg'
import unselected from '../../../assets/images/userAccount/personalData/unselectedCard.svg'
import { useWindowDimensions } from "../../../components/utils/hooks";
import ScrollContainer from "react-indiana-drag-scroll";
import BottomModal from "../../../mobile/components/BottomModal";
import closeModal from "../../../assets/images/closeModal.svg";
import { Box, Modal } from "@mui/material";


const PaymentType = ({ item }) => {
    let { Icon, label, isSelected, onSelect, last } = item
    if (last) {
        label = `ÐšÐ°Ñ€Ñ‚Ð° **** ${last}`
    }

    return <div onClick={ onSelect } className={ classNames(styles.paymentCard, isSelected && styles.activePayment) }>
        <div style={ { marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }>
            <SText size={ 14 } weight={ 500 } lineHeight={ 14 } color={ Colors.darkGray }>{ label }</SText>
            { isSelected ? <img style={ { height: 17, width: 17, marginLeft: 5 } } src={ selected } /> :
                <img style={ { height: 17, width: 17, marginLeft: 5 } } src={ unselected } /> }
        </div>
        <div>
            <img src={ Icon } style={ item.type == 'sberpay' ? { height: '20px' } : { maxWidth: '20px' } } />
        </div>
    </div>
}

const PaymentMethod = ({
    visibilityState, type = 'submitOrder', onPay = () => {
    }, onClose
}) => {
    const { paymentMethods, onMethodChange, selectedMethod } = useContext(HelperContext)
    const width = useWindowDimensions()

    const displayedMethods = paymentMethods.filter(method => {
        if (type === 'submitOrder') return true
        return method.type !== 'cash'
    })

    if (width > 1023) {
        return <Modal
            open={ true }
            onClose={ onClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }
        >
            <Box style={ { width: 634 } } className={ styles.modal }>
                <div style={ { marginBottom: 29 } }><SText size={ 18 } weight={ 500 } lineHeight={ 22 } color={ '#515151' }>{ 'Ð¡Ð¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹' }</SText></div>
                <ScrollContainer style={ { marginBottom: 0, width: 600 } } horizontal className={ styles.cardsContainer }>
                    {
                        displayedMethods.map(item => <PaymentType key={ item.label } item={ {
                            ...item,
                            isSelected: selectedMethod === item.type,
                            onSelect: () => onMethodChange(item.type)
                        } } />)
                    }
                </ScrollContainer>
                <div onClick={ onClose } className={ styles.closeModal }><img src={ closeModal } /></div>
            </Box>
        </Modal>
    }

    return <BottomModal maxHeight={ 330 } onClose={ onClose }>
        <div style={ { marginLeft: 25, marginBottom: 20 } }><SText size={ 16 } weight={ 500 } lineHeight={ 20 }
            color={ Colors.darkGray }>{ 'Ð¡Ð¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹' }</SText></div>
        <div>
            <ScrollContainer horizontal className={ styles.cardsContainer }>
                {
                    displayedMethods.map(item => <PaymentType key={ item.label } item={ {
                        ...item,
                        isSelected: selectedMethod === item.type,
                        onSelect: () => onMethodChange(item.type)
                    } } />)
                }
            </ScrollContainer>
        </div>
    </BottomModal>
}

export default PaymentMethod