import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sendRequest from "../../api";

const initialState = {
    loaded: false,
    loading: true,
    data: {},
    selectedSlot: {
        date: null,
        time: null
    },
    nearestSlot: {
        date: null,
        time: null
    }
}


export const getDeliverySlots = createAsyncThunk(
    'deliverySlots/getDeliverySlots',
    async (_, {getState}) => {
        const apikey = getState().user.apikey;
        const address = getState().user.address;

        if (address.gps) {
            const payload = {
                apikey,
                lat: address.gps[0],
                lng: address.gps[1],
            };

            const response = await sendRequest('getDeliveryTypes_v2', payload, false);
            return {response, reset: false};
        }
    }
);


const deliverySlotsSlice = createSlice({
    name: 'deliverySlots',
    initialState,
    reducers: {
        resetSlots: (state) => {
            return initialState;
        },
        setActiveSlot: (state, action) => {
            state.selectedSlot = action.payload;
        },
        setNearestSlot: (state, action) => {
            state.selectedSlot = state.nearestSlot;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDeliverySlots.fulfilled, (state, action) => {
            if (action.payload?.response?.region) {
                state.data = action.payload.response;
                const firstSlot = Object.entries(action.payload.response.timeSlots)[0];
                const hasOldSlot = action.payload?.timeSlots?.[state.selectedSlot.date]?.times?.includes(
                    state.selectedSlot?.time
                );
                if (!hasOldSlot || action.payload.reset) {
                    state.selectedSlot = {
                        date: firstSlot[0],
                        time: firstSlot[1].times[0],
                    };
                }
                state.nearestSlot = {
                    date: firstSlot[0],
                    time: firstSlot[1].times[0],
                };
                state.loaded = true;
                state.loading = false;
            } else return {...state, loading: false};
        });
        builder.addCase(getDeliverySlots.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDeliverySlots.rejected, (state) => {
            console.log('failed to load slots');
            state.loading = false;
        });
    },
});

export default deliverySlotsSlice.reducer

export const {resetSlots, setActiveSlot, setNearestSlot} = deliverySlotsSlice.actions