import React, {useEffect, useRef} from 'react';
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import styles from '../styles.module.scss'

const offsets = {}

const GroupSelector = ({
                           selectorList, activeId, scrollFunction = (index) => {
    }
                       }) => {
    const listRef = useRef(null);

    function scrollToIndex(index) {
        let scrollOffset = offsets[0] - 20

        if (listRef.current && offsets[index]) {
            const left = offsets[index] - 20 - scrollOffset
            listRef.current.scrollTo({
                left,
                behavior: "smooth"
            })
        }

    }

    useEffect(() => {
        if (listRef.current) {
            const itemIndex = selectorList.findIndex(item => item.id === activeId)
            if (itemIndex >= 0 && itemIndex < selectorList.length) {
                scrollToIndex(itemIndex)
            }
        }
    }, [activeId, listRef.current]);

    if (!selectorList.length) return null;

    function getIsActive(id) {
        return activeId === id;
    }

    function onItemPress(index) {
        scrollFunction(index)
    }

    return (
        <div ref={listRef} className={styles.groupSelectorWrapper}>
            {
                selectorList.map((item,index) => renderItem({onLayout: x=>offsets[index] = x, item, isActive: getIsActive(item.id), onItemPress, index}))
            }
        </div>
    );
};

const renderItem = ({item, isActive, onItemPress, onLayout, index}) => {

    return <Item key={index} data={item} isActive={isActive} onItemPress={onItemPress} onLayout={onLayout}/>;
}

const Item = ({data, isActive, onItemPress, onLayout}) => {
    const itemRef = useRef(null)

    const itemOffset = itemRef.current ? itemRef.current.getBoundingClientRect().x : 0
    useEffect(() => {
        onLayout(itemOffset)
    }, [itemOffset])

    return (
        <div ref={itemRef} className={styles.groupSelectorItem}
             style={isActive ? {backgroundColor: Colors.darkGray} : {}}
             onClick={() => onItemPress(data.index)}>
            <SText weight={700} size={14} color={isActive ? '#fff' : Colors.darkGray}>
                {data.name?.trim()}
            </SText>
        </div>
    );
};

export default GroupSelector;