import React from "react";
import {useWindowDimensions} from "./utils/hooks";

const PlatformSelector = ({Desktop = () => null, Mobile = () => null, props = {}, width = 500}) => {

    const windowWidth = useWindowDimensions()

    return windowWidth > width ? <Desktop {...props}/> : <Mobile {...props}/>
}

export default PlatformSelector