import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {writeApiKey} from "../../redux/reducers/user";
import styles from './index.module.scss'
import PlatformSelector from "../../components/PlatformSelector";
import closeModal from '../../assets/images/closeModal.svg'
import {Box, Modal} from "@mui/material";
import {createHash} from "../../components/utils/other";
import sendRequest from "../../api";
import {useQueryParams} from "../../components/utils/ModalSpectator";
import {ConfirmPhone, ConfirmWA, SignIn} from "../../mobile/screens/Auth";

const [SIGN_IN, CONFIRM_PHONE, CONFIRM_WA] = [0, 1, 2]

const Auth = ({onClose}) => {
    const {get} = useQueryParams()
    const [scene, setScene] = useState(SIGN_IN)
    const [phone, setPhone] = useState('')
    const [agreed, setAgreed] = useState(true)
    const [status, setStatus] = useState(null)
    const [code, setCode] = useState('')
    const [orderNumber, setOrderNumber] = useState(undefined)

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        const initialPhone = get('auth')
        if (!initialPhone) return
        const phone = !initialPhone.includes('+') ? '+' + initialPhone.trim() : initialPhone
        if (window?.newUserOrderId) {
            setOrderNumber(window.newUserOrderId)
        }
        setPhone(phone)
        requestCall(phone)
        return () => {
            window.newUserOrderId = null
            window.newUserPhone = null
        }
    }, [])

    async function requestCall(_phone = phone) {
        const payload = {
            phone: _phone,
            token: user.authToken,
            sum: createHash(_phone),
            source: 'app',
            type: 'call'
        }

        sendRequest('doRegisterOrRecover', payload)

        setScene(CONFIRM_PHONE)
    }

    async function requestWA() {
        const payload = {
            action: 'doGetCodeOnWA',
            source: 'app',
            sum: createHash(phone),
            type: 'call',
            phone,
            token: user.authToken,
        }
        sendRequest('doGetCodeOnWA', payload)
        setCode('')
        setScene(CONFIRM_WA)
    }

    async function confirmCode(code) {
        const payload = {
            source: 'app',
            token: user.authToken,
            phone,
            code
        }
        const response = await sendRequest('doConfirm', payload)
        if (response?.user) {
            const date = new Date();
            date.setUTCMilliseconds(response.user.time)
            document.cookie = 'eco=' + response.user.eco + '; expires=' + date.toUTCString() + '; path=/;'
            setStatus('success')
            dispatch(writeApiKey(response.user.TOKEN))

        } else {
            setStatus('error')
        }
    }

    useEffect(() => {
        if (status === 'success') onClose()
    }, [status])

    function onReenter() {
        setScene(SIGN_IN)
        setCode('')
    }

    let content = null
    switch (scene) {
        case SIGN_IN:
            content = <SignIn phone={phone}
                              setPhone={setPhone}
                              onCall={requestCall}
                              onWA={requestWA}
                              agreed={agreed}
                              setAgreed={setAgreed}
                              orderNumber={orderNumber}
            />;
            break;
        case CONFIRM_PHONE:
            content = <ConfirmPhone code={code}
                                    setCode={setCode}
                                    phone={phone}
                                    onReenter={onReenter}
                                    onWA={requestWA}
                                    status={status}
                                    onSubmit={confirmCode}
                                    onDismissError={() => setStatus(null)}
                                    orderNumber={orderNumber}
            />;
            break;
        case CONFIRM_WA:
            content = <ConfirmWA code={code}
                                 setCode={setCode}
                                 phone={phone}
                                 onReenter={onReenter}
                                 onSubmit={confirmCode}
                                 status={status}
                                 onDismissError={() => setStatus(null)}
                                 orderNumber={orderNumber}
            />;
            break;
    }

    return <PlatformSelector width={700} Mobile={AuthD} Desktop={AuthD} props={{content, onClose}}/>
}

const AuthD = ({content, onClose}) => {
    const {get} = useQueryParams()
    const checkout = !!get('auth')?.length

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Box className={checkout ? styles.afterOrderModal : styles.modalD}>
            <div style={checkout ? {} : {maxWidth: 420, display: 'flex', justifyContent: 'center', margin: '0px auto 0'}}>
                {content}
            </div>
            <img onClick={onClose} className={checkout ? styles.closeModalCheckout :styles.closeModal} src={closeModal}/>
        </Box>
    </Modal>
}

export default Auth