import React from 'react'
import styles from './Delivery.module.scss'
import SText from "../../../components/SText";
import first from '../../../assets/images/delivery/1.jpg'
import second from '../../../assets/images/delivery/2.jpg'
import third from '../../../assets/images/delivery/3.jpg'
import fourth from '../../../assets/images/delivery/4.jpg'
import fifth from '../../../assets/images/delivery/5.jpg'
import { NavLink } from "react-router-dom";
import { colorToFilter } from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import Colors from "../../../components/utils/Colors";

const Delivery = () => {

    return <div className={ styles.wrapper }>
        <div className={ styles.header }>
            <NavLink to={ '/' }>
                <div className={ styles.goBack }><img style={ { filter: colorToFilter('#383838') } } src={ back } /></div>
            </NavLink>
            <div><SText size={ 16 } lineHeight={ 20 } weight={ 500 } color={ Colors.darkGray }>{ 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°' }</SText></div>
        </div>
        <div className={ styles.container }>
            <div style={ { marginBottom: 30 } }><SText size={ 36 } lineHeight={ 36 } weight={ 500 }>{ 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²' }</SText>
            </div>
            <div>
                <SText>{ 'Ecomarket Ð¿Ñ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÑ‚ ÐºÑ€ÑƒÐ³Ð»Ð¾ÑÑƒÑ‚Ð¾Ñ‡Ð½ÑƒÑŽ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð½Ð° Ð´Ð¾Ð¼, Ð² Ð¾Ñ„Ð¸Ñ, Ð½Ð° Ð´Ð°Ñ‡Ñƒ Ð¸Ð»Ð¸ Ð² Ð»ÑŽÐ±Ð¾Ðµ Ð´Ñ€ÑƒÐ³Ð¾Ðµ ÑƒÐ´Ð¾Ð±Ð½Ð¾Ðµ Ð´Ð»Ñ Ð²Ð°Ñ Ð¼ÐµÑÑ‚Ð¾.' }</SText>
            </div>
            <div><SText>{ 'ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° â€“ 3500 Ñ€ÑƒÐ±.' }</SText></div>
            <div style={ { marginBottom: 30 } }>
                <SText>{ 'Ð’Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¾Ñ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ Ð·Ð°ÐºÐ°Ð·Ð° Ð½Ð° Ð»ÑŽÐ±Ð¾Ð¹ ÑƒÐ´Ð¾Ð±Ð½Ñ‹Ð¹ Ð´ÐµÐ½ÑŒ Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ðµ 1 Ð¼ÐµÑÑÑ†Ð°.' }</SText></div>
            <div style={ { marginBottom: 15 } }><SText size={ 16 } weight={ 400 }
                lineHeight={ 20 }>{ 'Ð¡Ð¾Ñ…Ñ€Ð°Ð½Ð½Ð¾ÑÑ‚ÑŒ ÑÐ²ÐµÐ¶ÐµÑÑ‚Ð¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²' }</SText></div>
            <div style={ { marginBottom: 30 } }>
                <SText>{ 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¾ÑÑƒÑ‰ÐµÑÑ‚Ð²Ð»ÑÐµÑ‚ÑÑ Ñ ÑÐ¾Ð±Ð»ÑŽÐ´ÐµÐ½Ð¸ÐµÐ¼ Ð²ÑÐµÑ… Ñ€ÐµÐºÐ¾Ð¼ÐµÐ½Ð´Ð°Ñ†Ð¸Ð¹ Ð¿Ð¾ Ñ…Ñ€Ð°Ð½ÐµÐ½Ð¸ÑŽ Ð¸ Ñ‚Ñ€Ð°Ð½ÑÐ¿Ð¾Ñ€Ñ‚Ð¸Ñ€Ð¾Ð²ÐºÐµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð¿Ð¸Ñ‚Ð°Ð½Ð¸Ñ. ÐœÑ‹ ÑƒÑ‡Ð¸Ñ‚Ñ‹Ð²Ð°ÐµÐ¼ Ð¿Ñ€Ð¸Ð½Ñ†Ð¸Ð¿ ÑÐ¾ÑÐµÐ´ÑÑ‚Ð²Ð°, Ñ‚ÐµÐ¼Ð¿ÐµÑ€Ð°Ñ‚ÑƒÑ€Ð½Ñ‹Ð¹ Ñ€ÐµÐ¶Ð¸Ð¼, Ð¾ÑÐ¾Ð±ÐµÐ½Ð½Ð¾ÑÑ‚Ð¸ ÑƒÑÐ»Ð¾Ð²Ð¸Ð¹ Ñ…Ñ€Ð°Ð½ÐµÐ½Ð¸Ñ. Ð§Ñ‚Ð¾Ð±Ñ‹ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð½Ðµ Ð¿Ð¾Ð¼ÑÐ»Ð¸ÑÑŒ, Ð¼Ñ‹ Ð±ÐµÑ€ÐµÐ¶Ð½Ð¾ Ð¸Ñ… ÑƒÐ¿Ð°ÐºÐ¾Ð²Ñ‹Ð²Ð°ÐµÐ¼ Ð¸ ÑƒÐºÐ»Ð°Ð´Ñ‹Ð²Ð°ÐµÐ¼.' }</SText>
            </div>
            <div style={ { marginBottom: 15 } }><SText size={ 16 } weight={ 400 } lineHeight={ 20 }>{ 'ÐŸÐ¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ðµ Ð·Ð°ÐºÐ°Ð·Ð°' }</SText>
            </div>
            <div>
                <SText>{ 'Ð•ÑÐ»Ð¸ Ð²Ñ‹ Ð½Ðµ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¿Ñ€Ð¸Ð½ÑÑ‚ÑŒ Ð·Ð°ÐºÐ°Ð· Ð² Ð¾Ð±Ð¾Ð·Ð½Ð°Ñ‡ÐµÐ½Ð½Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð¸ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð²Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ Ð´Ñ€ÑƒÐ³Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸, Ð»Ð¸Ð±Ð¾ Ñƒ Ð²Ð°Ñ ÐµÑÑ‚ÑŒ Ð´Ð¾Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¸Ñ Ð² Ð·Ð°ÐºÐ°Ð·Ðµ, Ñ‚Ð¾ Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, ÑÐ²ÑÐ¶Ð¸Ñ‚ÐµÑÑŒ Ñ ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚-Ñ†ÐµÐ½Ñ‚Ñ€Ð¾Ð¼.' }</SText>
            </div>
            <div style={ { marginBottom: 30 } }>
                <SText>{ 'Ð•ÑÐ»Ð¸ Ð²Ð°Ñ Ð½Ðµ ÑƒÑÑ‚Ñ€Ð¾Ð¸Ð»Ð¾ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð°, Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚-Ñ†ÐµÐ½Ñ‚Ñ€ Ð¸ Ð¾ÑÑ‚Ð°Ð²ÑŒÑ‚Ðµ Ð·Ð°ÑÐ²ÐºÑƒ Ð½Ð° Ð²Ð¾Ð·Ð²Ñ€Ð°Ñ‚.' }</SText>
            </div>
            <div className={ styles.grid }>
                <img src={ first } />
                <img src={ second } />
                <img src={ third } />
                <img src={ fourth } />
                <img src={ fifth } />
            </div>
        </div>
    </div>
}

export default Delivery