import React, {useRef} from 'react'
import SText from "../../../../components/SText";
import {useSelector} from "react-redux";
import ProductCard from "../../../../screens/ProductCard/ProductCard";
import styles from './styles.module.css'
import {ProductContainer, useWindowDimensions} from "../../Catalogue";

import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';
import ScrollContainer from "react-indiana-drag-scroll";

const Recommended = ({ids=[]}) => {
    const products = useSelector(state=>state.catalogue.products)

    const {width: windowWidth} = useWindowDimensions()
    const numColumns = Math.floor(windowWidth / 180)
    const width = 100/numColumns+'vw'

    const scrollRef = useRef(null)


    if (ids.length < 1)
        return null

    ids.splice(12)

    return <>
        <div className={'fake-shadow'}/>
        <div className={styles.relatedContainer}>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'C ÑÑ‚Ð¸Ð¼ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð¼ Ð±ÐµÑ€ÑƒÑ‚'}</SText>

            <ScrollContainer
                style={{marginBottom: 0, paddingBottom: 0}}
                innerRef={scrollRef}
                horizontal
                className={styles.showcaseCarousel}
            >
                {ids.map(id => {
                    if (products[id])
                        return <ProductContainer id={id} key={id} width={width}/>
                    return null
                }).filter(Boolean)}
            </ScrollContainer>

        </div>
    </>
}

export default Recommended
