import React, {useEffect, useRef, useState} from "react";
import {useTextMask} from "../../../components/utils/hooks";
import styles from './PhoneInput.module.scss'

export const phoneMask = ['+', '7', '-', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
//const phoneMask = ['+','7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/];

const PhoneInput = ({onEnter=()=>{}, onValueChange, value}) => {
    const ref = useRef(null)

    useTextMask(ref.current, {mask: phoneMask, guide: false})

    useEffect(() => {
        if (ref.current)
            ref.current.selectionStart = ref.current.selectionEnd
    }, [value])

    const handleEnter = (cb) => (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            cb()
        }
    }


    return <input
        className={styles.input}
        onFocusCapture={() => {
            if (value === '') onValueChange('+7')
        }}
        ref={ref}
        type={'cel-phone'}
        inputMode={'tel'}
        onFocus={() => (value === '' && onValueChange('+7'))}
        maxLength={18}
        onKeyUp={handleEnter(onEnter)}
        onChange={(e) => {
            onValueChange(e.target.value)
        }}
        value={value}
        placeholder={"ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°"}
    />
}

export default PhoneInput
