import React, {useContext} from "react";
import {useSelector} from "react-redux";
import styles from "./DeliveryInfo.module.css";
import {Box, Modal} from "@mui/material";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import DeliveryPriceInfo from "./DeliveryPriceInfo";
import car from '../../../assets/images/cart/car.svg'
import HelperContext from "../../../redux/HelperContext";
import closeBtn from "../../../assets/images/closeModal.svg";

export const Row = ({cart, price, min}) => {

    return (
        <div className={styles.deliveryPriceRow}>
            <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                <img src={car}/>
                <SText size={14} weight={500} lineHeight={15}
                        color={Colors.darkGray}>{'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° '} {price} {'â‚½'}</SText>
            </div>
            <div>
                <SText size={14} weight={500} lineHeight={15}
                        color={Colors.darkGray}>{`Ð¿Ñ€Ð¸ ÐºÐ¾Ñ€Ð·Ð¸Ð½Ðµ Ð¾Ñ‚ ${Math.max(min, cart)}`} {'â‚½'}</SText>
            </div>
        </div>
    )
}

const DeliveryInfo = ({onClose}) => {
    const {delivery} = useContext(HelperContext)
    const slots = useSelector(state=>state.deliverySlots)
    const options = slots.data.options || []
    const slotsLoading = slots.loading

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
        <Box style={{display: 'flex'}}>
            <div className={styles.modal}>
                <div style={{marginBottom: 20}}><SText size={20} weight={900} lineHeight={25}
                                                       color={Colors.darkGray}>{'Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¾ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐµ'}</SText></div>
                <div className={styles.deliveryPriceInfo}><DeliveryPriceInfo/></div>
                <div style={{marginBottom: 28}}><SText size={12} weight={500} color={'#9c9c9c'}
                                                       lineHeight={12}>{`ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° ${delivery.minimalOrder} Ñ€ÑƒÐ±`}</SText>
                </div>
                {options.length !== 0 && <>
                    <div style={{marginBottom: 20}}><SText size={16} weight={700} lineHeight={20}
                                                           color={Colors.darkGray}>{'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText></div>
                    <div style={{marginBottom: 33}}>

                        {options.map(([cart, price]) => (
                            <Row cart={cart} key={cart} price={price} min={delivery.minimalOrder}/>
                        ))}

                    </div>
                </>}
                <div className={styles.grayLine}/>
                <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                      color={Colors.darkGray}>{'Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¾ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐµ'}</SText>
                </div>
                <div className={styles.text}>
                    ÐŸÑ€Ð¸Ð½Ð¸Ð¼Ð°ÐµÐ¼ Ð¸ Ð¾Ñ‚Ð³Ñ€ÑƒÐ¶Ð°ÐµÐ¼ Ð·Ð°ÐºÐ°Ð·Ñ‹ 24/7. <br/>
                    Ð”Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÐ¼ Ð¾Ñ‚ 60 Ð¼Ð¸Ð½ÑƒÑ‚. <br/>
                    ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑƒÐ¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° {delivery.minimalOrder}. <br/>
                </div>
                <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                      color={Colors.darkGray}>{'Ð¡Ð¾Ñ…Ñ€Ð°Ð½Ð½Ð¾ÑÑ‚ÑŒ ÑÐ²ÐµÐ¶ÐµÑÑ‚Ð¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²'}</SText>
                </div>
                <div className={styles.text}>
                    Ð‘ÐµÑ€ÐµÐ¶Ð½Ð¾ Ð¿Ð°ÐºÑƒÐµÐ¼ Ð¸ ÑƒÐºÐ»Ð°Ð´Ñ‹Ð²Ð°ÐµÐ¼ Ð¸ Ð²ÐµÐ·Ñ‘Ð¼, ÑÐ¾Ð±Ð»ÑŽÐ´Ð°Ñ Ñ‚ÐµÐ¼Ð¿ÐµÑ€Ð°Ñ‚ÑƒÑ€Ð½Ñ‹Ð¹ Ñ€ÐµÐ¶Ð¸Ð¼ Ð¸ Ð¿Ñ€Ð¸Ð½Ñ†Ð¸Ð¿ ÑÐ¾ÑÐµÐ´ÑÑ‚Ð²Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð².
                </div>
{/*                <div style={{marginBottom: 7}}><SText size={16} weight={500} lineHeight={20}
                                                      color={Colors.darkGray}>{'ÐŸÐ¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ðµ Ð·Ð°ÐºÐ°Ð·Ð°'}</SText>
                </div>
                <div className={styles.text}>
                    Ð•ÑÐ»Ð¸ Ð²Ñ‹ Ð½Ðµ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¿Ñ€Ð¸Ð½ÑÑ‚ÑŒ Ð·Ð°ÐºÐ°Ð· Ð² Ð¾Ð±Ð¾Ð·Ð½Ð°Ñ‡ÐµÐ½Ð½Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð¸ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð²Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ Ð´Ñ€ÑƒÐ³Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸, Ð»Ð¸Ð±Ð¾ Ñƒ
                    Ð²Ð°Ñ
                    ÐµÑÑ‚ÑŒ Ð´Ð¾Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¸Ñ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ, Ñ‚Ð¾ Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, ÑÐ²ÑÐ¶Ð¸Ñ‚ÐµÑÑŒ Ñ ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚-Ñ†ÐµÐ½Ñ‚Ñ€Ð¾Ð¼. <br/>
                    Ð•ÑÐ»Ð¸ Ñƒ Ð²Ð°Ñ ÐµÑÑ‚ÑŒ Ð¿Ð¾Ð¶ÐµÐ»Ð°Ð½Ð¸Ñ Ð¿Ð¾ Ñ€Ð°Ð±Ð¾Ñ‚Ðµ ÑÐµÑ€Ð²Ð¸ÑÐ°, Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ñ‚Ð°ÐºÐ¶Ðµ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚-Ñ†ÐµÐ½Ñ‚Ñ€.

                </div>*/}
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeBtn}/></div>
        </Box>
    </Modal>
}

export default DeliveryInfo
