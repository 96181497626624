import React from "react";
import {useNavigate} from "react-router-dom";
import SText from "../../../../components/SText";
import back from '../../.././../assets/images/catalogueV2/backArrow.svg'
import search from '../../../../assets/images/catalogueV2/searchIcon.svg'
import styles from '../styles.module.scss'
import {useQueryParams} from "../../../../components/utils/ModalSpectator";

const Header = ({title = ''}) => {
    const {push} = useQueryParams()
    const navigate = useNavigate()

    function onBack() {
        window.scrollTo(0, 0)
        navigate('/')
    }

    function onSearchPress() {
        push(['search', ''])
        window.initWithSearch = true
    }

    return <div className={styles.headerWrapper}>
        <div className={styles.headerBack} onClick={onBack}><img src={back}/></div>
        <div className={styles.headerTitle}><SText size={18} lineHeight={18} weight={700}>{title}</SText></div>
        <div className={styles.headerSearch} onClick={onSearchPress}><img src={search}/></div>
    </div>
}

export default Header