import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from "react";
import cn from "classnames";
import {DIMENSIONS} from "../../mobile/utils";
import {useWindowDimensions} from "../utils/hooks";

function createLayoutMap({sections, itemHeight, sectionHeaderHeight, numColumns}) {
    const map = [];

    let offset = 0;
    sections.forEach((section, sectionIndex) => {
        section.forEach((item, itemIndex) => {
            let start = offset;
            if (itemIndex === 0) {
                // render header
                typeof sectionHeaderHeight === "function"
                    ? (offset += sectionHeaderHeight(sectionIndex))
                    : (offset += sectionHeaderHeight);
            }
            if (itemIndex % numColumns === 0) {
                // new row
                typeof itemHeight === "function"
                    ? (offset += itemHeight(sectionIndex, itemIndex))
                    : (offset += itemHeight);

                map.push({start: start, end: offset, sectionIndex, itemIndex});
            }
        });
    });
    return {layoutMap: map, totalHeight: offset};
}

function getVisibleItems({
    scrollTop,
    itemHeight,
    sections,
    totalHeight,
    sectionHeaderHeight,
    layoutMap,
    boxHeight,
    numColumns
}) {
    // assuming that section headers do not affect offset much, we can roughly find
    // the index of the closest offset
    let offsetIndex = Math.floor((scrollTop / totalHeight) * layoutMap.length);

    offsetIndex = Math.min(layoutMap.length - 1, offsetIndex);
    while (!layoutMap[offsetIndex]) {
        offsetIndex--;
    }

    while (!(layoutMap[offsetIndex].start <= scrollTop && layoutMap[offsetIndex].end >= scrollTop)) {
        if (layoutMap[offsetIndex].end < scrollTop) {
            if (offsetIndex + 1 <= layoutMap.length - 1) {
                offsetIndex++;
            } else 
                break;
            }
        else 
            offsetIndex--;
        }
    
    let endIndex = offsetIndex;

    while (layoutMap[endIndex].start < scrollTop + boxHeight && endIndex < layoutMap.length - 1) 
        endIndex = Math.min(endIndex + 1, layoutMap.length - 1);
    
    let layoutStart = layoutMap[offsetIndex],
        layoutEnd = layoutMap[endIndex];

    let itemsToRender = [];

    let anchor = layoutStart.start;
    let anchorOffset = 0;

    if (sections.length) {
        for (let sectionIndex = layoutStart.sectionIndex; sectionIndex <= layoutEnd.sectionIndex; sectionIndex++) {
            let suspendedRow = [];
            for (let itemIndex = layoutStart.sectionIndex === sectionIndex
                ? layoutStart.itemIndex
                : 0; itemIndex <= sections[sectionIndex].length - 1; itemIndex++) {
                if (itemIndex === 0) {
                    const height = typeof sectionHeaderHeight === "function"
                        ? sectionHeaderHeight(sectionIndex)
                        : sectionHeaderHeight;

                    itemsToRender.push({
                        type: "header",
                        top: anchor + anchorOffset,
                        height,
                        index: sectionIndex,
                        key: "s" + sectionIndex
                    });
                    anchorOffset += height;
                }
                if (suspendedRow.length === numColumns) {
                    const height = typeof itemHeight === "function"
                        ? itemHeight(sectionIndex, itemIndex)
                        : itemHeight;

                    itemsToRender.push({
                        index: itemIndex,
                        type: "row",
                        top: anchor + anchorOffset,
                        height,
                        sectionIndex: sectionIndex,
                        itemIndex,
                        items: suspendedRow.length
                            ? [...suspendedRow]
                            : [sections[sectionIndex][itemIndex]],
                        key: "r" + suspendedRow.join("_")
                    });
                    anchorOffset += height;
                    suspendedRow.length = 0;
                }

                suspendedRow.push(sections[sectionIndex][itemIndex]);

                if (sections[sectionIndex].length - 1 === itemIndex) {
                    const height = typeof itemHeight === "function"
                        ? itemHeight(sectionIndex, itemIndex)
                        : itemHeight;
                    let item = sections[sectionIndex][itemIndex];
                    itemsToRender.push({
                        index: itemIndex,
                        type: "row",
                        top: anchor + anchorOffset,
                        height,
                        sectionIndex: sectionIndex,
                        items: [...suspendedRow],
                        key: "r" + item
                    });
                    anchorOffset += height;
                }
            }
        }
    }

    return {itemsToRender, layoutStart, layoutEnd};
}

const UPDATE_STEP = 10;

const BigList = forwardRef(({
    sections = [],
    containerClassName = false,
    renderItem = (item) => {},
    renderSectionHeader = (index) => {},
    renderListHeader = false,
    listHeaderHeight = 0,
    renderStickyPart = false,
    stickyPartHeight = 0,
    rowClassName = "",
    itemHeight = 0,
    sectionHeaderHeight = 0,
    numColumns = 3,
    setOnTop = false,
    onViewableItemsChange = false,
    useWidth = 266
}, ref) => {

    const [scrollTop,
        setScrollTop] = useState(0);
    const width = useWindowDimensions();

    const onScroll = () => {
        setScrollTop(window.scrollY);
        if (setOnTop) {
            setOnTop(window.scrollY < DIMENSIONS.HEADER_H);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    const step = Math.floor(scrollTop / UPDATE_STEP);

    const boxHeight = window.innerHeight;

    const {layoutMap, totalHeight} = useMemo(() => {
        return createLayoutMap({sections, itemHeight, sectionHeaderHeight, numColumns});
    }, [sections, numColumns]);

    const scrollToLocation = useCallback(({
        sectionIndex = false
    }) => {
        if (layoutMap.length) {
            const offset = layoutMap.find((layoutItem) => {
                return layoutItem.sectionIndex === sectionIndex;
            });

            window.scrollTo({
                top: offset.start + listHeaderHeight + (width > 1000
                    ? 40
                    : 0),
                behavior: "auto"
            });
        }
    }, [layoutMap, numColumns]);

    useImperativeHandle(ref, () => ({scrollToLocation}));
    
    const {itemsToRender: visibleItems} = useMemo(() => {
        if (!layoutMap.length) {
            return {itemsToRender: []}
        }
        return getVisibleItems({
            scrollTop: Math.max(0, scrollTop - (listHeaderHeight - 10)), //Math.max(0, scrollTop - topOffset),
            itemHeight,
            sections,
            totalHeight,
            sectionHeaderHeight,
            boxHeight,
            numColumns,
            layoutMap
        });
    }, [layoutMap, step, sections, boxHeight, numColumns]);

    const children = useMemo(() => {
        if (!visibleItems.length) {
            return []
        }
        let _children = visibleItems.map((item) => {
            if (item.type === "header") 
                return (
                    <div
                        key={item.key}
                        style={{
                        position: "absolute",
                        width: "100%",
                        height: item.height,
                        top: item.top,
                        transform: "translate(0,0)"
                    }}>
                        {renderSectionHeader(item.index)}
                    </div>
                );
            
            if (item.type === "row") {
                return (
                    <div
                        key={item.key}
                        className={rowClassName}
                        style={{
                        display: "flex",
                        position: "absolute",
                        width: "100%",
                        height: item.height,
                        top: item.top,
                        transform: "translate(0,0)"
                    }}>
                        {item
                            .items
                            .map((id) => (
                                <div key={item.key + ":" + id}>{renderItem(id, useWidth)}</div>
                            ))}
                    </div>
                );
            }
            return null;
        });

        return _children;
    }, [visibleItems, useWidth]);

    useEffect(() => {
        if (!onViewableItemsChange || !visibleItems.length) 
            return;
        
        let viewableItems = [];
        for (let visibleItem of visibleItems) {
            if (visibleItem.type === "row" && typeof visibleItem.sectionIndex !== "undefined") {
                viewableItems.push({sectionIndex: visibleItem.sectionIndex, itemIndex: visibleItem.itemIndex});
            }
        }
        onViewableItemsChange(viewableItems);
    }, [visibleItems]);

    return (
        <div
            className={cn(containerClassName)}
            style={{
            position: "relative",
            height: totalHeight
        }}>
            {children}
        </div>
    );
});

export default BigList;
