import React from 'react'
import crossIcon from '../../../assets/images/close.svg'
import {colorToFilter} from "../../../components/utils/ColorFilter";
import Colors from "../../../components/utils/Colors";
import styles from './styles.module.css'
import SText from "../../../components/SText";

const ModalHeader = ({onClose, title}) => {
    return <div className={styles.container}>
        <div className={styles.closeButton} style={{opacity: 0}}/>
        <SText size={14} weight={500}>{title}</SText>
        <div className={styles.closeButton} onClick={onClose}>
            <img src={crossIcon} style={{filter: colorToFilter(Colors.darkGray), height: 12, width: 12}}/>
        </div>
    </div>
}

export default ModalHeader
