export function getTotals(orderData) {
    const products = Object
        .entries(orderData?.PRODUCTS || {})
        .map(([key, value]) => value)

    const tips = orderData?.COURRIER?.tips || 0
    const weightDiff = products.reduce((total, product) => {
        const sum = Number(product?.SUM),
            actualAmount = Number(product?.AMOUNT),
            orderedAmount = Number(product?.START_AMOUNT || actualAmount)
        if (orderedAmount == actualAmount) return total
        const price = sum / actualAmount * orderedAmount
        const priceDiff = sum - price

        return total + priceDiff
    }, 0)
    const delivery = Number(orderData?.DEL_SUM) || 0
    const discount = !!Number(orderData?.BONUS_SUM) ? 0 : -Number(orderData?.COUP_SUM) || 0
    const bonuses = -Number(orderData?.BONUS_SUM) || 0
    const productsSum = Number(orderData?.SUM) - bonuses - discount //- weightDiff
    const total = Number(orderData?.SUM) + delivery

    return {tips, weightDiff, delivery, discount, bonuses, productsSum, total}
}