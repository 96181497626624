import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import sendRequest, { BASE_DOMAIN } from "../../api";

function insertSorted(array, product) {

    let start = 0,
        end = array.length
    while (start < end) {
        let mid = (start + end) >>> 1
        array[mid].numb < product.numb ?
            start = mid + 1 :
            end = mid
    }

    array.splice(start, 0, product)
}

const CAROUSEL_LENGTH = 10;

function prepareCatalogueV6(categories, products, carousels) {

    function filterProducts(ids = []) {
        return ids.filter(id => products[id]?.available > 0);
    }

    const shape = {
        map: {},
        masterOrder: [],
    };

    const prevs = {
        master: -1,
        slave: -1,
        tag: -1,
    };

    for (let masterCategory of categories) {
        shape.masterOrder.push(masterCategory.id);
        let firstChildFaced = false

        let childrenOrder = [];

        for (let childCategory of masterCategory.children) {
            childrenOrder.push(childCategory.id);
            let firstTagFaced = false

            let tagsOrder = [];

            const tagsArray = Object.values(childCategory._tags || {}).sort(
                (tag1, tag2) => tag1.order - tag2.order,
            );

            for (let tag of tagsArray) {
                const products = filterProducts(tag.products_sorted_ids);

                if (!products?.length) continue;

                const id = 'tag_' + tag.id;

                tagsOrder.push(id);
                shape.map[id] = {
                    id,
                    isFirst: !firstTagFaced,
                    name: tag.name,
                    parent: childCategory.id,
                    type: 'tag',
                    icon: false,
                    cover: false,
                    children: null,
                    products,
                    carouselProducts: false,
                };
                firstTagFaced = true
                if (shape.map[prevs.tag]) shape.map[prevs.tag].next = id;
                prevs.tag = id;
            }

            shape.map[childCategory.id] = {
                isFirst: !firstChildFaced,
                id: childCategory.id,
                parent: masterCategory.id,
                name: childCategory.title,
                type: 'child',
                icon: false,
                cover: false,
                children: tagsOrder,
                products: filterProducts(childCategory.products_sorted_ids),
                carouselProducts: false,
                url: childCategory.url
            };

            firstChildFaced = true

            if (shape.map[prevs.slave])
                shape.map[prevs.slave].next = childCategory.id;
            prevs.slave = childCategory.id;
        }

        shape.map[masterCategory.id] = {
            id: masterCategory.id,
            name: masterCategory.title,
            type: 'master',
            icon: masterCategory.ico
                ? BASE_DOMAIN + '/imgs/groups/icos/' + masterCategory.ico
                : false,
            cover: false,
            children: childrenOrder,
            products: filterProducts(masterCategory.products_sorted_ids),
            carouselProducts:
                carousels[masterCategory.id]?.slice(0, CAROUSEL_LENGTH) || [],
            total: carousels[masterCategory.id]?.length - CAROUSEL_LENGTH || 0,
            url: masterCategory.url,
        };
        if (shape.map[prevs.master])
            shape.map[prevs.master].next = masterCategory.id;
        prevs.master = masterCategory.id;
    }

    shape.map[-100] = {
        carouselProducts: [],
        children: [],
        cover: false,
        icon: false,
        id: -100,
        name: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        next: 0,
        products: [],
        total: 0,
        type: "master",
        url: "myproducts"
    }

    return shape;
}

function prepareCatalogueV5(categories, products) { // todo ÑÐ´ÐµÐ»Ð°Ñ‚ÑŒ Ð² Ð¾Ð´Ð¸Ð½ Ð¿Ñ€Ð¾Ñ…Ð¾Ð´ Ð¿Ð¾ ÐºÐ°Ñ‚ÐµÐ³Ð¾Ñ€Ð¸ÑÐ¼ (ÑÐµÐ¹Ñ‡Ð°Ñ Ð´Ð²Ð° Ñ‚Ðº Ð»ÐµÐ½ÑŒ Ð±Ñ‹Ð»Ð¾ Ñ Ð½ÑƒÐ»Ñ Ð¿Ð¸ÑÐ°Ñ‚ÑŒ)

    const bigList = {
        sections: [],
        info: []
    }
    const _categories = {
        master: { order: [], map: {} },
        slave: { order: [], map: {} }
    }

    const urlMap = {}
    const productUrlMap = {}

    _categories.master.map[-100] = {
        id: -100,
        name: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        icon: false,
        children: [],
        firstSubCat: -100,
        url: 'myproducts',
        metaTitle: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        metaDescription: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        metaKeywords: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        productsSortedIds: [],
    }

    _categories.slave.map[-100] = {
        id: -100,
        name: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        tags: [],
        url: 'myproducts',
        isFirst: 0,
        isOnly: 1,
        name: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        parentName: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        metaTitle: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        metaDescription: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        metaKeywords: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
        parentId: -100
    }

    for (let category of categories) {
        _categories.master.map[category.id] = {
            id: category.id,
            name: category.title,
            icon: category.ico ? BASE_DOMAIN + '/imgs/groups/icos/' + category.ico : false,
            children: category.children,
            firstSubCat: category.children?.[0]?.id || category.id,
            url: category.url,
            metaTitle: category.meta_title,
            metaDescription: category.meta_description,
            metaKeywords: category.meta_keywords,
            productsSortedIds: category.products_sorted_ids,
        }
        urlMap[category.url] = {
            parentId: category.id,
            childId: category.id
        }
        _categories.master.order.push(category.id)

        if (category.children && category.children.length > 0) {
            category.children.forEach((child, index, arr) => {
                if (index === 0) {
                    urlMap[category.url] = {
                        ...urlMap[category.url],
                        childId: child.id,
                    }
                }
                urlMap[child.url] = {
                    parentId: category.id,
                    childId: child.id
                }

                const _products = [
                    ...(child.products_sorted_ids.filter(id => Boolean(products[id])) || []),
                    ...Object.values(child?._tags || {}).reduce((acc, tag) => {
                        return [...acc, ...tag.products_sorted_ids.filter(id => Boolean(products[id]))]
                    }, [])
                ]

                _categories.slave.order.push(child.id)
                _categories.slave.map[child.id] = {
                    id: child.id,
                    products: _products, //: child.products_sorted_ids.filter(id=>Boolean(products[id])) || [],
                    /* tags: Object.keys(child?._tags || {}).length ? Object.values(child._tags).reduce((acc, tag) => {
                         return {
                             ...acc,
                             [tag.id]: {
                                 ...tag,
                                 parentSubCat: child.id,
                                 products: tag.products_sorted_ids.filter(id=>Boolean(products[id]))
                             }
                         }
                     }, {}) : {},*/
                    tags: [],
                    url: child.url,
                    isFirst: index === 0,
                    isOnly: arr.length === 1,
                    name: child.title,
                    parentName: category.title,
                    metaTitle: category.meta_title,
                    metaDescription: category.meta_description,
                    metaKeywords: category.meta_keywords,
                    parentId: category.id
                }
            })
        } else {
            const slave = {
                id: category.id,
                products: category.products_sorted_ids.filter(id => Boolean(products[id])),
                tags: [],
                isFirst: false,
                isOnly: true,
                name: category.title,
                url: category.url,
                metaTitle: category.meta_title,
                metaDescription: category.meta_description,
                metaKeywords: category.meta_keywords,
                parentName: category.title,
                parentId: category.id
            }
            _categories.slave.order.push(category.id)
            _categories.slave.map[category.id] = slave
        }

    }

    let alreadyTitledSections = {
        master: {},
        slave: {},
    }

    for (let id of _categories.slave.order) {
        const category = _categories.slave.map[id]
        //category.products = category.products.map(p=>p.id)

        if (category.products?.length) {

            /* let tagProducts = Object.values(category.tags).reduce((acc, tag)=> {
                 const filteredProducts = tag.products_sorted_ids.filter(id=>!!products[id]).map(Number)
 
                 return [...acc, ...filteredProducts]
             }, [])
 
 
             let allProducts = [
                 ...category.products,
                 ...tagProducts
             ]*/
            bigList.sections.push(category.products)
            bigList.info.push({
                name: null,
                sectionName: category.name,
                categoryName: category.parentName,
                url: category.url,
                id: null,
                sectionId: category.id,
                parentId: category.parentId,
                renderSectionName: !alreadyTitledSections.slave[category.id] && !category.isOnly,
                renderCategoryName: !alreadyTitledSections.master[category.parentId],
            })
            alreadyTitledSections.slave[category.id] = true
            alreadyTitledSections.master[category.parentId] = true
        }

        //let singleProductsCollector = []

        /*category.tags = Object.values(category.tags)
            .sort((a, b) => a.order - b.order)
            .map((tag, index, {length}) => {
                const products = tag.products

                if (products.length < 2) {
                    singleProductsCollector = [...singleProductsCollector, ...products]
                } else {
                    bigList.sections.push(products)
                    bigList.info.push({
                        name: tag.name,
                        sectionName: category.name,
                        categoryName: category.parentName,
                        id: tag.id,
                        sectionId: category.id,
                        parentId: category.parentId,
                        renderSectionName: !alreadyTitledSections.slave[category.id],
                        renderCategoryName: !alreadyTitledSections.master[category.parentId],

                    })

                    if (tag.products.length) {
                        alreadyTitledSections.slave[category.id] = true
                        alreadyTitledSections.master[category.parentId] = true
                    }
                }
                if (singleProductsCollector.length && index === length - 1) {
                    bigList.sections.push(singleProductsCollector)
                    bigList.info.push({
                        name: 'ÐŸÑ€Ð¾Ñ‡ÐµÐµ',
                        sectionName: category.name,
                        categoryName: category.parentName,
                        id: category.id,
                        sectionId: category.id,
                        parentId: category.parentId,
                        renderSectionName: !alreadyTitledSections.slave[category.id],
                        renderCategoryName: !alreadyTitledSections.master[category.parentId],

                    })
                }

                return {...tag, products}
            })*/


    }

    for (let id in products) {
        const url = products[id].url
        productUrlMap[url] = id
    }

    urlMap['myproducts'] = {
        parentId: -100,
        childId: -100
    }

    const returnData = {
        bigList,
        categories: _categories,
        urlMap,
        productUrlMap,
    }

    return returnData;
}


function prepareCatalogue(categories, products) {
    const bigList = {
        sections: [],
        info: []
    }
    const _categories = {
        master: { order: [], map: {} },
        slave: { order: [], map: {} }
    }
    const urlMap = {}


    for (let category of categories) {
        _categories.master.map[category.id] = {
            id: category.id,
            name: category.title,
            icon: BASE_DOMAIN + '/imgs/groups/icos/' + category.ico,
            children: category.children,
            firstSubCat: category.children?.[0]?.id || category.id,
            url: category.url,
            metaTitle: category.meta_title,
            metaDescription: category.meta_description,
            metaKeywords: category.meta_keywords,
            productsSortedIds: category.products_sorted_ids,
        }
        urlMap[category.url] = {
            parentId: category.id,
            childId: null
        }

        _categories.master.order.push(category.id)

        if (category.children && category.children.length > 0) {
            category.children.forEach((child, index, arr) => {
                if (index === 0) {
                    urlMap[category.url] = {
                        ...urlMap[category.id],
                        childId: child.id
                    }
                }
                urlMap[child.url] = {
                    parentId: category.id,
                    childId: child.id
                }
                _categories.slave.order.push(child.id)
                _categories.slave.map[child.id] = {
                    id: child.id,
                    products: [],
                    /* tags: Object.keys(child?._tags || {}).length ? Object.values(child._tags).reduce((acc, tag) => {
                         return {
                             ...acc,
                             [tag.id]: {
                                 ...tag,
                                 parentSubCat: child.id,
                                 products: []
                             }
                         }
                     }, {}) : {},*/
                    tags: [],
                    url: child.url,
                    isFirst: index === 0,
                    isOnly: arr.length === 1,
                    name: child.title,
                    parentName: category.title,
                    parentId: category.id
                }
            })
        }
    }

    for (let id in products) {
        let product = products[id]
        let pushedTo = {
            cats: [],
            tags: []
        }

        const p = { id, numb: Number(product.numb) }

        for (let group of product.all_groups) {
            const category = _categories.slave.map[group]
            if (!category?.products) continue

            if (product._tags?.length) {
                product._tags.forEach(tag => {
                    if (!category.tags?.[tag.id]?.products) {
                        if (!pushedTo.cats.includes(category.id)) {
                            insertSorted(category.products, p)
                            pushedTo.cats.push(category.id)
                        }
                        return
                    }
                    if (!pushedTo.tags.includes(tag.id)) {
                        insertSorted(category.tags[tag.id].products, p)
                        pushedTo.tags.push(tag.id)
                    }
                })
            } else {
                if (!pushedTo.cats.includes(category.id)) {
                    insertSorted(category.products, p)
                    pushedTo.cats.push(category.id)
                }
            }
        }
    }

    let alreadyTitledSections = {
        master: {},
        slave: {},
    }

    for (let id of _categories.slave.order) {
        const category = _categories.slave.map[id]
        category.products = category.products.map(p => p.id)

        if (category.products?.length) {
            bigList.sections.push(category.products)
            bigList.info.push({
                name: null,
                sectionName: category.name,
                categoryName: category.parentName,
                id: null,
                sectionId: category.id,
                parentId: category.parentId,
                renderSectionName: !alreadyTitledSections.slave[category.id],
                renderCategoryName: !alreadyTitledSections.master[category.parentId],
            })
            alreadyTitledSections.slave[category.id] = true
            alreadyTitledSections.master[category.parentId] = true
        }

        category.tags = Object.values(category.tags)
            .sort((a, b) => a.order - b.order)
            .map((tag, index) => {
                const products = tag.products.map(p => p.id)

                bigList.sections.push(products)
                bigList.info.push({
                    name: tag.name,
                    sectionName: category.name,
                    categoryName: category.parentName,
                    id: tag.id,
                    sectionId: category.id,
                    parentId: category.parentId,
                    renderSectionName: !alreadyTitledSections.slave[category.id],
                    renderCategoryName: !alreadyTitledSections.master[category.parentId],

                })

                if (tag.products.length) {
                    alreadyTitledSections.slave[category.id] = true
                    alreadyTitledSections.master[category.parentId] = true
                }

                return { ...tag, products }
            })


    }

    urlMap['myproducts'] = {
        parentId: -100,
        childId: -100
    }

    return {
        bigList,
        categories: _categories,
        urlMap
    }
}

const initialState = {
    products: {},
    bigList: {
        sections: [],
        info: []
    },
    catV6: {},
    categories: {
        master: { order: [], map: {} },
        slave: { order: [], map: {} }
    },
    urlMap: {},
    productUrlMap: {},
    loaded: false,
    loadedRegion: false,
}

const getSubdomain = () => {
    let parts = window.location.hostname.split('.');
    if (typeof parts[1] !== "undefined") {
        switch (parts[0]) {
            case 'spb':
                return 78;
            case 'kaz':
                return 16;
            case 'nnov':
                return 52;
            case 'rnd':
                return 61;
            default:
                return 77;
        }
    }

    return 77;
}

export const fetchCatalogue = createAsyncThunk(
    'catalogue/fetchCatalogue',
    async (_, { getState }) => {
        const region = getState().deliverySlots.data?.region || getSubdomain() || 77

        const response = await sendRequest('appStartUp_v5', {
            REGION: region,
            source: 'mobile',
            TEST_CASE: 'A',
            token: null
        })

        if (!response.groups) throw (new Error('popa'))
        return {
            REGION: region,
            ...response
        }

    }
)

export const catalogueSlice = createSlice({
    name: 'catalogue',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCatalogue.fulfilled, (state, action) => {
            const { bigList, categories, urlMap, productUrlMap } = prepareCatalogueV5(action.payload.groups, action.payload.products)
            state.urlMap = urlMap
            state.productUrlMap = productUrlMap
            state.catV6 = prepareCatalogueV6(
                action.payload.groups,
                action.payload.products,
                action.payload.top_groups_products,
            );
            state.products = action.payload.products
            state.bigList = bigList
            state.categories = categories
            state.loaded = true
            state.loadedRegion = action.payload.REGION

        })
        builder.addCase(fetchCatalogue.rejected, (state) => {
            console.log('FAILED TO LOAD CATALOGUE')
            if (state.loaded) return

        })
    }
})

export const { } = catalogueSlice.actions

export default catalogueSlice.reducer
