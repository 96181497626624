import React, { createContext, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import sendRequest from "../../api";
import { fetchCatalogue } from "../../redux/reducers/catalogue";
import { refreshOrders } from "../../redux/reducers/user";
import { cartClear } from "../../redux/reducers/cart";
import { useNavigate } from "react-router-dom";

const initialState = {
    mode: 'none', // 'replace', 'add'
    deadline: false, // moment
    missedProducts: [],
    newProducts: [],
    altProducts: []
}

const initialContextValue = {
    ...initialState,
    remains: moment.duration(0, 'seconds'),
    dismissTradeOff: () => { },
    confirmTradeOff: () => { },
    setAddMode: () => { },
}

export const OrderProductXChangeContext = createContext(initialContextValue)

export const useOrderProductXChange = () => {
    const [state, setState] = useState(initialState)
    const [remains, setRemains] = useState(moment.duration(0, 'seconds'))
    const [isModalDownload, setIsModalDownload] = useState(false)
    const orders = useSelector(state => state.user.orders.items)
    const user = useSelector(state => state.user)
    const cart = useSelector(state => state.cart)
    const [ticker, setTicker] = useState(-1) // Ñ Ð¾Ñ‡ÐµÐ½ÑŒ ÑƒÑÑ‚Ð°Ð» Ð¸ Ð½Ð°Ñ‡Ð°Ð» Ð³Ð¾Ð²Ð½Ð¾ÐºÐ¾Ð´Ð¸Ñ‚ÑŒ, ÑÐ¾Ñ€Ñ€Ð¸
    const lastOrder = orders?.[0]
    const navigation = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const timer = setInterval(() => {
            setTicker(p => p + 1)
        }, 10000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    function getRemains() {
        const now = moment().unix()
        const deadline = state.deadline.unix()
        const duration = moment.duration(deadline - now, 'seconds')

        if (duration.asSeconds() < 0) {
            appendState(initialState)
        }

        setRemains(duration)
    }

    useEffect(() => {
        if (!user.apikey)
            dismissTradeOff()
    }, [user.apikey])

    useEffect(() => {
        if (!state.deadline) return

        getRemains()
        const timer = setInterval(() => {
            getRemains()
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [state.deadline])

    function appendState(val = {}) {
        setState(prev => ({ ...prev, ...val }))
    }

    async function checkLastOrder() {
        if (!lastOrder?.ID || lastOrder.newStatus === 'cancelled' || window.tradeOffDismissed === true) return

        const payload = {
            id: lastOrder.ID,
            apikey: user.apikey
        }
        let altProducts = []

        const response = await sendRequest('getMissedProductsOrder', payload)
        if (response?.missed?.length) {
            dispatch(fetchCatalogue())

            const _deadline = response.missed.reduce((time, item) => {
                altProducts = [...altProducts, ...(item.similarProducts || [])]
                const deadline = moment(item.deadline, 'YYYY-MM-DD HH:mm:SS')
                if (deadline.isAfter(time))
                    return deadline
                else
                    return time
            }, moment())

            if (_deadline.isBefore(moment())) return

            appendState({
                mode: 'replace',
                deadline: _deadline,
                missedProducts: response?.missed || [],
                altProducts,
            })
        }
    }

    function dismissTradeOff() {
        appendState({
            mode: 'none',
            missedProducts: [],
            altProducts: []
        })
        window.tradeOffDismissed = true

    }

    async function confirmTradeOff() {
        const payload = {
            apikey: user.apikey,
            order: {
                id: lastOrder?.ID,
                products: Object.entries(cart.items).map(([id, amount], index) => {
                    return {
                        replacedId: state.missedProducts?.[index]?.id || false,
                        replaceableId: id,
                        quantity: amount
                    }
                }),
            }
        }

        const response = await sendRequest('replaceProductOrders', payload)
        if (response) {
            dispatch(refreshOrders())
            dispatch(cartClear())
            appendState(initialState)
        }
    }

    async function setAddMode() {
        appendState({
            mode: 'add',
            deadline: moment().add(10, 'minutes')
        })
        navigation('/')
    }


    useEffect(() => {
        checkLastOrder()
    }, [lastOrder, ticker])

    return { ...state, isModalDownload, setIsModalDownload, remains, dismissTradeOff, confirmTradeOff, setAddMode, appendState }
}

export default OrderProductXChangeContext