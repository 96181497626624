import React from "react";
import {useSelector} from "react-redux";
import SText from "../../../../components/SText";
import {useNavigate} from "react-router-dom";
import styles from './styles.module.css'

const Item = ({id}) => {
    const navigate = useNavigate()
    const data = useSelector(state=>state.catalogue.categories.master.map[id])
    if (!data) return null

    function onPress () {
        navigate('/'+data.url)
    }

    return <div className={styles.shortListRow} onClick={onPress}>
        <div className={styles.shortListIconHolder}>
            <img src={data.icon}/>
        </div>
        <SText size={20} weight={900}>
            {data.name.toUpperCase()}
        </SText>
    </div>
}

const ShortList = () => {
    const categories = useSelector(state=>state.catalogue.categories.master.order)

    return <div>
        {categories.map(id=><Item id={id} key={id}/>)}
    </div>
}

export default ShortList
