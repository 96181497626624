import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sendRequest, {BASE_URL} from "../../api";

const initialState = {
    supportChat: {},
    interval: false
}

export const fetchSupportChat = createAsyncThunk(
    'chats/fetchSupportChat',
    async (_, {getState}) => {
        const payload = {
            apikey: getState().user.apikey,
            limit: 100,
            offset: 0,
        }
        return await sendRequest('getCrmMessages', payload)
    }
)
const PING_RATE = 5000

export const startChatService = createAsyncThunk(
    'chats/startChatService',
    async (_, {dispatch, getState}) => {
        dispatch(fetchSupportChat())
        return setInterval(() => dispatch(fetchSupportChat()), PING_RATE)
    }
)
export const stopChatService = createAsyncThunk(
    'chats/stopChatService',
    async (_, {dispatch, getState}) => {
        const interval = getState().chats.interval
        clearInterval(interval)
    }
)

export const sendSupportMessage = createAsyncThunk(
    'chats/sendSupportMessage',
    async (message, {getState, dispatch}) => {
        const SendData = new FormData()
        SendData.append('action', 'sendCrmMessage')
        SendData.append('apikey', getState().user.apikey)
        SendData.append('message', message.text)
        //type !== 'support' && SendData.append('orderId', orderId)
        SendData.append('PICTURE', message._image ? message._image : false)

        const request = {
            credentials: 'include',
            method: "POST",
            body: SendData,
            headers: {
                'Accept': 'application/json',
                ...(SendData?.getHeaders ? SendData.getHeaders() : {})
            }
        }

        await fetch(BASE_URL, request).then(response => response.json()).then((response) => {
            //console.log(response)
            if (response?.status?.result === 'SUCCESS') {
                dispatch(fetchSupportChat())
            }
        })
    }
)

const chatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSupportChat.fulfilled, (state, action) => {
            if (!action.payload) return state
            state.supportChat = action.payload
        })
        builder.addCase(startChatService.fulfilled, (state, action) => {
            state.interval = action.payload
        })
    }
})

export default chatsSlice.reducer

export const {} = chatsSlice.actions