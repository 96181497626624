import React, { useContext, useEffect, useState } from "react";
import styles from './OrdersHistory.module.scss'
import { Box, Modal } from "@mui/material";
import { Courier, StatusBlock } from "./AccountNavigationModal";
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import chat from "../../assets/images/userAccount/chatBtn.svg";
import { getPrettyNumber, getStatusLabelProps } from "./OrdersHistory";
import { roundPrice, shortAddress } from "../../components/utils/other";
import OrderProductXChange from "../../components/utils/OrderProductXChange";
import sendRequest, { BASE_DOMAIN } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { getTotals } from "./utils";
import greenTick from '../../assets/images/check-mark.svg'
import warning from '../../assets/images/warning.svg'
import { colorToFilter } from "../../components/utils/ColorFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddCard from "../AddCardScreen/AddCard";
import MapHelper from "../Map/MapHelper";
import closeBtn from "../../assets/images/closeModal.svg";
import { cartClear, cartPlus } from "../../redux/reducers/cart";
import { useLastOrder } from "../../components/utils/hooks";
import OrderProductXChangeContext from "../../components/utils/OrderProductXChange";
import { Code } from "../UserAccount/Bonuses";
import codeStyles from '../UserAccount/Bonuses.module.scss'
import { createWidget } from '@sber-ecom-core/sberpay-widget';
import SberPayButton from '../../assets/images/payments/sberPay.png'

const OrdersHistoryModal = ({ onClose, id, setChatId }) => {
    const [orderData, setOrderData] = useState(false)
    const user = useSelector(state => state.user)
    const [courierMapVisible, setCourierMapVisible] = useState()
    const [canAdd, setCanAdd] = useState(false)
    const lastOrder = useLastOrder()
    const { setAddMode } = useContext(OrderProductXChangeContext)
    const [searchParams, setSearchParams] = useSearchParams()
    async function loadCanAdd() {

        const payload_ = {
            id: lastOrder.ID,
            apikey: user.apikey
        }
        const response_ = await sendRequest('getMissedProductsOrder', payload_)

        const canAdd = response_?.isOpenToAdd || false

        setCanAdd(canAdd)
    }

    const registerSberPayOrder = async (id) => {

        const payload = {
            apikey: user.apikey,
            order: id,
            security: window?.security,
        }

        return await sendRequest('registerOrderSberPay', payload)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (lastOrder) {
                await loadCanAdd()
            }
        }
        fetchData()
    }, [])

    const getOrderData = async (id) => {

        const payload = {
            apikey: user.apikey,
            id: id,
            security: window?.security,
        }

        return await sendRequest('getOrderData', payload)
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await getOrderData(id)
            setOrderData(data.order)

            if (window.security)
                window.security = null

            if (window.orderId)
                window.orderId = null
        }

        fetchData()
    }, [])

    if (!orderData) return null

    return <Modal
        open={ true }
        onClose={ onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 100 } }
    >
        <Box style={ { display: 'flex' } }>
            <div className={ styles.modalWrapper }>
                <div className={ styles.leftContent }>
                    <OrderStatus id={ id } setChatId={ setChatId } mapVisible={ courierMapVisible }
                        openMap={ () => setCourierMapVisible(prev => !prev) }
                        orderData={ orderData } />
                    <div style={ { marginLeft: 10 } }>
                        <div style={ { marginBottom: 12 } }>
                            <div><SText weight={ 500 } size={ 14 } lineHeight={ 17 }
                                color={ Colors.darkGray }>{ 'Ð’Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:' }</SText></div>
                            <SText weight={ 500 } size={ 14 } lineHeight={ 17 }
                                color={ Colors.darkGray }>{ orderData.PRETTY_DATE + ', ' + orderData.TIME }</SText>
                        </div>
                        <div style={ { marginBottom: 12 } }>
                            <div>
                                <SText weight={ 500 } size={ 14 } lineHeight={ 17 }
                                    color={ Colors.darkGray }>{ 'ÐÐ´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:' }</SText>
                            </div>
                            <SText weight={ 500 } size={ 14 } lineHeight={ 17 }
                                color={ Colors.darkGray }>{ shortAddress(orderData.ADRESS) }</SText>
                        </div>
                        { orderData.comment.trim() && <div>
                            <SText size={ 14 } lineHeight={ 17 } weight={ 500 }
                                color={ '#a0a0a0' }>{ `ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹: ${orderData.comment}` }</SText>
                        </div> }
                    </div>

                    { canAdd && (orderData.NUMBER === lastOrder.NUMBER) && <div onClick={ () => {
                        setAddMode()
                        setSearchParams('addProducts')
                    } } className={ styles.addProductsBtn }>
                        <SText size={ 16 } weight={ 500 } color={ '#fff' }>{ 'Ð”Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ' }</SText>
                    </div> }
                </div>
                <div className={ styles.rightContent }>
                    { courierMapVisible && <Map order={ orderData } /> }
                    <div style={ { marginLeft: 44 } }>
                        <div style={ { marginBottom: 25 } }><SText size={ 16 } weight={ 700 } lineHeight={ 20 }
                            color={ Colors.darkGray }>{ 'Ð¡Ð¿Ð¸ÑÐ¾Ðº Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²' }</SText></div>
                        <Products products={ orderData.PRODUCTS } />
                        <div style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: 26, maxWidth: 495 } }>
                            <OrderPrice order={ orderData } />
                        </div>
                        <div style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: 26, maxWidth: 495 } }>
                            <RepeatAndPay order={ orderData } orderId={ id } onClose={ onClose } onRegisterSberPay={ registerSberPayOrder } />
                        </div>
                    </div>
                    { user && user.personalPromo &&
                        <div className={ codeStyles.codeHolder_order }>
                            <Code promo={ user.personalPromo } />
                        </div>
                    }
                </div>
            </div>
            <div onClick={ onClose } className={ styles.closeModal }><img src={ closeBtn } /></div>
        </Box>
    </Modal>
}

const Map = ({ order }) => {
    return <div style={ { borderRadius: 12, overflow: 'hidden', width: '100%', marginBottom: 30 } }><MapHelper
        setAdressAndGps={ () => {
        } }
        resetGps={ () => {
        } }
        setDisabled={ () => {
        } }
        ref={ () => {
        } }
        inputAddress={ '' }
        setInputAddres={ () => {
        } }
        setSuggestions={ () => {
        } }
        mapLoaded={ () => {
        } }
        showButtons={ 'true' }
        GPS={ [order.COURRIER.lat, order.COURRIER.lon] || [55.754638, 37.621633] }
        order={ order }
    /></div>
}

const RepeatAndPay = ({ order, orderId, onClose, onRegisterSberPay }) => {
    const navigate = useNavigate()
    const [pay, setPay] = useState(false)
    const dispatch = useDispatch()
    const totals = getTotals(order)
    const widget = createWidget("PRODUCTION");
    const handleClickSberPay = async () => {
        let resultRegister = await onRegisterSberPay(orderId);
        const params = {
            bankInvoiceId: resultRegister.payment_id,
            backUrl: 'https://ecomarket.ru/sberpay_test',
        };
        widget.open(params);
        onClose();
    };

    const onRepeatClick = () => {
        dispatch(cartClear())
        const products = Object.values(order.PRODUCTS)
        products.map(product => dispatch(cartPlus(product.ID)))
        navigate('/cart')
    }

    return (
        <div style={ { width: '100%' } }>
            { order.PAID == 1 && <div style={ { paddingBottom: 70 } } className={ styles.paymentStatusLabel }>
                <img src={ greenTick } style={ { filter: colorToFilter(Colors.green) } } />
                <SText color={ Colors.green } size={ 14 } weight={ 500 }>{ 'Ð—Ð°ÐºÐ°Ð· Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½' }</SText>
            </div> }
            { (order.PAID == 0 && order.newStatus !== 'cancelled' && order.PAYMENT != 1) &&
                <div className={ styles.paymentStatusLabel }>
                    <img src={ warning } />
                    <SText color={ Colors.red } size={ 14 } weight={ 500 }>{ 'Ð—Ð°ÐºÐ°Ð· Ð½Ðµ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½' }</SText>
                </div> }
            { (order.PAID == 0 && order.newStatus !== 'cancelled') &&
                <div style={ { marginBottom: 25, display: 'flex', justifyContent: 'flex-end' } }>
                    <div style={ { width: 340, textAlign: 'end' } }>
                        <SText size={ 12 } weight={ 400 } lineHeight={ 15 }
                            color={ '#bdbdbd' }>{ `Ð•ÑÐ»Ð¸ Ñƒ Ð²Ð°Ñ ÐµÑÑ‚ÑŒ Ð²Ð¾Ð¿Ñ€Ð¾ÑÑ‹ Ð¿Ð¾ Ð¾Ð¿Ð»Ð°Ñ‚Ðµ, Ñ‚Ð¾ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² Ð½Ð°ÑˆÑƒ ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸ ` } </SText>
                        <SText size={ 12 } weight={ 400 } lineHeight={ 15 } color={ '#0055a5' }>{ '8 (495) 159-90-09' }</SText>
                        <SText size={ 12 } weight={ 400 } lineHeight={ 15 } color={ '#bdbdbd' }>{ ` Ð¸Ð»Ð¸ info@ecomarket.ru` }</SText>
                    </div>
                </div> }
            { (order.PAID == 0 && order.newStatus !== 'cancelled') &&
                <div className={ styles.payButtons }>
                    <div onClick={ onRepeatClick } className={ styles.repeatBtn }><SText size={ 16 } weight={ 400 }
                        color={ Colors.darkGray }
                        lineHeight={ 16 }>{ 'ÐŸÐ¾Ð²Ñ‚Ð¾Ñ€Ð¸Ñ‚ÑŒ ÑÑ‚Ð¾Ñ‚ Ð·Ð°ÐºÐ°Ð·' }</SText>
                    </div>
                    <div onClick={ () => setPay(true) } className={ styles.payBtn }><SText size={ 16 } weight={ 300 } color={ '#fff' }
                        lineHeight={ 16 }>{ 'ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ ' + roundPrice(totals.total) + ' â‚½' }</SText>
                    </div>
                    <div onClick={ handleClickSberPay } className={ styles.sberPayBtn }><img src={ SberPayButton } height={ 49 } /></div>
                </div> }
            { pay && <AddCard orderId={ orderId } onClose={ () => setPay(false) } /> }
        </div>
    )
}

const OrderPriceRow = ({ title, value = 0, isFinal = false }) => {
    if (!value) return null

    return <div className={ styles.totalRow } style={ { marginTop: isFinal ? 20 : 6 } }>
        <SText size={ isFinal ? 15 : 14 } weight={ isFinal ? 700 : 400 }>{ title }</SText>
        <SText size={ isFinal ? 15 : 14 } weight={ isFinal ? 700 : 500 }>{ roundPrice(value) + ' â‚½' }</SText>
    </div>
}

const OrderPrice = ({ order }) => {
    const totals = getTotals(order)

    return <div className={ styles.orderPriceWrapper }>
        <OrderPriceRow title={ 'Ð§Ð°ÐµÐ²Ñ‹Ðµ ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ:' } value={ totals.tips } />
        <OrderPriceRow title={ 'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²:' } value={ totals.productsSum } />
        <OrderPriceRow title={ 'Ð˜Ð·Ð¼ÐµÐ½ÐµÐ½Ð¸Ñ Ð² Ð²ÐµÑÐµ:' } value={ totals.weightDiff } />
        <OrderPriceRow title={ 'Ð¡ÑƒÐ¼Ð¼Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸:' } value={ totals.delivery } />
        <OrderPriceRow title={ 'Ð’Ñ‹ Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ð¸Ð»Ð¸ Ð¿Ñ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´:' } value={ totals.discount } />
        <OrderPriceRow title={ 'ÐžÐ¿Ð»Ð°Ñ‡ÐµÐ½Ð¾ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð°Ð¼Ð¸:' } value={ totals.bonuses } />
        <OrderPriceRow title={ 'Ð˜Ñ‚Ð¾Ð³:' } value={ totals.total } isFinal />
    </div>
}

export const Products = ({ products }) => {
    const productsIds = Object.keys(products)
    return <div style={ { marginBottom: 30 } }>
        {
            productsIds.map((id, index) => <ProductRow key={ index } amount={ products[id].AMOUNT } edIzm={ products[id].ed_izm } id={ products[id].ID } />)
        }
    </div>
}

const ProductRow = ({ id, amount, edIzm }) => {
    const [productData, setProductData] = useState(false)
    const product = useSelector(state => state.catalogue.products?.[id])

    const getProductById = async () => {
        const payload = {
            id: id,
            region: 77,
        }
        return await sendRequest('getProductById_v2', payload)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!product) {
                setProductData(await getProductById(id))
            } else {
                setProductData(product)
            }
        }
        fetchData()
    }, [])

    if (!productData) return null

    return <div className={ styles.productRow }>
        <div style={ { display: 'flex', alignItems: 'center', flex: 4 } }>
            <img src={ BASE_DOMAIN + productData.thumb } style={ { marginRight: 15 } } />
            <div className={ styles.productTitle }><SText size={ 14 } lineHeight={ 17 } weight={ 500 }
                color={ Colors.darkGray }>{ productData.title }</SText></div>
        </div>
        <SText size={ 12 } lineHeight={ 15 } weight={ 500 } color={ Colors.darkGray }>{ amount + ' ' + edIzm }</SText>
        <div style={ { flex: 1, textAlign: "center" } }><SText size={ 14 } lineHeight={ 17 } weight={ 500 }
            color={ Colors.darkGray }>{ productData.price + ' â‚½' }</SText>
        </div>
    </div>
}

const OrderStatus = ({ orderData, openMap, mapVisible, setChatId, id }) => {
    const { remains: xChangeRemains } = useContext(OrderProductXChange)
    const [searchParams, setSearchParams] = useSearchParams()

    return <div
        style={ { paddingBottom: (getStatusLabelProps(orderData.newStatus).code === -1 || !orderData.COURRIER) ? 10 : 31 } }
        className={ styles.lastOrder }>
        <div style={ { marginBottom: 20 } }><SText size={ 16 } weight={ 700 } lineHeight={ 20 }
            color={ Colors.darkGray }>{ getPrettyNumber(orderData.NUMBER) }</SText>
        </div>
        <StatusBlock currentStatus={ getStatusLabelProps(orderData.newStatus).code } />

        { getStatusLabelProps(orderData.newStatus).code != 3 &&
            xChangeRemains.minutes() > 0 ? <div style={ { marginBottom: 35 } }><SText size={ 16 } weight={ 900 } lineHeight={ 19 }
                color={ Colors.darkGray }>{ xChangeRemains.minutes() }</SText>
        </div> : false }
        { orderData.COURRIER ?
            <>
                <Courier order={ orderData } />
                <div className={ styles.lastOrderBtns }>
                    <div onClick={ () => setSearchParams('chat=' + id) }
                        className={ styles.chatBtn }>
                        <img src={ chat } />
                        <SText size={ 12 } lineHeight={ 14 } weight={ 700 } color={ '#fff' }>{ 'Ð¡Ð²ÑÐ·Ð°Ñ‚ÑŒÑÑ' }</SText>
                    </div>
                    <div onClick={ openMap } style={ { opacity: mapVisible ? .5 : 1 } } className={ styles.mapBtn }>
                        <SText size={ 12 } lineHeight={ 14 } weight={ 700 } color={ '#fff' }>{ 'ÐšÑƒÑ€ÑŒÐµÑ€ Ð½Ð° ÐºÐ°Ñ€Ñ‚Ðµ' }</SText>
                    </div>
                </div>
            </> : null }
    </div>
}

export default OrdersHistoryModal
