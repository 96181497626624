import React, {useContext, useEffect, useRef, useState, useMemo} from "react";
import {useSelector} from "react-redux";
import Header from "../Catalogue/components/Header";
import ProductsCarousel from "./components/ProductsCarousel";
import Showcase from "../Catalogue/components/Showcase";
import BottomBar from "../Catalogue/components/BottomBar";
import OrderProductXChangeContext from "../../../components/utils/OrderProductXChange";
import {useNavigate, useSearchParams} from "react-router-dom";
import CatalogueCarousel from "../Catalogue/components/CatalogueCarousel";
import styles from "../../../components/BigList/styles.module.css";
import {DIMENSIONS} from "../../utils";
import search from "../../../assets/images/header/search.svg";
import SText from "../../../components/SText";
import DeliveryBanner from "../../../components/Banner/DeliveryBanner";
import {useQueryParams} from "../../../components/utils/ModalSpectator";
import {Helmet} from "react-helmet";

const ListHeader = () => {
    const {push} = useQueryParams()

    return <div>
        <Header/>
        <Showcase mobile={true}/>
        <div className={styles.inputBtnWrapper} style={{height: DIMENSIONS.SEARCH_BAR_HEIGHT, marginBottom: 25}}>
            <div onClick={() => {
                push(['search', ''])
                window.initWithSearch = true
            }} className={styles.inputBtn}>
                <img src={search}/>
                <SText size={14} weight={400} lineHeight={17} color={'#a6a6a6'}>{'ÐŸÐ¾Ð¸ÑÐº Ð¿Ð¾ ÐºÐ°Ñ‚Ð°Ð»Ð¾Ð³Ñƒ'}</SText>
            </div>
        </div>

    </div>
}

const CatalogueV2 = () => {
    const user = useSelector(state => state.user)
    const categories = useSelector(state => state.catalogue.catV6.masterOrder)
    const products = useSelector(state => state.catalogue.products)
    const {altProducts} = useContext(OrderProductXChangeContext)
    const recommended = useSelector(state => state.user.personalRecommendations)
    const navigate = useNavigate()
    const {push} = useQueryParams()
    const [showDeliveryBanner, closeDeliveryBanner] = useState(!user.apikey)

    function onCategoryPress(id) {
        navigate('Category', {id})
    }

    useEffect(() => {
        if (window?.newUserOrderId) {
            setTimeout(() => {
                push(['auth', window.newUserPhone])
            }, 200)
        } else if (window.orderId) {
            setTimeout(() => {
                push(['orderDetails', window.orderId])
            }, 200)
        }
    }, [window.orderId, window?.newUserOrderId])

    let data = categories

    if (altProducts.length) {
        const additionalRowData = {
            name: 'Ð ÐµÐºÐ¾Ð¼ÐµÐ½Ð´ÑƒÐµÐ¼ Ðº Ð·Ð°Ð¼ÐµÐ½Ðµ',
            items: altProducts.filter(id => !!products[id]),
        }
        data = [additionalRowData, ...data]
    }

    if (recommended.length) {
        const recItems = recommended.filter(id => !!products[id]);
        const totalRecItems = recItems.length;
        const showRecItems = recItems.splice(0,15);
        const additionalRowData = {
            slim: true,
            id: -100,
            name: 'Ð£Ð¶Ðµ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°Ð»Ð¸',
            items: showRecItems,
            total: totalRecItems,
            url: 'myproducts'
        }
        
        data = [...data]
        data.splice(1, 0, additionalRowData)
    }

    return (
        <div style={{paddingBottom: 50, position: 'relative'}}>
            <Helmet>
                <title>Ð˜Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚-Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½ Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚ â€” Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð½Ð° Ð´Ð¾Ð¼ Ð² ÐœÐ¾ÑÐºÐ²Ðµ Ð¸ ÐœÐ¾ÑÐºÐ¾Ð²ÑÐºÐ¾Ð¹ Ð¾Ð±Ð»Ð°ÑÑ‚Ð¸, ÐºÑƒÐ¿Ð¸Ñ‚ÑŒ
                    Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð¿Ð¸Ñ‚Ð°Ð½Ð¸Ñ Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹ Ð² Ð¾Ð½Ð»Ð°Ð¹Ð½-Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ</title>
                <meta name="description"
                      content="Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð±Ð¸Ð¾, ÑÐºÐ¾Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð², Ð²ÑÐµÐ³Ð¾ Ð·Ð° 90 Ð¼Ð¸Ð½ÑƒÑ‚ Ð¿Ð¾ Ð³Ð¾Ñ€Ð¾Ð´Ñƒ Ð¸ Ð¾Ð±Ð»Ð°ÑÑ‚Ð¸. ÐžÐ³Ñ€Ð¾Ð¼Ð½Ñ‹Ð¹ Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚, Ð±Ð¾Ð»ÐµÐµ 1000 Ð½Ð°Ð¸Ð¼ÐµÐ½Ð¾Ð²Ð°Ð½Ð¸Ð¹.Ð’ Ð½Ð°Ð»Ð¸Ñ‡Ð¸Ð¸ Ð¥Ð°Ð»ÑÐ»ÑŒÐ½Ñ‹Ðµ Ð¸ ÐšÐ¾ÑˆÐµÑ€Ð½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹. ÐŸÑ€Ð°Ð²Ð¸Ð»ÑŒÐ½Ð¾Ðµ Ð¸ Ð·Ð´Ð¾Ñ€Ð¾Ð²Ð¾Ðµ Ð¿Ð¸Ñ‚Ð°Ð½Ð¸Ðµ Ñ Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚Ð¾Ð¼!"
                      data-react-helmet="true"/>
                <meta name="keywords" content="Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²" data-react-helmet="true"/>
                <meta name="viewport"
                      content="width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no"/>
            </Helmet>
            {showDeliveryBanner && <DeliveryBanner show={closeDeliveryBanner} />}
            <ListHeader/>
            <div style={{position: 'sticky', top: 0, left: 0, zIndex: 2, background: '#fff', padding: '5px 0 0'}}>
                <CatalogueCarousel currentSection={-1} onSelect={(_, id) => onCategoryPress(id)}/>
            </div>

            {
                data.map((item, index) => {
                    if (item.items && item.name)
                        return <ProductsCarousel key={index} name={item.name} items={item.items} slim={item.slim} item={item} />
                    else
                        return <ProductsCarousel key={index} categoryId={item} slim={item.slim}/>;
                })
            }
            <BottomBar isOnTop={false}/>
        </div>
    );
}

export default CatalogueV2