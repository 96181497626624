import React from "react"
import styles from './About.module.scss'
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";

const About = () => {

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'Ðž Ð½Ð°Ñ'}</SText></div>
        </div>
        <div className={styles.container}>
            <div style={{marginBottom: 30}}><SText size={36} lineHeight={36} weight={500}>{'Ðž Ð½Ð°Ñ'}</SText></div>
            <div style={{marginBottom: 10}}><SText>{'ÐÐµÐ¼Ð½Ð¾Ð³Ð¾ Ð¾Ð± Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚Ðµ.'}</SText></div>
            <div style={{marginBottom: 16}}>
                <SText>{'ÐÐµ Ð½Ð°Ð´Ð¾ Ð±Ð¾Ð»ÑŒÑˆÐµ Ñ‚Ñ€Ð°Ñ‚Ð¸Ñ‚ÑŒ ÑÐ²Ð¾Ñ‘ Ð²Ñ€ÐµÐ¼Ñ Ð² Ð¿Ð¾Ð¸ÑÐºÐ°Ñ… Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð². Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚ Ð´Ð¾ÑÑ‚Ð°Ð²Ð¸Ñ‚ Ð¸Ñ… Ð²Ð°Ð¼ Ð²ÑÐµÐ³Ð¾ Ð·Ð° 90 Ð¼Ð¸Ð½ÑƒÑ‚.'}</SText>
            </div>
            <div style={{marginBottom: 20, marginLeft: 40}}><SText>{'Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText></div>
            <div style={{marginBottom: 20, marginLeft: 40}}><SText>{'ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚Ðµ ÑÐ²Ð¾Ð¹ Ð·Ð°ÐºÐ°Ð· Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹ Ð´Ð¾ Ð¿Ð¾Ñ€Ð¾Ð³Ð°'}</SText>
            </div>
            <div style={{marginBottom: 20, marginLeft: 40}}>
                <SText>{'ÐšÐ¾Ð¼Ð°Ð½Ð´Ð° Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚ ÑÑÐºÐ¾Ð½Ð¾Ð¼Ð¸Ñ‚ Ð²Ð°Ð¼ Ð²Ñ€ÐµÐ¼Ñ Ð¸ ÑÐ¸Ð»Ñ‹, ÐºÐ¾Ñ‚Ð¾Ñ€Ð¾Ðµ Ð²Ñ‹ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¿Ð¾Ñ‚Ñ€Ð°Ñ‚Ð¸Ñ‚ÑŒ Ð½Ð° Ð±Ð¾Ð»ÐµÐµ Ð¿Ð¾Ð»ÐµÐ·Ð½Ð¾Ðµ Ð´ÐµÐ»Ð¾, Ñ‡ÐµÐ¼ Ð¿Ð¾Ð¸ÑÐº Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð².'}</SText>
            </div>
            <div style={{marginBottom: 25}}><SText size={16} weight={500} color={Colors.green}
                                                   lineHeight={16}>{'Ecomarket.ru - Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹, ÐºÐ°ÐºÐ¸Ð¼Ð¸ Ð¾Ð½Ð¸ Ð´Ð¾Ð»Ð¶Ð½Ñ‹ Ð±Ñ‹Ñ‚ÑŒ!'}</SText>
            </div>
            <div><SText size={16} weight={500} lineHeight={20}>{'ÐšÑ‚Ð¾ Ð½Ð°ÑˆÐ¸ Ð¿Ð¾ÑÑ‚Ð°Ð²Ñ‰Ð¸ÐºÐ¸?'}</SText></div>
            <div style={{marginBottom: 25}}>
                <SText>{'ÐÐ°ÑˆÐ¸ Ð¿Ð¾ÑÑ‚Ð°Ð²Ñ‰Ð¸ÐºÐ¸ ÑÑ‚Ð¾ Ñ„ÐµÑ€Ð¼ÐµÑ€Ñ‹ Ð¸ Ð½ÐµÐ±Ð¾Ð»ÑŒÑˆÐ¸Ðµ Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´ÑÑ‚Ð²Ð°, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ð²Ñ‹Ñ€Ð°Ñ‰Ð¸Ð²Ð°ÑŽÑ‚ Ð¸ Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´ÑÑ‚ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹. ÐœÐ½Ð¾Ð³Ð¸Ðµ Ð¸Ð¼ÐµÑŽÑ‚ Ð±Ð¸Ð¾ ÑÐµÑ€Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ‚Ñ‹.\n'}</SText>
            </div>
            <div><SText size={16} weight={400} lineHeight={20}>{'ÐœÑ‹ Ñ€Ð°Ð·Ð±Ð¸Ñ€Ð°ÐµÐ¼ÑÑ Ð² Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð°Ñ…\n'}</SText></div>
            <div>
                <SText>{'Ð£ Ð½Ð°Ñ 3 ÑƒÑ€Ð¾Ð²Ð½Ñ ÐºÐ¾Ð½Ñ‚Ñ€Ð¾Ð»Ñ ÐºÐ°Ñ‡ÐµÑÑ‚Ð²Ð° Ð¸ Ð¼Ð¸Ð½Ð¸ Ð»Ð°Ð±Ð¾Ñ€Ð°Ñ‚Ð¾Ñ€Ð¸Ñ Ð¿Ð¾ Ð¿Ñ€Ð¾Ð²ÐµÑ€ÐºÐµ ÑÐ¾Ð´ÐµÑ€Ð¶Ð°Ð½Ð¸Ñ Ð²Ñ€ÐµÐ´Ð½Ñ‹Ñ… Ð½Ð¸Ñ‚Ñ€Ð°Ñ‚Ð¾Ð² Ð¸ Ð´Ð¾Ð±Ð°Ð²Ð¾Ðº Ð¿Ñ€Ð¸Ð½Ð¸Ð¼Ð°ÐµÐ¼Ð¾Ð¹ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸.'}</SText>
            </div>
            <div style={{marginBottom: 25}}>
                <SText>{'ÐÐ°ÑˆÐ¸ ÐºÐ¾Ð¼Ð¿Ð»ÐµÐºÑ‚Ð¾Ð²Ñ‰Ð¸ÐºÐ¸ Ð°ÐºÐºÑƒÑ€Ð°Ñ‚Ð½Ð¾ ÑÐ¾Ð±Ð¸Ñ€Ð°ÑŽÑ‚ Ð·Ð°ÐºÐ°Ð·, Ð²Ñ€ÑƒÑ‡Ð½ÑƒÑŽ Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ð»ÑƒÑ‡ÑˆÐµÐµ Ð´Ð»Ñ Ð²Ð°Ñ.'}</SText></div>
            <div><SText size={16} weight={500} lineHeight={20}>{'Ð¡Ñ€Ð¾ÐºÐ¸ Ð³Ð¾Ð´Ð½Ð¾ÑÑ‚Ð¸'}</SText></div>
            <div style={{marginBottom: 25}}>
                <SText>{'Ð’ ÑÑƒÐ¿ÐµÑ€Ð¼Ð°Ñ€ÐºÐµÑ‚Ð°Ñ… Ð½ÐµÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ð¿Ð¾ÐºÑƒÐ¿Ð°Ñ‚ÐµÐ»Ð¸ Ñ‡Ð°ÑÑ‚Ð¾ Ð¿Ð¾ÐºÑƒÐ¿Ð°ÑŽÑ‚ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ñ Ð¸ÑÑ‚Ñ‘ÐºÑˆÐ¸Ð¼ ÑÑ€Ð¾ÐºÐ¾Ð¼ Ð³Ð¾Ð´Ð½Ð¾ÑÑ‚Ð¸. ÐœÑ‹ Ð½Ðµ Ð´Ð¾Ð¿ÑƒÑÐºÐ°ÐµÐ¼ Ñ‚Ð°ÐºÐ¾Ð³Ð¾ Ð¸ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÑÐµÐ¼ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ñƒ Ñ‚Ð¾Ð»ÑŒÐºÐ¾ ÑÐ²ÐµÐ¶ÐµÐµ. Ð£ Ð½Ð°Ñ Ð´Ð²Ð° Ñ€Ð°Ð·Ð° Ð² Ð´ÐµÐ½ÑŒ Ð¿Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¼Ð¾Ð»Ð¾Ñ‡Ð½Ð¾Ð¹ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸, Ñ„Ñ€ÑƒÐºÑ‚Ð¾Ð², Ð¾Ð²Ð¾Ñ‰ÐµÐ¹, Ð·ÐµÐ»ÐµÐ½Ð¸, Ð¼ÑÑÐ° Ð¸ Ñ€Ñ‹Ð±Ñ‹. ÐšÐ°Ñ‚ÐµÐ³Ð¾Ñ€Ð¸Ñ‡ÐµÑÐºÐ¸ Ð·Ð°Ð¿Ñ€ÐµÑ‰Ð°ÐµÑ‚ÑÑ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²ÐºÐ° Ð½Ð° Ð¿Ð¾ÑÐ»ÐµÐ´Ð½ÐµÐ¼ Ð´Ð½Ðµ ÑÑ€Ð¾ÐºÐ° Ð³Ð¾Ð´Ð½Ð¾ÑÑ‚Ð¸.'}</SText>
            </div>
            <div><SText size={16} weight={500} lineHeight={20}>{'Ð¢ÐµÐ¼Ð¿ÐµÑ€Ð°Ñ‚ÑƒÑ€Ð½Ñ‹Ð¹ Ñ€ÐµÐ¶Ð¸Ð¼'}</SText></div>
            <div>
                <SText>{'Ð—Ð°Ð¼Ð¾Ñ€Ð¾Ð¶ÐµÐ½Ð½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ , ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ñ‚Ñ€ÐµÐ±ÑƒÑŽÑ‚ Ñ‚ÐµÐ¼Ð¿ÐµÑ€Ð°Ñ‚ÑƒÑ€Ð½Ð¾Ð³Ð¾ Ñ€ÐµÐ¶Ð¸Ð¼Ð° Ð¿Ñ€Ð¸ Ñ‚Ñ€Ð°Ð½ÑÐ¿Ð¾Ñ€Ñ‚Ð¸Ñ€Ð¾Ð²ÐºÐµ, ÑƒÐ¿Ð°ÐºÐ¾Ð²Ñ‹Ð²Ð°ÑŽÑ‚ÑÑ Ð² ÑÐ¿ÐµÑ†Ð¸Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ñ‚ÐµÑ€Ð¼Ð¾ÑÑƒÐ¼ÐºÐ¸ Ñ Ñ…Ð»Ð°Ð´Ð¾ÑÐ»ÐµÐ¼ÐµÐ½Ñ‚Ð°Ð¼Ð¸. Ð¢Ð°Ðº Ð¼Ñ‹ Ð´Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÐ¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ Ð² Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾Ð¼ Ñ‚ÐµÐ¼Ð¿ÐµÑ€Ð°Ñ‚ÑƒÑ€Ð½Ð¾Ð¼ Ñ€ÐµÐ¶Ð¸Ð¼Ðµ.'}</SText>
            </div>
        </div>
    </div>
}

export default About