import React, { useEffect, useState } from 'react'
import BottomModal from "../../components/BottomModal";
import { useSelector } from "react-redux";
import sendRequest from "../../../api";
import { getPrettyNumber, getStatusLabelProps } from "../../../screens/UserAccount/OrdersHistory";
import { StatusBlock } from "../../../screens/UserAccount/AccountNavigationModal";
import SText from "../../../components/SText";
import { declOfNum } from "../../../components/utils/other";
import OrderControls from "./components/OrderControls";
import Details from "./components/Details";
import MapHelper from "../../../screens/Map/MapHelper";
import { Code } from "../../../screens/UserAccount/Bonuses";
import codeStyles from '../../../screens/UserAccount/Bonuses.module.scss'

const OrderDetails = ({ onClose, id }) => {
    const [orderData, setOrderData] = useState(false)
    const user = useSelector(state => state.user)
    const statusCode = getStatusLabelProps(orderData.newStatus).code

    const showControls = statusCode < 3 && statusCode > -1

    const [currentScene, setCurrentScene] = useState(showControls ? 'banners' : 'details')

    const getOrderData = async (id) => {
        const payload_ = {
            id: id,
            apikey: user?.apikey || null,
            security: window.security || null,
        }
        const response_ = await sendRequest('getMissedProductsOrder', payload_)

        const canAdd = response_?.isOpenToAdd || false

        const payload = {
            apikey: user?.apikey || null,
            security: window.security || null,
            id: id
        }

        const response = await sendRequest('getOrderData', payload)

        if (response?.order) {
            setOrderData({ ...response.order, ID: id, canAdd })
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            await getOrderData(id)
        }

        fetchData()

        if (window.security)
            window.security = null

        if (window.orderId)
            window.orderId = null
    }, [window.orderId])

    if (!orderData) return null


    const remain = orderData?.COURRIER?.arrival_time ? Math.ceil(Number(orderData?.COURRIER?.arrival_time) / 60) + 5 : false

    const mainText = statusCode < 2 ?
        'ÐœÑ‹ Ð¾Ñ‚Ð±Ð¸Ñ€Ð°ÐµÐ¼ Ð´Ð»Ñ Ð²Ð°Ñ\nÑÐ°Ð¼Ñ‹Ðµ Ð»ÑƒÑ‡ÑˆÐ¸Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹' :
        (remain ? `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð¿Ñ€Ð¸Ð¼ÐµÑ€Ð½Ð¾ ${remain} ${declOfNum(remain, ['Ð¼Ð¸Ð½ÑƒÑ‚Ð°', 'Ð¼Ð¸Ð½ÑƒÑ‚Ñ‹', 'Ð¼Ð¸Ð½ÑƒÑ‚'])}\n` : 'Ð¡ÐºÐ¾Ñ€Ð¾ Ð±ÑƒÐ´ÐµÐ¼ Ñƒ Ð²Ð°Ñ\n')

    const isFinished = orderData.newStatus === 'cancelled' || orderData.newStatus === 'delivered'

    return <BottomModal onClose={ onClose } title={ getPrettyNumber(orderData.NUMBER) } style={ { zIndex: 100 } }>
        { !isFinished && <MapHelper
            setAdressAndGps={ () => {
            } }
            resetGps={ () => {
            } }
            setDisabled={ () => {
            } }
            ref={ () => {
            } }
            inputAddress={ '' }
            setInputAddres={ () => {
            } }
            setSuggestions={ () => {
            } }
            mapLoaded={ () => {
            } }
            showButtons={ 'true' }
            GPS={ orderData.COURRIER?.lat ? [orderData.COURRIER.lat, orderData.COURRIER.lon] : false }
            order={ orderData }
        /> }
        { orderData.newStatus === 'cancelled' ?
            <SText div weight={ 500 } color={ '#626262' } style={ { margin: 16 } }>{ 'Ð—Ð°ÐºÐ°Ð· Ð¾Ñ‚Ð¼ÐµÐ½ÐµÐ½' }</SText> :
            <div style={ { maxWidth: 260, marginTop: 24, margin: '12px auto' } }>
                <StatusBlock currentStatus={ getStatusLabelProps(orderData.newStatus).code } />
            </div>
        }

        { showControls && <>
            <SText div weight={ 900 } size={ 18 } style={ { textAlign: 'center', marginVertical: 12 } }
                numberOfLines={ 2 }>{ mainText }</SText>
            <OrderControls orderStatus={ orderData.newStatus }
                orderId={ id }
                currentScene={ currentScene }
                canChat={ !!orderData.COURRIER?.phone }
                order={ orderData }
                canAdd={ orderData.canAdd }
                onSetScene={ setCurrentScene }
            />
        </> }
        { currentScene === 'details' && <Details orderId={ id } order={ orderData } onPay={ () => { getOrderData(id) } } onClose={ onClose } /> }
        { user && user.personalPromo &&
            <div className={ codeStyles.codeHolder_order }>
                <Code promo={ user.personalPromo } />
            </div>
        }
    </BottomModal>
}

export default OrderDetails
