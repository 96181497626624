import React, {useContext, useEffect, useState} from 'react'
import {useLastOrder} from "./hooks";
import OrderProductXChangeContext from "./OrderProductXChange";
import {useSearchParams} from "react-router-dom";

const InitialRouteProcessor = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const lastOrder = useLastOrder()
    const {missedProducts} = useContext(OrderProductXChangeContext)
    const [fired, setFired] = useState(false)

    useEffect(() => {
        if (missedProducts.length > 0) {
            setSearchParams('orderTradeOff')
            setFired(true)
            return
        }
        if (fired) return

/*        if (lastOrder?.ID) {
            setSearchParams('orderDetails=' + lastOrder.ID)
            setFired(true)
        }*/

    }, [lastOrder?.ID, missedProducts.length])

    return null
}

export default InitialRouteProcessor
