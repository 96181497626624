import React, {useEffect, useState, useMemo} from 'react'
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import NotFound from "./screens/NotFound/NotFound";
import sendRequest from "./api";
import PagePreloader from "./components/PagePreloader";
import Page from "./screens/Page/Page"

const CompanyUrlProcessor = ({mobile = false}) => {
    const {urlMap, productUrlMap} = useSelector(state => state.catalogue)
    const user = useSelector(state => state.user)
    const {url} = useParams()

    const [content, setContent] = useState(<PagePreloader />)

    async function getContent() {

        const validCompanyPage = await sendRequest('getPageData', {url})
        if (validCompanyPage && validCompanyPage !== 'NO DATA') {
            return <Page page={validCompanyPage}/>
        }

        return <NotFound/>

    }

    useMemo(() => {
        setContent(<PagePreloader />)
    }, [url])
    
    useEffect(() => {
        getContent().then(setContent)
    }, [url, urlMap, productUrlMap])

    return content
}

export default CompanyUrlProcessor
