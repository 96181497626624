import styles from "./styles.module.css";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import {CircularProgressbar} from "react-circular-progressbar";
import React from "react";


const Circle = ({percentage, value, units, title, bigger=false}) => {

    return <div>
            <div className={bigger ? styles.bigCircle : styles.smallCircle}>
                <CircularProgressbar value={percentage}/>
                <div className={styles.textInside}>
                    <SText size={bigger ? 20 : 14}  weight={bigger ? 500 : 400} color={'#bdbdbd'}>{percentage}%</SText>
                    {bigger && <div>{`Ð¡ÑƒÑ‚Ð¾Ñ‡Ð½Ð¾Ð¹`} <br/> {'Ð½Ð¾Ñ€Ð¼Ñ‹'}</div>}
                </div>
            </div>
            <div style={{marginTop: 7}}>
                <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={500}
                                                          color={Colors.darkGray}
                                                          fontType={1}>{title}</SText></div>
                <div style={{textAlign: 'center'}}><SText size={12} lineHeight={15} weight={400}
                                                          color={Colors.darkGray}>{value + ' ' + units}</SText></div>
            </div>
        </div>
}


const NutritionalValue = ({calories, bel, ugl, fats, klet}) => {

    if (!bel || !ugl || !fats) return null

    let correctCal = Math.ceil(parseFloat(calories) / 2200 * 100) || 1
    let correctBel = Math.ceil(parseFloat(bel) / 80 * 100) || 1
    let correctFats = Math.ceil(parseFloat(fats) / 60 * 100) || 1
    let correctUgl = Math.ceil(parseFloat(ugl) / 80 * 100) || 1
    let correctKlet = Math.ceil(klet / 28 * 100) || 1

    return <div className={styles.nutritionalValueCard}>
        <div><SText size={16} weight={700} lineHeight={20}
                    color={Colors.darkGray}>{'Ð­Ð½ÐµÑ€Ð³ÐµÑ‚Ð¸Ñ‡ÐµÑÐºÐ°Ñ Ð¸ Ð¿Ð¸Ñ‰ÐµÐ²Ð°Ñ Ñ†ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒ'}</SText></div>
        <div style={{marginBottom: 21}}><SText size={12} lineHeight={15} weight={400}
                                               color={'#bdbdbd'}>{'Ð½Ð° 100 Ð³ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð°'}</SText></div>
        <div className={styles.circles}>

            <Circle
                bigger
                title={'ÐšÐ°Ð»Ð¾Ñ€Ð¸Ð¹Ð½Ð¾ÑÑ‚ÑŒ'}
                value={calories}
                percentage={correctCal}
                units={''}
            />
            <div style={{flex: 1}}>
                <div style={{display: 'flex', marginBottom: 20, justifyContent: 'space-evenly'}}>
                    <Circle
                        title={'Ð‘ÐµÐ»ÐºÐ¸'}
                        value={bel}
                        percentage={correctBel}
                        units={'Ð³'}
                    />
                    <Circle
                        title={'Ð–Ð¸Ñ€Ñ‹'}
                        value={fats}
                        percentage={correctFats}
                        units={'Ð³'}
                    />
                </div>
                <div style={{display: 'flex', marginBottom: 20, justifyContent: 'space-evenly'}}>
                    <Circle
                        title={'Ð£Ð³Ð»ÐµÐ²Ð¾Ð´Ñ‹'}
                        value={ugl}
                        percentage={correctUgl}
                        units={'Ð³'}
                    />
                    <Circle
                        title={'ÐšÐ»ÐµÑ‚Ñ‡Ð°Ñ‚ÐºÐ°'}
                        value={klet}
                        percentage={correctKlet}
                        units={'Ð³'}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default NutritionalValue
