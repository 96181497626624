import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sendRequest from "../../api";

const initialState = {
    loaded: false,
    data: {}
}

export const fetchConfig = createAsyncThunk(
    'config/fetch',
    async () => {
        return await sendRequest('getSlider_v2', {source: "old_web"})
    }
)

const configSlice = createSlice({
    name: 'config',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchConfig.fulfilled, (state,action)=>{
            state.loaded = true
            state.data = action.payload || {}
        })
    }
})

export default configSlice.reducer