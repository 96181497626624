import React, {useContext, useEffect, useMemo, useState} from 'react'
import styles from './styles.module.css'
import HelperContext from "../../../../redux/HelperContext";
import OrderProductXChange from "../../../../components/utils/OrderProductXChange";
import {useDispatch, useSelector} from "react-redux";
import {getTimeBeforeAssembly, useLastOrder, useWindowDimensions} from "../../../../components/utils/hooks";
import moment from "moment";
import {refreshOrders} from "../../../../redux/reducers/user";
import Colors from "../../../../components/utils/Colors";
import ReplaceIcon from '../../../../assets/images/header/cartButton/cart-plus.svg'
import TruckIcon from '../../../../assets/images/header/cartButton/truck.svg'
import CartIcon from '../../../../assets/images/header/cartButton/cart.svg'
import ChevronUp from '../../../../assets/images/chevron-up.svg'
import CatalogueIcon from '../../../../assets/images/catalogue.svg'
import CheckIcon from '../../../../assets/images/header/cartButton/accepted.svg'
import DeliveryIcon from '../../../../assets/images/header/cartButton/on-the-way.svg'
import BoxIcon from '../../../../assets/images/header/cartButton/ready-to-go.svg'
import {declOfNum, prettyDate, roundPrice} from "../../../../components/utils/other";
import cn from "classnames";
import {colorToFilter} from "../../../../components/utils/ColorFilter";
import SText from "../../../../components/SText";
import {useSearchParams} from "react-router-dom";
import {useQueryParams} from "../../../../components/utils/ModalSpectator";

const BottomBar = ({isOnTop = true}) => {

    const width = useWindowDimensions()
    const [searchParams, setSearchParams] = useSearchParams()
    const {push} = useQueryParams()

    const {totals, delivery} = useContext(HelperContext)
    const {mode, remains: xChangeRemains} = useContext(OrderProductXChange)
    const dispatch = useDispatch()

    const cartSum = totals.cart.actual

    const finalPrice = totals.finalTotals.actual

    const cart = useSelector(state => state.cart)
    const user = useSelector(state => state.user)
    const slots = useSelector(state => state.deliverySlots)

    const lastOrder = useLastOrder()

    const [time, setTime] = useState(moment().format('mm'))
    const [timer, setTimer] = useState(0)

    useEffect(() => { //
        if (timer) clearInterval(timer)
        if (!lastOrder) return

        setTimer(
            setInterval(() => {
                setTime((moment().format('mm')))
                dispatch(refreshOrders())
            }, 30000)
        )
    }, [lastOrder?.ID])

    const getFirstLineText = () => {
        const [FREE_DEL_FROM, nextStepPrice] = delivery.options.find(([target, price]) => Number(target) > totals.cart.actual) || [0, 0]

        /*        let auxText = ''*/
        let diff = FREE_DEL_FROM - cartSum
        /*        if (diff > 0)
                    auxText = nextStepPrice === 0 ?
                        `Ð”Ð¾ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾Ð¹ ${roundPrice(diff)} â‚½` :
                        `${roundPrice(diff)} â‚½ Ð´Ð¾ ÑÐºÐ¸Ð´ÐºÐ¸ Ð½Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ`*/
        return nextStepPrice === 0 && diff / FREE_DEL_FROM <= 0.2 ?
            `Ð”Ð¾ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾Ð¹ ${roundPrice(diff)} â‚½` : ''
    }

    function getMiddleButtonProps() {

        const DEL_PRICE = totals.deliveryPrice
        const MINIMAL_ORDER = delivery.minimalOrder
        const HAS_ADDRESS = !!user.address?.name
        const [FREE_DEL_FROM, nextStepPrice] = delivery.options.find(([target]) => Number(target) > totals.cart.actual) || [0, 0]

        const remains = lastOrder?.arrival_time ? Math.ceil(Number(lastOrder?.arrival_time) / 60) + 5 : false

        if (mode === 'replace') {
            const hasCart = cart.order.length !== 0
            return {
                contentColor: '#fff',
                backgroundColor: hasCart ? Colors.green : Colors.red,
                mainText: hasCart ? `Ð—Ð°Ð¼ÐµÐ½Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð² ${cart.order.length ? `(+${cart.order.length})` : ''}` : 'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð·Ð°Ð¼ÐµÐ½Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²',
                auxText: `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸ ${xChangeRemains.minutes()}:${String(xChangeRemains.seconds()).padStart(2, '0')}`,
                Icon: hasCart ? ReplaceIcon : false,
                iconSize: 32,
                onPress: () => {
                    setSearchParams('orderTradeOff')
                }
            }
        }
        if (mode === 'add') {
            return {
                contentColor: '#fff',
                backgroundColor: Colors.green,
                mainText: cart.order.length ? 'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð² +' + cart.order.length : 'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²',
                auxText: `ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸ ${xChangeRemains.minutes()}:${String(xChangeRemains.seconds()).padStart(2, '0')}`,
                Icon: ReplaceIcon,
                iconSize: 32,
                onPress: () => {
                    setSearchParams('addProducts')
                }
            }
        }

        if (!lastOrder && cartSum === 0) {
            /*if (!HAS_ADDRESS) return false*/
            return {
                contentColor: Colors.darkGray,
                backgroundColor: '#fff',
                mainTextStyle: {
                    size: 14,
                    weight: 500
                },
                auxTextStyle: {
                    size: 14,
                    weight: 500
                },
                Icon: slots.loading ? TruckIcon : null,
                mainText: slots.loading ? 'Ð Ð°ÑÑÑ‡Ð¸Ñ‚Ñ‹Ð²Ð°ÐµÐ¼ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ' :
                    <span style={{display: 'flex', alignItems: 'center', gap: 3}}>{`ÐœÐ¸Ð½. ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° ${MINIMAL_ORDER} â‚½`}</span>,
                auxText: slots.loading ? 'Ð¸ ÑƒÑÐ»Ð¾Ð²Ð¸Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸...' : /*`Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ Ð·Ð° ${DEL_PRICE} â‚½`*/ '',
                onPress: () => setSearchParams('deliveryInfo')
            }
        }


        if (cartSum > 0) {
            let auxText = ''
            if (cartSum >= MINIMAL_ORDER) {
                if (totals.deliveryPrice === 0) {
                    auxText = 'Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾'
                } else {
                    auxText = ''
                    /*let diff = FREE_DEL_FROM - cartSum
                    if ((true || diff <= 1000) && diff > 0)
                        auxText = nextStepPrice === 0 ?
                            `${roundPrice(diff)} â‚½ Ð´Ð¾ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½Ð¾Ð¹ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸` :
                            `${roundPrice(diff)} â‚½ Ð´Ð¾ ÑÐºÐ¸Ð´ÐºÐ¸ Ð½Ð° Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ`
                    else
                        auxText = `Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ Ð·Ð° ${totals.deliveryPrice} â‚½`*/
                }
            } else {
                auxText = <div style={{
                    width: 'fit-content',
                    textAlign: 'center',
                }}>{`ÐœÐ¸Ð½. ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° ${MINIMAL_ORDER} â‚½`}</div>
            }

            let contentColor = cartSum > MINIMAL_ORDER ? '#fff' : Colors.darkGray

            return {
                contentColor: contentColor,
                backgroundColor: cartSum > MINIMAL_ORDER ? Colors.green : Colors.yellow,
                Icon: CartIcon,
                mainText: <div style={{textAlign: 'center'}}>{roundPrice(cartSum) + ' â‚½'}</div>,
                auxText: auxText,
                onPress: () => {
                    /* if (user?.apikey)*/
                    setSearchParams('cart')
                    /*else push(['auth', ''])*/
                },
                topTextComponent: () => (
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 4, gap: 5}}>
                        <SText
                            color={contentColor}
                            weight={700}
                            size={width > 350 ? 16 : 14}
                            div
                        >
                            <div style={{
                                textAlign: 'center'
                            }}>{roundPrice(cartSum) + ' â‚½'}</div>
                        </SText>

                        {/*                        {totals.deliveryPrice > 0 &&
                            <SText
                                color={contentColor}
                                weight={400}
                                size={12}
                                div
                            >
                                {' + Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ° ' + roundPrice(totals.deliveryPrice) + ' â‚½'}
                            </SText>
                        }*/}
                    </div>
                ),
            };
        }

        if (lastOrder) {
            const minutes = getTimeBeforeAssembly(lastOrder)

            let props = {
                contentColor: '#fff',
                backgroundColor: Colors.green,
                Icon: lastOrder.newStatus === 'on_the_way' ? DeliveryIcon : BoxIcon,
                mainText: lastOrder.newStatus === 'on_the_way' ? 'ÐšÑƒÑ€ÑŒÐµÑ€ ÑƒÐ¶Ðµ Ð² Ð¿ÑƒÑ‚Ð¸!' : 'Ð¡Ð¾Ð±Ð¸Ñ€Ð°ÐµÐ¼ Ð²Ð°Ñˆ Ð·Ð°ÐºÐ°Ð·',
                auxText: remains ?
                    `${declOfNum(remains, ['ÐžÑÑ‚Ð°Ð»Ð°ÑÑŒ', 'ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ', 'ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ'])} ~${remains} ${declOfNum(remains, ['Ð¼Ð¸Ð½ÑƒÑ‚Ð°', 'Ð¼Ð¸Ð½ÑƒÑ‚Ñ‹', 'Ð¼Ð¸Ð½ÑƒÑ‚'])}` :
                    (
                        minutes ?
                            `${declOfNum(remains, ['ÐžÑÑ‚Ð°Ð»Ð°ÑÑŒ', 'ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ', 'ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ'])} ~${minutes} ${declOfNum(minutes, ['Ð¼Ð¸Ð½ÑƒÑ‚Ð°', 'Ð¼Ð¸Ð½ÑƒÑ‚Ñ‹', 'Ð¼Ð¸Ð½ÑƒÑ‚'])}` :
                            lastOrder.newStatus === 'on_the_way' ? 'Ð¡ÐºÐ¾Ñ€Ð¾ Ð±ÑƒÐ´ÐµÑ‚ Ñƒ Ð²Ð°Ñ' : 'Ð¡ÐºÐ¾Ñ€Ð¾ Ð¿ÐµÑ€ÐµÐ´Ð°Ð´Ð¸Ð¼ Ð² Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÑƒ'
                    ),
                onPress: () => setSearchParams('orderDetails=' + lastOrder.ID)
            }

            if (minutes > 120) {
                props = {
                    ...props,
                    Icon: CheckIcon,
                    mainText: 'Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð¿Ñ€Ð¸Ð½ÑÑ‚',
                    auxText: `Ð”Ð¾ÑÑ‚Ð°Ð²Ð¸Ð¼ ${prettyDate(moment(lastOrder.DATE, 'YYYY-MM-DD')).toLowerCase()} ${lastOrder.TIME}`,
                    iconSize: 32
                }
            }

            return props
        }
    }

    const middleButtonProps = useMemo(() => {
        return getMiddleButtonProps()
    }, [cartSum, lastOrder, delivery, time, slots.loading, totals])

    const Icon = middleButtonProps.Icon

    function onScrollTopPress() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return <div className={styles.newContainer}>

        <div style={(!Object.keys(cart.items).length || totals.deliveryPrice === 0) ? {
            background: 'initial',
            boxShadow: 'none'
        } : {background: '#fff', boxShadow: '0 -2px 12px rgba(0, 0, 0, 0.15)'}} className={styles.whiteSpace}>
            {(totals.deliveryPrice === 0 || (lastOrder && !Object.keys(cart.items).length) || (mode === 'replace' || mode === 'add') || !Object.keys(cart.items).length) ? null :
                <div onClick={() => setSearchParams('deliveryInfo')} className={styles.firstLineInfo}>
                    <><img src={TruckIcon}/>

                        <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
                            <SText size={12} weight={500}>{`Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° ${totals.deliveryPrice} â‚½`}</SText>
                            <SText size={12} weight={500}>{`${getFirstLineText()}`}</SText>
                        </div>

                        <img style={{transform: 'rotate(90deg)', filter: colorToFilter('#c5c5c5')}} src={ChevronUp}/></>
                </div>}
            <div className={styles.secondLineBtn}>
                <div className={cn(styles.sideButton, styles.scrollUpButton)}
                     onClick={onScrollTopPress}
                     style={{opacity: isOnTop ? 0 : 1}}>
                    <img src={ChevronUp} className={styles.iconSmall}/>
                </div>
                {!Boolean(middleButtonProps) ?
                    <div/> :
                    <div onClick={middleButtonProps.onPress} className={styles.middleButtonNew}
                         style={{backgroundColor: middleButtonProps.backgroundColor}}>
                        {Icon ?
                            <div className={styles.iconWrapperNew}>
                                <img src={Icon} className={styles.icon}
                                     style={{filter: colorToFilter(middleButtonProps.contentColor)}}/>
                            </div> :
                            <div style={{width: 24}}/>
                        }
                        <div className={styles.textContentHolder}>
                            {middleButtonProps.topTextComponent ? middleButtonProps.topTextComponent() : <SText
                                size={(middleButtonProps.mainTextStyle?.size - (width <= 420 ? 2 : 0) || 16 - (width <= 420 ? 2 : 0))}
                                weight={middleButtonProps.mainTextStyle?.weight || 700}
                                color={middleButtonProps.contentColor}
                                style={{marginBottom: 4}}
                                lineHeight={(middleButtonProps.mainTextStyle?.size - (width <= 420 ? 2 : 0) || 16 - (width <= 420 ? 2 : 0))}
                            >
                                {middleButtonProps.mainText}
                            </SText>}
                            <SText
                                size={(middleButtonProps.auxTextStyle?.size - (width <= 420 ? 2 : 0) || 12 - (width <= 420 ? 2 : 0))}
                                weight={middleButtonProps.auxTextStyle?.weight || 400}
                                color={middleButtonProps.contentColor}
                                lineHeight={(middleButtonProps.auxTextStyle?.size - (width <= 420 ? 2 : 0) || 12 - (width <= 420 ? 2 : 0))}
                                style={{width: 'fit-content', display: 'inline-block'}}
                            >
                                {middleButtonProps.auxText}
                            </SText>
                        </div>
                    </div>}
                <div className={styles.sideButton} onClick={() => { setSearchParams('search'); delete window.initWithSearch;}}>
                    <img src={CatalogueIcon} style={{width: '100%', height: '100%'}}/>
                </div>
            </div>
        </div>
    </div>

    return <div className={styles.container}>
        <div className={cn(styles.sideButton, styles.scrollUpButton)}
             onClick={onScrollTopPress}
             style={{opacity: isOnTop ? 0 : 1}}>
            <img src={ChevronUp} className={styles.iconSmall}/>
        </div>
        {!Boolean(middleButtonProps) ?
            <div/> :
            <div onClick={middleButtonProps.onPress} className={styles.middleButton}
                 style={{backgroundColor: middleButtonProps.backgroundColor}}>
                {Icon ?
                    <div className={styles.iconWrapper}>
                        <img src={Icon} className={styles.icon}
                             style={{filter: colorToFilter(middleButtonProps.contentColor)}}/>
                    </div> :
                    <div style={{width: 24}}/>
                }
                <div className={styles.textContentHolder}>
                    {middleButtonProps.topTextComponent ? middleButtonProps.topTextComponent() : <SText
                        size={(middleButtonProps.mainTextStyle?.size - (width <= 420 ? 2 : 0) || 16 - (width <= 420 ? 2 : 0))}
                        weight={middleButtonProps.mainTextStyle?.weight || 700}
                        color={middleButtonProps.contentColor}
                        style={{marginBottom: 4}}
                    >
                        {middleButtonProps.mainText}
                    </SText>}
                    <SText
                        size={(middleButtonProps.auxTextStyle?.size - (width <= 420 ? 2 : 0) || 12 - (width <= 420 ? 2 : 0))}
                        weight={middleButtonProps.auxTextStyle?.weight || 400}
                        color={middleButtonProps.contentColor}
                    >
                        {middleButtonProps.auxText}
                    </SText>
                </div>
            </div>}
        <div className={styles.sideButton} onClick={() => setSearchParams('search')}>
            <img src={CatalogueIcon} style={{width: '100%', height: '100%'}}/>
        </div>
    </div>
}
export default BottomBar