import React, {useEffect, useState} from "react";
import styles from '../styles.module.scss'
import {NavLink} from "react-router-dom";
import {colorToFilter} from "../../../../components/utils/ColorFilter";
import back from "../../../../assets/images/cart/goBack.svg";
import SText from "../../../../components/SText";
import Colors from "../../../../components/utils/Colors";
import {useDispatch, useSelector} from "react-redux";
import warning from '../../../../assets/images/warning.svg'
import {HistoryItem} from "../../../../screens/UserAccount/OrdersHistory";
import {fetchOrders} from "../../../../redux/reducers/user";

const OrdersHistory = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const orders = useSelector(state => state.user.orders)
    const [loadChunk, setLoadChunk] = useState(0)
    const [chatId, setChatId] = useState(null)

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return () => document.removeEventListener('scroll', scrollHandler)
    }, [])

    const scrollHandler = async (e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) === 0) {
            setLoadChunk(prev => prev + 1)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (loadChunk !== 0)
                await loadNextChunk()
        }
        fetchData()
    }, [loadChunk])

    async function loadNextChunk() {
        dispatch(fetchOrders())
    }

    return <div className={styles.container}>
        <div className={styles.header}>
            <NavLink to={'/'}>
                <div className={styles.goBack}><img style={{filter: colorToFilter('#383838')}} src={back}/></div>
            </NavLink>
            <div><SText size={16} lineHeight={20} weight={500} color={Colors.darkGray}>{'Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð·Ð°ÐºÐ°Ð·Ð¾Ð²'}</SText></div>
        </div>

        {user.orders.unpaid > 0 &&
            <div style={{marginBottom: 20}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 12, gap: 10}}><img src={warning}/>
                    <SText
                        size={14} weight={700} lineHeight={18}
                        color={Colors.red}>{'Ð£ Ð²Ð°Ñ ' + user.orders.unpaid + ' Ð½ÐµÐ¾Ð¿Ð»Ð°Ñ‡ÐµÐ½Ð½Ñ‹Ñ… Ð·Ð°ÐºÐ°Ð·Ð°'}</SText></div>
                <div className={styles.unpaidOrders}>{'ÐŸÐµÑ€ÐµÐ¹Ð´Ð¸Ñ‚Ðµ Ð² Ð´ÐµÑ‚Ð°Ð»Ð¸ Ð·Ð°ÐºÐ°Ð·Ð°'}
                    <br/> {'Ð¸ Ð½Ð°Ð¶Ð¼Ð¸Ñ‚Ðµ ÐºÐ½Ð¾Ð¿ÐºÑƒ â€œÐ¾Ð¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒâ€. Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾!'}</div>
            </div>}

            {
                orders.items.length ? (
                    <div>
                        {orders.items.map(order => <HistoryItem key={order.ID} order={order}/>)}
                    </div>
                ) : (
                    <div style={{display: 'flex', justifyContent: "center"}}>
                        <SText size={14} lineHeight={16} color={Colors.darkGray} weight={500}>Ð’Ñ‹ ÐµÑ‰Ðµ Ð½Ðµ Ð´ÐµÐ»Ð°Ð»Ð¸ Ð½Ð¸ Ð¾Ð´Ð½Ð¾Ð³Ð¾ Ð·Ð°ÐºÐ°Ð·Ð°, ÑÐºÐ¾Ñ€ÐµÐµ Ðº Ñ‚Ð¾Ð²Ð°Ñ€Ð°Ð¼.</SText>
                    </div>
                )
            }

    </div>
}

export default OrdersHistory