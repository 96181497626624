import React, {useState} from "react";
import styles from './Map.module.scss'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import cn from "classnames";
import clear from '../../assets/images/map/clearInput.svg'
import free from '../../assets/images/map/freeDelivery.svg'
import car from '../../assets/images/map/deliveryCar.svg'
import DeliveryPriceInfo from "../Cart/components/DeliveryPriceInfo";
import {useContext} from "react";
import HelperContext from "../../redux/HelperContext";
import {useSelector} from "react-redux";
import {Row} from "../Cart/components/DeliveryInfo";

const Address = ({
                     address,
                     setAddress,
                     suggestions,
                     mapHelper,
                     setSuggestions,
                     disabled,
                     setSuggestionsVisible,
                     suggestionsVisible,
                     onSubmitAddress
                 }) => {

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'calc(100% - 68px)'
    }}>

        <div>

            <div style={{position: 'relative'}}>
                {address && <div onClick={() => {
                    setSuggestions([])
                    setAddress('')
                }} className={styles.clearInput}>
                    <img src={clear}/>
                </div>}
                <div className={styles.input}>
                    <input onBlur={() => setTimeout(() => {
                        setSuggestionsVisible(false)
                    }, 200)} onFocus={() => setSuggestionsVisible(true)}
                           placeholder={'Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð°Ð´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'} value={address}
                           onChange={e => setAddress(e.target.value)} type="text"/>

                </div>
                {(suggestionsVisible && suggestions.length > 0) && <div className={styles.suggestions}>
                    {
                        suggestions.map(item => <div className={styles.suggestWrapper}>
                            <div onClick={() => {
                                mapHelper.setSuggestedAdress(item.TITLE)
                                setSuggestionsVisible(false)
                                setAddress(item.TITLE)
                            }} className={styles.suggest}><SText size={16}
                                                                 weight={400}
                                                                 lineHeight={16}
                                                                 color={Colors.darkGray}>{item.TITLE}</SText>
                            </div>
                        </div>)
                    }
                </div>}
            </div>

            {/*<div><SText size={16} weight={400} lineHeight={20} color={'#c6c6c6'}>{'Ð’Ð°ÑˆÐ¸ Ð°Ð´Ñ€ÐµÑÐ°:'}</SText></div>*/}

        </div>

        <button onClick={() => {
            onSubmitAddress()
        }} disabled={disabled} style={{opacity: disabled ? 0.5 : 1}} className={styles.btn}>
            <SText size={16} weight={700} lineHeight={16} color={'#fff'}>{'Ð’Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ ÑÑ‚Ð¾Ñ‚ Ð°Ð´Ñ€ÐµÑ'}</SText>
        </button>

    </div>
}


const Terms = () => {
    const {delivery} = useContext(HelperContext)
    const slots = useSelector(state => state.deliverySlots)
    const options = slots.data.options || []

    return <div>

        <DeliveryPriceInfo/>

        <div style={{marginBottom: 34}}>
            <SText size={12} lineHeight={15} weight={400}
                   color={'#9c9c9c'}>{`ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑƒÐ¼Ð¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° ${delivery.minimalOrder} Ñ€ÑƒÐ±`}</SText>
        </div>

        {options.length !== 0 && <>
            <div style={{marginBottom: 24}}>
                <SText size={16} weight={500} lineHeight={20} color={'#383838'}>{'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText>
            </div>

            <div style={{marginBottom: 33}}>

                {options.map(([cart, price]) => (
                    <Row cart={cart} key={cart} price={price} min={delivery.minimalOrder}/>
                ))}

            </div>
        </>}

        <hr className={styles.grayLine}/>

        <div style={{marginBottom: 40}}>
            <div style={{marginBottom: 14}}><SText size={16} weight={500} lineHeight={20}
                                                   color={'#383838'}>{'Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¾ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐµ'}</SText></div>
            <div className={styles.text}>
                ÐŸÑ€Ð¸Ð½Ð¸Ð¼Ð°ÐµÐ¼ Ð¸ Ð¾Ñ‚Ð³Ñ€ÑƒÐ¶Ð°ÐµÐ¼ Ð·Ð°ÐºÐ°Ð·Ñ‹ 24/7. <br/>
                Ð”Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐµÐ¼ Ð¾Ñ‚ 60 Ð¼Ð¸Ð½ÑƒÑ‚. <br/>
                ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ð°Ñ ÑÑƒÐ¼Ð° Ð·Ð°ÐºÐ°Ð·Ð° {delivery.minimalOrder}. <br/>
            </div>
        </div>
        <div style={{marginBottom: 40}}>
            <div style={{marginBottom: 14}}><SText size={16} weight={500} lineHeight={20}
                                                   color={'#383838'}>{'Ð¡Ð¾Ñ…Ñ€Ð°Ð½Ð½Ð¾ÑÑ‚ÑŒ ÑÐ²ÐµÐ¶ÐµÑÑ‚Ð¸ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð²\n'}</SText></div>
            <div className={styles.text}>
                Ð‘ÐµÑ€ÐµÐ¶Ð½Ð¾ Ð¿Ð°ÐºÑƒÐµÐ¼ Ð¸ ÑƒÐºÐ»Ð°Ð´Ñ‹Ð²Ð°ÐµÐ¼ Ð¸ Ð²ÐµÐ·Ñ‘Ð¼, ÑÐ¾Ð±Ð»ÑŽÐ´Ð°Ñ Ñ‚ÐµÐ¼Ð¿ÐµÑ€Ð°Ñ‚ÑƒÑ€Ð½Ñ‹Ð¹ Ñ€ÐµÐ¶Ð¸Ð¼ Ð¸ Ð¿Ñ€Ð¸Ð½Ñ†Ð¸Ð¿ ÑÐ¾ÑÐµÐ´ÑÑ‚Ð²Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð².
            </div>
        </div>
        {/*        <div style={{marginBottom: 15}}>
            <div style={{marginBottom: 14}}><SText size={16} weight={500} lineHeight={20}
                                                   color={'#383838'}>{'ÐŸÐ¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ðµ Ð·Ð°ÐºÐ°Ð·Ð°\n'}</SText></div>
            <div><SText size={14} weight={400} lineHeight={15}
                        color={'#383838'}>{'Ð•ÑÐ»Ð¸ Ð²Ñ‹ Ð½Ðµ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¿Ñ€Ð¸Ð½ÑÑ‚ÑŒ Ð·Ð°ÐºÐ°Ð· Ð² Ð¾Ð±Ð¾Ð·Ð½Ð°Ñ‡ÐµÐ½Ð½Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð¸ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð²Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ Ð´Ñ€ÑƒÐ³Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸, Ð»Ð¸Ð±Ð¾ Ñƒ Ð²Ð°Ñ ÐµÑÑ‚ÑŒ Ð´Ð¾Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¸Ñ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ, Ñ‚Ð¾ Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, ÑÐ²ÑÐ¶Ð¸Ñ‚ÐµÑÑŒ Ñ ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚-Ñ†ÐµÐ½Ñ‚Ñ€Ð¾Ð¼.Ð•ÑÐ»Ð¸ Ñƒ Ð²Ð°Ñ ÐµÑÑ‚ÑŒ Ð¿Ð¾Ð¶ÐµÐ»Ð°Ð½Ð¸Ñ Ð¿Ð¾ Ñ€Ð°Ð±Ð¾Ñ‚Ðµ ÑÐµÑ€Ð²Ð¸ÑÐ°, Ð¿Ð¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ñ‚Ð°ÐºÐ¶Ðµ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÐºÐ¾Ð½Ñ‚Ð°ÐºÑ‚-Ñ†ÐµÐ½Ñ‚Ñ€.'}</SText>
            </div>
        </div>*/}

    </div>
}

const MapLeftContent = ({address, setAddress, suggestions, mapHelper, setSuggestions, disabled, onSubmitAddress}) => {

    const [page, setPage] = useState(1)
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)


    return <div className={styles.leftContentWrapper}>

        <div className={styles.toggle}>
            <div onClick={() => setPage(1)}
                 className={cn(styles.nonActiveToggle, page === 1 ? styles.activeToggle : false)}><SText
                size={14}
                weight={page === 1 ? 700 : 400}
                lineHeight={20}
                color={page === 1 ? Colors.green : Colors.darkGray}>{'ÐÐ´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText>
            </div>
            <div onClick={() => setPage(2)}
                 className={cn(styles.nonActiveToggle, page === 2 ? styles.activeToggle : false)}><SText
                size={14}
                weight={page === 2 ? 700 : 400}
                lineHeight={20}
                color={page === 2 ? Colors.green : Colors.darkGray}>{'Ð£ÑÐ»Ð¾Ð²Ð¸Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸'}</SText>
            </div>
        </div>
        {page === 1 ? <Address onSubmitAddress={onSubmitAddress} suggestionsVisible={suggestionsVisible}
                               setSuggestionsVisible={setSuggestionsVisible}
                               address={address}
                               disabled={disabled} setAddress={setAddress} suggestions={suggestions}
                               setSuggestions={setSuggestions}
                               mapHelper={mapHelper}/> : <Terms/>}

    </div>
}

export default MapLeftContent