import React, { useState } from 'react';
import styles from './Contacts.module.scss'
import SText from "../../../components/SText";
import wa from '../../../assets/images/green_whatsapp.png'
import sendRequest from "../../../api";
import { NavLink } from "react-router-dom";
import { colorToFilter } from "../../../components/utils/ColorFilter";
import back from "../../../assets/images/cart/goBack.svg";
import Colors from "../../../components/utils/Colors";

const Contacts = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [mail, setMail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [success, setSuccess] = useState(false)

    const validateEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validate = (FIELD) => {
        let phoneNumber = phone?.toString().replace(/\D+/g, '');
        if ((name.length > 0) || (phoneNumber.length > 0) || (mail.length > 0) || (companyName.length > 0)) {
            switch (FIELD) {
                case 'NAME':
                    if (name.length < 2) {
                        return 'invalid';
                    }
                    break;
                case 'PHONE':
                    if (phoneNumber.length !== 11) {
                        return 'invalid';
                    }
                    break;
                case 'EMAIL':
                    if (!validateEmail(mail)) {
                        return 'invalid';
                    }
                    break;
                case 'COMPANY':
                    if (companyName.length < 4) {
                        return 'invalid';
                    }
                    break;
            }
        }
    }

    const sendRequests = async () => {
        let phoneNumber = phone.toString().replace(/\D+/g, '');
        if ((name.length > 1) && (phoneNumber.length === 11) && (validateEmail(mail)) && (companyName.length > 3)) {
            try {
                let SEND_DATA = {
                    token: false,
                    phone: phone,
                    email: mail,
                    company: companyName,
                    name: name,
                };

                let data = await sendRequest('recordB2Brequest', SEND_DATA);
                if (data !== false) {
                    /*                try {
                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push({
                                            'event': 'generate_lead',
                                            'form_name': 'B2B',
                                        });
                                    } catch {}*/

                    setMail('')
                    setPhone('')
                    setName('')
                    setCompanyName('')
                    setSuccess(true)
                }
            } catch (error) {
            }
        }
    }

    return (
        <div className={ styles.wrapper }>
            <div className={ styles.header }>
                <NavLink to={ '/' }>
                    <div className={ styles.goBack }><img style={ { filter: colorToFilter('#383838') } } src={ back } /></div>
                </NavLink>
                <div><SText size={ 16 } lineHeight={ 20 } weight={ 500 } color={ Colors.darkGray }>{ 'ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹' }</SText></div>
            </div>
            <div className={ styles.container }>
                <div style={ { marginBottom: 30 } }><SText size={ 36 } lineHeight={ 36 } weight={ 500 }>{ 'ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹' }</SText></div>
                <div style={ { marginBottom: 20 } }><SText size={ 16 } weight={ 500 }>{ 'Ð¡Ð»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸' }</SText></div>
                <div className={ styles.numberLine } >
                    <SText>{ 'ÐœÐ¾ÑÐºÐ²Ð° Ð¸ Ñ€ÐµÐ³Ð¸Ð¾Ð½Ñ‹:' }</SText>
                    <div>
                        <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 4, marginBottom: '8px' } }>
                            <a href="tel:+74951599009"><SText>{ '+7 (495) 159-90-09' }</SText></a>
                        </div>
                        <div style={ { display: 'flex', alignItems: 'center', gap: 4 } }>
                            <img src={ wa } />
                            <a href="https://wa.me/79774347836" target="_blank"><SText>{ '+7 (977) 434-78-36' }</SText></a>
                        </div>
                    </div>
                </div>
                <div><SText>{ 'Ð¡Ð»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸: support@ecomarket.ru' }</SText></div>
                <div><SText>{ 'ÐžÑ‚Ð´ÐµÐ» Ð·Ð°ÐºÑƒÐ¿Ð¾Ðº: vendors@ecomarket.ru' }</SText></div>
                <div style={ { marginBottom: 30 } }><SText>{ 'ÐžÑ‚Ð´ÐµÐ» Ð¼Ð°Ñ€ÐºÐµÑ‚Ð¸Ð½Ð³Ð°: hasueva@ecomarket.ru' }</SText></div>
                <div className={ styles.numberLine }>
                    <SText>{ 'ÐžÑ‚Ð´ÐµÐ» ÐºÐ¾Ñ€Ð¿Ð¾Ñ€Ð°Ñ‚Ð¸Ð²Ð½Ñ‹Ñ… Ð¿Ñ€Ð¾Ð´Ð°Ð¶ B2B :' }</SText>
                    <div style={ { display: 'flex', alignItems: 'center', gap: 4 } }>
                        <img src={ wa } />
                        <a href="tel:+74951599009"><SText>{ '+7 (495) 159-90-09' }</SText></a>
                    </div>
                </div>
                <div style={ { marginBottom: 30 } }><SText>{ 'ÐŸÐ¾Ñ‡Ñ‚Ð°: b2b@ecomarket.ru' }</SText></div>


                <div className={ styles.form }>
                    <div className={ styles.formTitle }>{ ' ÐžÑÑ‚Ð°Ð²ÑŒÑ‚Ðµ Ð·Ð°ÑÐ²ÐºÑƒ, Ð¸ Ð¼ÐµÐ½ÐµÐ´Ð¶ÐµÑ€ Ð¾Ñ‚Ð´ÐµÐ»Ð° Ð’2Ð’' } <br />
                        { 'ÑÐ²ÑÐ¶ÐµÑ‚ÑÑ Ñ Ð²Ð°Ð¼Ð¸ Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ðµ 1 Ñ‡Ð°ÑÐ°' }</div>
                    <div className={ styles.inputGrid }>
                        <input style={ {
                            color: validate('NAME') === 'invalid' ? '#f03517' : '#383838',
                            border: validate('NAME') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                        } } value={ name }
                            onChange={ (e) => setName(e.target.value) }
                            className={ styles.invalid } type="text"
                            placeholder={ 'Ð˜Ð¼Ñ' } />
                        <input value={ companyName } onChange={ (e) => setCompanyName(e.target.value) }
                            style={ {
                                color: validate('COMPANY') === 'invalid' ? '#f03517' : '#383838',
                                border: validate('COMPANY') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                            } } type="text"
                            placeholder={ 'ÐÐ°Ð·Ð²Ð°Ð½Ð¸Ðµ ÐºÐ¾Ð¼Ð¿Ð°Ð½Ð¸Ð¸' } />
                        <input value={ phone } onChange={ (e) => setPhone(e.target.value) }
                            style={ {
                                color: validate('PHONE') === 'invalid' ? '#f03517' : '#383838',
                                border: validate('PHONE') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                            } } type="text"
                            placeholder={ 'ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°' } />
                        <input value={ mail } onChange={ e => setMail(e.target.value) }
                            style={ {
                                color: validate('EMAIL') === 'invalid' ? '#f03517' : '#383838',
                                border: validate('EMAIL') === 'invalid' ? '1px solid #f03517' : '1px solid #43b02a'
                            } } type="text"
                            placeholder={ 'E-mail' } />
                    </div>
                    <div className={ styles.submitBtn }>
                        <button onClick={ sendRequests }>{ 'ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ' }</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;