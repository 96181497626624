import React from 'react'
import styles from './styles.module.css'
import {BASE_DOMAIN} from "../../../../api";

const ProductImages = ({product}) => {
    const src = BASE_DOMAIN+product.big

    return <div className={styles.imagesContainer}>
        <img src={src} className={styles.productImage}/>
    </div>
}

export default ProductImages
