import React, {useContext, useEffect} from "react";
import styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Item from "../../../screens/OrderTradeOff/components/Item";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import HelperContext from "../../../redux/HelperContext";
import {roundPrice} from "../../../components/utils/other";
import OrderProductXChangeContext from "../../../components/utils/OrderProductXChange";
import BottomModal from "../../components/BottomModal";

const OrderTradeOff = ({onClose}) => {
    const {remains, missedProducts, dismissTradeOff, mode, confirmTradeOff} = useContext(OrderProductXChangeContext)
    const navigate = useNavigate()
    const cart = useSelector(state => state.cart.order)
    const {totals} = useContext(HelperContext)
    const cartSum = totals.cart.actual
    const missedSum = missedProducts.reduce((sum, product) => {
        return sum + (Number(product?.sum) || 0)
    }, 0)
    const discount = mode === 'add' ?
        1 :
        1 - .2

    useEffect(() => {
        if (mode === 'none')
            navigate(-1)
    }, [mode])

    function onMainButtonPress() {
        cart.length ? confirmTradeOff() : navigate('/')
    }

    return <BottomModal onClose={onClose}>
        <div className={styles.wrapper}>
            <div style={{marginBottom: 15}}><SText size={16} weight={900} lineHeight={20}
                                                   color={Colors.darkGray}>{'Ð—Ð°Ð¼ÐµÐ½Ð° Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²'}</SText></div>

            {cart.length
                ? <div style={{marginBottom: 20}}>
                    <div style={{marginBottom: 15, opacity: remains.asSeconds() <= 35 ? 0 : 1, marginLeft: 6}}>
                        <SText size={14} lineHeight={15} weight={700}
                               color={Colors.red}>{'Ðš ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ Ð²Ñ€ÐµÐ¼Ñ ÑÐ±Ð¾Ñ€ÐºÐ¸ Ð¿Ð¾Ð´Ñ…Ð¾Ð´Ð¸Ñ‚ Ðº ÐºÐ¾Ð½Ñ†Ñƒ. ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ Ð·Ð°Ð¼ÐµÐ½Ñƒ.'}</SText>
                    </div>
                    <div><SText size={14} lineHeight={15} weight={700}
                                color={Colors.darkGray}>{'ÐÐµÐ²Ð¾ÑˆÐµÐ´ÑˆÐ¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹:'}</SText></div>
                </div>
                : <div style={{marginBottom: 20}}><SText size={14} weight={500} lineHeight={15}
                                                         color={Colors.darkGray}>
                    {'ÐÐµÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ð½Ðµ Ð²Ð¾ÑˆÐ»Ð¸ Ð² Ð·Ð°ÐºÐ°Ð·, Ñ‚Ð°Ðº ÐºÐ°Ðº, ' +
                        'Ðº ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ, Ð¾Ð½Ð¸ Ð·Ð°ÐºÐ¾Ð½Ñ‡Ð¸Ð»Ð¸ÑÑŒ Ð½Ð° ÑÐºÐ»Ð°Ð´Ðµ Ð² Ð¼Ð¾Ð¼ÐµÐ½Ñ‚ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ñ Ð·Ð°ÐºÐ°Ð·Ð°:'}
                </SText></div>
            }

            <div className={styles.products}>
                {missedProducts.filter(v => !!v).map(prod => <Item
                    product={{...prod, price: prod.sum, weight: prod.amount * prod.coef, thumb: prod.image}}
                    key={prod.id}/>)}
            </div>
            <div className={styles.grayLine}/>

            {cart.length
                ? <div style={{marginBottom: 25}}>
                    <div style={{marginBottom: 14}}><SText size={16} lineHeight={15} weight={700}
                                                           color={Colors.darkGray}>{'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¾ Ð½Ð° Ð·Ð°Ð¼ÐµÐ½Ñƒ:'}</SText>
                    </div>
                    <div className={styles.products}>
                        {cart.map(id => <TempWrapper id={id} key={id} controllable/>)}
                    </div>
                    <div className={styles.grayLine}/>
                    <div style={{marginBottom: 3}} className={styles.finalRow}>
                        <SText size={12} weight={500} lineHeight={15}
                               color={Colors.darkGray}>{'Ð¡Ñ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ Ð´Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð½Ñ‹Ñ… Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð² (ÑÐºÐ¸Ð´ÐºÐ° 20%)'}</SText>
                        <SText size={12} weight={500} lineHeight={15}
                               color={Colors.darkGray}>{`${roundPrice(cartSum * discount)} â‚½`}</SText>
                    </div>
                    <div className={styles.finalRow}>
                        <SText size={12} weight={500} lineHeight={15}
                               color={'#ff7834'}>{'Ð’Ð¾Ð·Ð²Ñ€Ð°Ñ‚ Ð·Ð° Ð½ÐµÐ²Ð¾ÑˆÐµÐ´ÑˆÐ¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹'}</SText>
                        <SText size={12} weight={500} lineHeight={15}
                               color={'#ff7834'}>{`-${roundPrice(missedSum)} â‚½`}</SText>
                    </div>
                </div>
                : <div style={{marginBottom: 25, maxWidth: 342}}>
                    <div style={{marginBottom: 6}}><SText size={14} weight={700} lineHeight={16}
                                                          color={Colors.darkGray}>{'ÐœÑ‹ Ð¿Ñ€Ð¸Ð½Ð¾ÑÐ¸Ð¼ ÑÐ²Ð¾Ð¼ Ð¸Ð·Ð²Ð¸Ð½ÐµÐ½Ð¸Ñ Ð·Ð° Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð½Ñ‹Ðµ Ð½ÐµÑƒÐ´Ð¾Ð±ÑÑ‚Ð²Ð° Ð¸ Ð¿Ñ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÐ¼ Ð¿Ð¾Ð´Ð¾Ð±Ñ€Ð°Ñ‚ÑŒ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ð½Ð° Ð·Ð°Ð¼ÐµÐ½Ñƒ.'}</SText>
                    </div>
                    <div><SText size={14} weight={700} lineHeight={16}
                                color={Colors.green}>{'ÐÐ° Ð´Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð½Ñ‹Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ð¼Ñ‹ ÑÐ´ÐµÐ»Ð°ÐµÐ¼ ÑÐºÐ¸Ð´ÐºÑƒ 20%'}</SText></div>
                </div>}

            <div className={styles.buttons}>
                <div onClick={onMainButtonPress} className={styles.mainBtn}>
                    <div className={styles.mainBtnText}>
                        {cart.length ?
                            `ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð·Ð°Ð¼ÐµÐ½Ñƒ (${roundPrice((discount * cartSum) - missedSum)} â‚½)` :
                            'ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð·Ð°Ð¼ÐµÐ½Ðµ'
                        }
                    </div>
                    <div className={styles.timer}>
                        <SText size={14} weight={700} lineHeight={21}
                               color={remains.asSeconds() <= 35 ? Colors.red : Colors.darkGray}>{`${remains.minutes()}:${String(remains.seconds()).padStart(2, '0')}`}</SText>
                    </div>
                </div>
                <div onClick={dismissTradeOff} className={styles.skipBtn}><SText size={14} weight={700}
                                                                                 lineHeight={18}
                                                                                 color={'#fff'}>{'ÐŸÑ€Ð¾Ð¿ÑƒÑÑ‚Ð¸Ñ‚ÑŒ'}</SText>
                </div>
            </div>

        </div>
    </BottomModal>
}

export const TempWrapper = (
    {
        id, controllable
    }
) => {
    const product = useSelector(state => state.catalogue.products[+id])
    if (!product) return null

    return <Item product={product} controllable={controllable}/>
}

export default OrderTradeOff