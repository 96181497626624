import React from 'react';
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import SText from "../../components/SText";
import goBack from '../../assets/images/cart/goBack.svg'
import styles from './Page.module.scss'

const Page = ({page}) => {
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>{page.meta_title.length ? page.meta_title : page.title}</title>
            </Helmet>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div onClick={() => navigate('/')} className={styles.goBack}><img src={goBack}/></div>
                    <SText size={16} lineHeight={16} weight={500}>{page.title}</SText>
                </div>
                <div>
                    <h1>{page.title}</h1>
                </div>
                <div className={styles.pageContent} dangerouslySetInnerHTML={{__html: page.content}} />
            </div>
        </>
    )
}

export default Page