import {configureStore} from "@reduxjs/toolkit";
import userReducer from './reducers/user'
import cartReducer from './reducers/cart'
import catalogueReducer from './reducers/catalogue'
import deliverySlotsReducer from './reducers/deliverySlots'
import chatsReducer from './reducers/chats'
import configReducer from './reducers/remoteConfig'

export const store = configureStore({
    reducer: {
        user: userReducer,
        cart: cartReducer,
        catalogue: catalogueReducer,
        deliverySlots: deliverySlotsReducer,
        chats: chatsReducer,
        config: configReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
})

window.state = store.getState
window.store = store
