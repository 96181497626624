import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sendRequest from "../../api";
import {resetSlots} from "./deliverySlots";

const initialState = {
    address: {
        id: null,
        name: '',
        gps: null,
        floor: '',
        entrance: '',
        number: '12',
        comment: ''
    },
    apikey: null,
    authToken: null,
    name: '',
    phone: '',
    email: '',
    orders: {items: [], total: 0, unpaid: 0, loadedChunks: 0},
    bonuses: 0,
    bonusesHistory: [],
    personalRecommendations: [],
    paymentCards: [],
    isPolled: true, // ÐÐ½ÐºÐµÑ‚Ð°
    personalPromo: {
        message: '',
        code: '',
        discount: 0,
        valid: true,
    },
    loaded: false
}

export const getAddress = createAsyncThunk(
    'user/getAddress',
    async () => {
        const apikey = localStorage.getItem('apikey')
        if (!apikey) {
            const localAddressJSON = localStorage.getItem('local_address')
            const localAddress = JSON.parse(localAddressJSON)
            if (localAddress) {
                return {
                    ...localAddress,
                    lat: localAddress.gps[0],
                    lon: localAddress.gps[1],
                }
            }
        }

        const response = await sendRequest('getListAddressesUser', {apikey})
        if (!response || response?.length === 0 || !response[0]) return false
        return response[0]
    }
)

export const updateAddress = createAsyncThunk(
    'user/updateAddress',
    async (newData = {}, {getState, dispatch}) => {
        const apikey = localStorage.getItem('apikey')


        const prevState = getState().user.address

        const DELTA = 0.002

        const reset = (newData?.gps !== prevState?.gps && ((Math.abs(newData.gps?.[0] - prevState.gps?.[0]) > DELTA) || (Math.abs(newData.gps?.[1] - prevState.gps?.[1]) > DELTA)))

        const payload = {
            apikey,
            id: prevState.id || null,
            name: newData.name || prevState.name || '',
            name_short: newData.name_short || prevState.name_short || '',
            floor: reset ? '' : (newData.floor !== undefined ? newData.floor : (prevState.floor || '')),
            number: reset ? '' : (newData.number !== undefined ? newData.number : (prevState.number || '')),
            entrance: reset ? '' : (newData.entrance !== undefined ? newData.entrance : (prevState.entrance || '')),
            comment: reset ? '' : (newData.comment !== undefined ? newData.comment : (prevState.comment || '')),
            gps: newData.gps || prevState.gps || [55.742047699088175, 37.61314131981331],
        }

        if (!apikey) {
            localStorage.setItem('local_address', JSON.stringify(payload))
            dispatch(getAddress())
            return
        }

        const action = prevState.id ? 'updateAddressUser' : 'addAddressUser'
        const response = await sendRequest(action, payload)
        dispatch(getAddress())
    }
)

export const getAuthToken = createAsyncThunk(
    'user/getAuthToken',
    async () => {
        return await sendRequest('getAuthToken')
    }
)

export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async (_, {dispatch, getState}) => {
        const apikey = localStorage.getItem('apikey')

        const savedDataJSON = localStorage.getItem('user_local_data')
        const savedData = JSON.parse(savedDataJSON)

        if (!apikey) {
            return {...savedData, apikey: null}
        }

        const savedAddressJSON = localStorage.getItem('local_address')
        const savedAddress = JSON.parse(savedAddressJSON)
        if (savedAddress && apikey) {
            await dispatch(updateAddress({...savedAddress, apikey: apikey}))
            localStorage.removeItem('local_address')
        }

        const response = await sendRequest('updateUserInfo', {apikey})

        const date = new Date();
        date.setUTCMilliseconds(response.user.time)
        document.cookie = 'eco=' + response.user.eco + '; expires=' + date.toUTCString() + '; path=/;'

        if (!response.user) {
            return {apikey}
        }
        const recommendations = await sendRequest('getPopularUserProducts', {apikey, region: 77})
        return {...response.user, apikey, recommendations: recommendations?.products}
    }
)

export const fetchOrders = createAsyncThunk(
    'user/fetchOrders',
    async (_start, {getState}) => {
        const start = getState().user?.orders?.loadedChunks || 0
        const apikey = getState().user.apikey
        if (!apikey) return false
        const payload = {start: start * 20, apikey}
        const response = await sendRequest('getOrders', payload)
        return {items: response?.orders || [], total: response?.total || 0, unpaid: response?.unpaid || 0, chunk: start + 1}
    }
)

export const refreshOrders = createAsyncThunk(
    'user/refreshOrders',
    async (_, {getState}) => {
        const apikey = getState().user.apikey
        if (!apikey) return false
        const payload = {start: 0, apikey}
        const response = await sendRequest('getOrders', payload)
        return {items: response?.orders || [], total: response?.total || 0, unpaid: response?.unpaid || 0, chunk: 1}
    }
)

export const updateUserData = createAsyncThunk(
    'user/updateUserData',
    async (newData, {getState, dispatch}) => {
        const prevState = getState().user
        if (!prevState.apikey) {
            const body = {
                PHONE: newData.phone !== undefined ? newData.phone : prevState.phone,
                NAME: newData.name !== undefined ? newData.name : prevState.name,
            }
            localStorage.setItem('user_local_data', JSON.stringify(body))
            dispatch(fetchUserData())
            return
        }
        const body = {
            phone: newData.phone || prevState.phone,
            name: newData.name !== undefined ? newData.name?.trim() : prevState.name,
            adress: '', //newData.address?.name || prevState.address?.name,
            floor: '', //newData.address?.floor || prevState.address?.floor,
            entrance: '', //newData.address?.entrance || prevState.address?.entrance,
            del_comment: '', //newData.address?.comment || prevState.address?.comment,
            appartment: '', //newData.address?.number || prevState.address?.number,
            email: newData.email || prevState.email,
            new_password: false,
            new_password2: false,
            old_password: false,
            paytype: '1',
            apikey: prevState.apikey
        }
        await sendRequest('doSaveUserData', body)
        dispatch(fetchUserData())
    }
)

export const writeApiKey = createAsyncThunk(
    'user/writeApiKey',
    async (value = '', {dispatch, getState}) => {
        let _value = value //'d528b2e9a1a4bf0d0971d6b1c000c6d7' // value
        localStorage.setItem('apikey', _value)
        return _value
    }
)

export const signOut = createAsyncThunk(
    'user/signOut',
    async (_, {dispatch}) => {
        dispatch(resetSlots())
        return localStorage.removeItem('apikey')
    }
)

export const getBonusesHistory = createAsyncThunk(
    'user/setBonusesHistory',
    async (_, {getState}) => {
        const token = getState().user.apikey
        return await sendRequest('bonusesStory', {apikey: token})
    }
)

export const updatePersonalCode = createAsyncThunk(
    'user/updatePersonalCode',
    async (code, {getState, dispatch}) => {
        const token = getState().user.apikey

        const request = {
            action: 'changeCode',
            code: code,
            apikey: token
        }
        const response = await sendRequest('changeCode', request)

        if (response) {
            dispatch(fetchUserData())
        } else {
            dispatch(setCodeInvalid())
        }
    }
)

export const getPaymentCards = createAsyncThunk(
    'user/getPaymentCards',
    async (_, {getState}) => {
        const apikey = getState().user.apikey

        return await sendRequest('paymentCards', {apikey})
    }
)

export const removePaymentCard = createAsyncThunk(
    'user/removeCard',
    async (id, {getState, dispatch}) => {
        const apikey = getState().user.apikey
        const response = await sendRequest('removeCard', {card: id, apikey})
        await dispatch(getPaymentCards())
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUserData: (state, action) => {
            localStorage.removeItem('apikey')
            return initialState
        },
        setAddress: (state, action) => {
            state.address = {...state.address, ...action.payload}
        },
        setCodeInvalid: (state) => {
            state.personalPromo.valid = false
        }
    },
    extraReducers: builder => {
        builder.addCase(getAuthToken.fulfilled, (state, action) => {
            state.authToken = action.payload.token
            state.loaded = true
        })
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            const data = action.payload
            state.apikey = data.apikey
            if (data.NAME !== undefined)
                state.name = data.NAME
            if (data.PHONE !== undefined)
                state.phone = data.PHONE
            if (data.EMAIL !== undefined)
                state.email = data.EMAIL
            if (data.STATUS !== undefined)
                state.status = data.STATUS
            if (data.IMAGE !== undefined)
                state.profilePicture = data.IMAGE
            if (data.BONUSES !== undefined)
                state.bonuses = data.BONUSES
            if (data.polled !== undefined)
                state.isPolled = data.polled === '1'
            state.loaded = true

            if (data.CODE !== undefined)
                state.personalPromo = {
                    message: data.CODE_MESSAGE,
                    code: data.CODE,
                    discount: data.PROMO_DISCOUNT,
                    valid: true,
                }
            state.personalRecommendations = data.recommendations || []
        })
        builder.addCase(fetchUserData.rejected, () => {
            console.log('FAILED TO FETCH USER')
        })
        builder.addCase(writeApiKey.fulfilled, (state, action) => {
            state.apikey = action.payload
        })
        builder.addCase(signOut.fulfilled, (state) => {
            return {
                ...initialState,
                authToken: state.authToken
            }
        })
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            if (action.payload.chunk < state.orders.loadedChunks || action.payload === false) return state

            state.orders = {
                items: [
                    ...state.orders.items,
                    ...action.payload.items
                ],
                total: action.payload.total,
                unpaid: action.payload.unpaid,
                loadedChunks: action.payload.chunk
            }
        })

        builder.addCase(refreshOrders.fulfilled, (state, action) => {
            if (!action.payload.items) return state
            state.orders = {
                items: [
                    ...action.payload.items
                ],
                total: action.payload.total,
                unpaid: action.payload.unpaid,
                loadedChunks: action.payload.chunk
            }
        })
        builder.addCase(getAddress.fulfilled, (state, action) => {
            if (action.payload) {
                state.address = {...action.payload, gps: [action.payload?.lat, action.payload?.lon]}
            }
        })
        builder.addCase(getBonusesHistory.fulfilled, (state, action) => {
            if (!action.payload.bonuses_story) return state

            state.bonusesHistory = action.payload.bonuses_story
        })

        builder.addCase(getPaymentCards.fulfilled, (state, action) => {
            state.paymentCards = action.payload?.cards || []
        })
    }
})

export const {setAddress, resetUserData, setCodeInvalid} = userSlice.actions

export default userSlice.reducer


const postalTracking = {
    status: '', // Ð¿Ð¾ÐºÐ° Ñ…Ð· ÐºÐ°ÐºÐ¸Ðµ, Ð½Ð¾ Ð¼Ð± Ñ‡Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ñ€Ð¸Ð´ÑƒÐ¼Ð°ÐµÐ¼, ÐµÑÐ»Ð¸ Ð½ÑƒÐ¶Ð½Ð¾ Ð±ÑƒÐ´ÐµÑ‚ Ñ€ÐµÐ½Ð´ÐµÑ€Ð¸Ñ‚ÑŒ Ñ‡Ñ‚Ð¾-Ñ‚Ð¾ Ð² Ð·Ð°Ð²Ð¸ÑÐ¸Ð¼Ð¾ÑÑ‚Ð¸ Ð¾Ñ‚ ÑÑ‚Ð°Ñ‚ÑƒÑÐ°
    pickPointAddress: 'ÐÐ´Ñ€ÐµÑ Ð¿ÑƒÐ½ÐºÑ‚Ð° Ð²Ñ‹Ð´Ð°Ñ‡Ð¸',
    pickPointSchedule: 'Ð¿Ð½-Ð²Ñ 09:00 - 22:00',
    steps: [
        {
            label: 'ÐŸÐµÑ€ÐµÐ´Ð°Ð½ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸ $name', // ÐÐ°Ð·Ð²Ð°Ð½Ð¸Ðµ ÑÑ‚Ð°Ð¿Ð°
            dateTime: 'DD-MM-YYYY HH:MM', // Ñ‚Ð°Ð¹Ð¼ÑÑ‚ÑÐ¼Ð¿ ÑÑ‚Ð°Ð¿Ð°; Ð¼Ð¾Ð¶Ð½Ð¾ Ð¿Ð¾ÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ false, ÐµÑÐ»Ð¸ ÑÑ‚Ð°Ð¿ ÐµÑ‰Ðµ Ð½Ðµ Ð¿Ñ€Ð¾Ð¹Ð´ÐµÐ½
        },
        {
            label: 'ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð»ÐµÐ½',
            dateTime: 'DD-MM-YYYY HH:MM',
        },
        {
            label: 'ÐŸÑ€Ð¸Ð½ÑÑ‚ ÐºÐ°ÐºÐ¾Ð¹-Ñ‚Ð¾ Ñ‚Ð°Ð¼ Ñ‚Ð¾Ñ‡ÐºÐ¾Ð¹ Ð´Ð»Ñ ÐºÐ°ÐºÐ¾Ð¹-Ñ‚Ð¾ Ñ†ÐµÐ»Ð¸', // ÐŸÐ¾ÑÐ»ÐµÐ´Ð½Ð¸Ð¹ ÑÑ‚Ð°Ñ‚ÑƒÑ Ñ Ð¢Ðš, ÐºÑ€Ð¾Ð¼Ðµ Ð¿ÐµÑ€Ð²Ñ‹Ñ… Ð´Ð²ÑƒÑ… Ð¸ Ð¿Ð¾ÑÐ»ÐµÐ´Ð½ÐµÐ³Ð¾
            dateTime: 'DD-MM-YYYY HH:MM',                          // ÐÐµ Ð¿ÐµÑ€ÐµÐ´Ð°Ð²Ð°Ñ‚ÑŒ ÐµÑÐ»Ð¸ Ð½Ð¸Ñ‡ÐµÐ³Ð¾ Ð¿Ð¾Ð»ÐµÐ·Ð½Ð¾Ð³Ð¾ Ñ‚Ð°Ð¼ Ð½ÐµÑ‚
        },
        {
            label: 'ÐŸÑ€Ð¸Ð±Ñ‹Ð» Ð² Ð¿ÑƒÐ½ÐºÑ‚ Ð²Ñ‹Ð´Ð°Ñ‡Ð¸',
            dateTime: false, // Ð½Ñƒ ÑÐ¾Ð±ÑÐ½Ð° false, Ñ‚Ðº Ð½Ðµ Ð¿Ñ€Ð¸Ð±Ñ‹Ð» ÐµÑ‰Ðµ
        },
    ]
}

