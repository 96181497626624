import React from "react";
import styles from './styles.module.css'
import SText from "../../../../components/SText";
import Nutritionals from "./Nutritionals";

const Description = ({product}) => {
    const text = product.short_description?.length ?
        product.short_description :
        'Ð£ Ð½Ð°Ñ Ñ‚Ð°Ðº Ð¼Ð½Ð¾Ð³Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð², Ñ‡Ñ‚Ð¾ Ð¼Ñ‹ Ð½Ðµ ÑƒÑÐ¿ÐµÐ»Ð¸ Ð·Ð°Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÑŒ Ð¾Ð¿Ð¸ÑÐ°Ð½Ð¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð°. Ð¡ÐºÐ¾Ñ€Ð¾ Ð¼Ñ‹ Ð¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð´Ð¾Ð±Ð°Ð²Ð¸Ð¼ ÑÑ‚Ñƒ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÑŽ'


    return <div>
        <div>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'ÐžÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ð°'}</SText>
            <SText size={14} weight={400} div style={{padding: '0 16px 16px'}}>{text}</SText>
        </div>
        {product.storage && <div>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'Ð£ÑÐ»Ð¾Ð²Ð¸Ñ Ñ…Ñ€Ð°Ð½ÐµÐ½Ð¸Ñ'}</SText>
            <SText size={14} weight={400} div style={{padding: '0 16px 16px'}}>{product.storage}</SText>
        </div>}
        {product.sostav && <div>
            <SText div weight={700} size={15} className={styles.sectionTitle}>{'Ð¡Ð¾ÑÑ‚Ð°Ð²'}</SText>
            <SText size={14} weight={400} div style={{padding: '0 16px 16px'}}>{product.sostav}</SText>
        </div>}
        <Nutritionals klet={product.klet_amount}
                      fats={product.fats_amount}
                      ugl={product.ugl_amount}
                      bel={product.bel_amount}
                      calories={product.calories_amount}/>
    </div>
}

export default Description
