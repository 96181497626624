import React, { useEffect, useState } from "react";
import styles from '../styles.module.scss'
import back from '../../../../assets/images/cart/goBack.svg'
import { colorToFilter } from "../../../../components/utils/ColorFilter";
import { useDispatch, useSelector } from "react-redux";
import { getBonusesHistory } from "../../../../redux/reducers/user";
import SText from "../../../../components/SText";
import ecoBalls from "../../../../assets/images/userAccount/bonuses/bigEco.svg";
import { Anketa, Code, getClosestBurnings, Slider } from "../../../../screens/UserAccount/Bonuses";
import Colors from "../../../../components/utils/Colors";
import { NavLink } from "react-router-dom";
import BottomModal from "../../../components/BottomModal";
import { declOfNum } from "../../../../components/utils/other";
import hedgehogSmall from '../../../../assets/images/userAccount/bonuses/hedgehogSmall.png'
import fireBallsImg from '../../../../assets/images/userAccount/bonuses/fireBalls.svg'
import termo from "../../../../assets/images/userAccount/bonuses/thermal-bag.svg";

const Bonuses = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [extraVisible, setExtraVisible] = useState(false)
    const [bonusesHistory, setBonusesHistory] = useState(false)
    const [fireBalls, setFireBalls] = useState(false)

    useEffect(() => {
        dispatch(getBonusesHistory())
    }, [])
    return <div className={ styles.container }>
        <div className={ styles.header }>
            <NavLink to={ '/' }>
                <div className={ styles.goBack }><img style={ { filter: colorToFilter('#383838') } } src={ back } /></div>
            </NavLink>
            <div><SText size={ 16 } lineHeight={ 20 } weight={ 500 } color={ Colors.darkGray }>{ 'Ð‘Ð¾Ð½ÑƒÑÑ‹' }</SText></div>
        </div>
        <div style={ { display: "flex", alignItems: 'flex-end', gap: 14, marginBottom: 33 } }>
            <div className={ styles.ecoRectangle }>
                <div style={ { marginBottom: 7 } }><SText size={ 16 } weight={ 500 } lineHeight={ 20 }
                    color={ '#fff' }>{ 'Ð’Ð°ÑˆÐ¸ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹' }</SText></div>
                <div style={ { display: 'flex', alignItems: 'center', gap: 9, marginLeft: 3 } }>
                    <img src={ ecoBalls } />
                    <div style={ { marginTop: -1 } }><SText size={ 30 } weight={ 700 } lineHeight={ 20 }
                        color={ '#fff' }>{ user.bonuses }</SText></div>
                </div>
            </div>
            <img onClick={ () => setFireBalls(true) } src={ fireBallsImg } />
            { fireBalls && <FireBalls user={ user } onClose={ () => setFireBalls(false) } /> }
        </div>
        <div style={ { marginBottom: 13 } }><SText color={ Colors.darkGray } size={ 14 } weight={ 700 }
            lineHeight={ 18 }>{ '1 ÑÐºÐ¾Ð±Ð°Ð»Ð» = 1 Ñ€ÑƒÐ±Ð»ÑŒ' }</SText>
        </div>
        <div style={ { marginBottom: 18 } } className={ styles.text }>
            Ð­ÐºÐ¾Ð±Ð°Ð»Ð»Ð°Ð¼Ð¸ Ð¼Ð¾Ð¶Ð½Ð¾ Ð¾Ð¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ 25% Ð·Ð°ÐºÐ°Ð·Ð°. <br />
            Ð­ÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹ ÑÐ³Ð¾Ñ€Ð°ÑŽÑ‚ Ð¿Ð¾ Ð¸ÑÑ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 30 Ð´Ð½ÐµÐ¹.
        </div>
        <Code promo={ user.personalPromo } />
        { !user.isPolled && <Anketa /> }
        <Slider />
        <div style={ { marginBottom: 32, cursor: 'pointer' } }>
            <SText onClick={ () => setExtraVisible(true) } size={ 16 } lineHeight={ 20 } weight={ 500 }
                color={ Colors.darkGray }>{ 'Ð”Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ñ‹Ðµ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹' }</SText>
            { extraVisible && <ExtraBonuses onClose={ () => setExtraVisible(false) } /> }
        </div>
        <div style={ { cursor: 'pointer' } }>
            <SText onClick={ () => setBonusesHistory(true) } size={ 16 } lineHeight={ 20 } weight={ 500 }
                color={ Colors.darkGray }>{ 'Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð½Ð°Ñ‡Ð¸ÑÐ»ÐµÐ½Ð¸Ñ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²' }</SText>
            { bonusesHistory &&
                <BonusesHistory onClose={ () => setBonusesHistory(false) } bonusesHistory={ user.bonusesHistory } /> }
        </div>
    </div>
}

const FireBalls = ({ user, onClose }) => {

    return <BottomModal onClose={ onClose }>
        <div className={ styles.ecoFire }>
            { getClosestBurnings(user.bonusesHistory).map(item => (
                <div>
                    <div style={ { background: item.color } } />
                    <SText color={ item.color } size={ 14 } weight={ 500 } lineHeight={ 18 }>{ item.text }</SText>
                </div>)) }
        </div>
    </BottomModal>
}

const ExtraBonuses = ({ onClose }) => {

    return <BottomModal onClose={ onClose }>
        <div style={ { padding: '0 15px' } }>
            <div style={ { marginBottom: 21 } }>
                <SText size={ 20 } weight={ 700 } lineHeight={ 25 }
                    color={ Colors.darkGray }>{ 'Ð”Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ñ‹Ðµ ÑÐºÐ¾Ð±Ð°Ð»Ð»Ñ‹' }</SText>
            </div>
            <div style={ { marginBottom: 20 } }><img style={ { width: '100%' } } src={ hedgehogSmall } /></div>
            <div><a
                href="whatsapp://send?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D0%BA%D1%83%D1%80%D1%8C%D0%B5%D1%80%20%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D0%BB%20%D1%83%20%D0%BD%D0%B0%D1%81%20%D1%82%D0%B5%D1%80%D0%BC%D0%BE%D0%BF%D0%B0%D0%BA%D0%B5%D1%82.%20%D0%9F%D1%80%D0%BE%D1%88%D1%83%20%D0%B7%D0%B0%D0%B1%D1%80%D0%B0%D1%82%D1%8C%20%D0%B5%D0%B3%D0%BE%20%D0%BF%D1%80%D0%B8%20%D1%81%D0%BB%D0%B5%D0%B4%D1%83%D1%8E%D1%89%D0%B5%D0%B9%20%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B5%20%D0%B8%20%D0%BD%D0%B0%D1%87%D0%B8%D1%81%D0%BB%D0%B8%D1%82%D1%8C%20%D0%BC%D0%BD%D0%B5%20200%20%D1%8D%D0%BA%D0%BE%D0%B1%D0%B0%D0%BB%D0%BB%D0%BE%D0%B2&phone=+74951599009"><img
                    style={ { width: '100%' } } src={ termo } /></a></div>
        </div>
    </BottomModal>
}

const BonusesHistory = ({ bonusesHistory, onClose }) => {

    return <BottomModal onClose={ onClose }>
        <div className={ styles.extraBonusesModal }>
            <div>
                {
                    bonusesHistory.map(bonus => {
                        return <div style={ { marginBottom: 15 } }>
                            <div style={ { marginBottom: 10 } }><SText size={ 18 } weight={ 700 } lineHeight={ 23 }
                                color={ Colors.darkGray }>{ bonus.AMOUNT + ' ' + declOfNum(bonus.AMOUNT, ['ÑÐºÐ¾Ð±Ð°Ð»Ð»', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð°', 'ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²']) }</SText>
                            </div>
                            <div>
                                <SText size={ 14 } weight={ 500 } lineHeight={ 17 }
                                    color={ Colors.darkGray }>{ bonus.HUMAN_DATE + ' ' + bonus.WAY }</SText>
                            </div>
                            <div><SText size={ 14 } weight={ 500 } lineHeight={ 17 }
                                color={ Colors.darkGray }>{ 'ÐÐºÑ‚Ð¸Ð²Ð½Ñ‹ Ð´Ð¾ ' + bonus.DATE_END }</SText></div>
                        </div>
                    })
                }
            </div>
        </div>
    </BottomModal>
}

export default Bonuses