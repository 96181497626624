import React from "react";
import {Link} from "react-router-dom";
import styles from './Breadcrumbs.module.scss'

const Breadcrumbs = ({breadcrumbTree}) => {
    if (!breadcrumbTree.length) {
        return null
    }

    let breads = [];
    for (let i = 0; i < breadcrumbTree.length; i++) {
        let linkData = breadcrumbTree[i];

        breads.push(
            <li 
                key={i}
                itemProp="itemListElement" 
                itemScope="" 
                itemType="http://schema.org/ListItem"
            >
                <Link to={'/' + linkData.url} title={linkData.title} itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                    <span itemProp="name">{linkData.title}</span>
                    <meta itemProp="position" content={ i + 1} />
                </Link>
            </li>
        );
    }


    return (
        <ul className={styles.breadCrumbs} itemScope="" itemType="http://schema.org/BreadcrumbList">
            {breads}
        </ul>
    )
}

export default Breadcrumbs;