import React, {useEffect, useState} from "react";
import styles from "./SaveNature.module.css"
import cn from "classnames";
import "slick-carousel/slick/slick.css";
import "../../components/Banner/style.css";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import SText from "../../components/SText";
import {useWindowDimensions} from "../../components/utils/hooks";
import angelina from '../../assets/images/saveNature/anna.png'
import anna from '../../assets/images/saveNature/angelina.png'
import olga from '../../assets/images/saveNature/olga.png'
import natasha from '../../assets/images/saveNature/natasha.png'
import svetlana from '../../assets/images/saveNature/svetlana.png'
import arrowFirst from '../../assets/images/saveNature/arrowFirst.svg'
import arrowSecond from '../../assets/images/saveNature/arrowSecond.svg'
import arrowMobile from '../../assets/images/saveNature/arrowMobile.svg'
import bag from '../../assets/images/saveNature/bag.svg'
import batteryLeft from '../../assets/images/saveNature/batteryLeft.svg'
import batteryRight from '../../assets/images/saveNature/batteryRight.svg'
import bottles from '../../assets/images/saveNature/bottles.svg'
import bottlesAndPens from '../../assets/images/saveNature/bottlesAndPens.svg'
import cloud from '../../assets/images/saveNature/cloud.svg'
import ecoLogo from '../../assets/images/saveNature/ecoLogo.svg'
import glass from '../../assets/images/saveNature/glass.svg'
import globe from '../../assets/images/saveNature/globe.svg'
import hedgehog from '../../assets/images/saveNature/hedgehog.svg'
import leaf from '../../assets/images/saveNature/leaf.svg'
import messageAngle from '../../assets/images/saveNature/messageAngle.svg'
import pens from '../../assets/images/saveNature/pens.svg'
import spoonAndFork from '../../assets/images/saveNature/spoonAndFork.svg'
import trash from '../../assets/images/saveNature/trash.svg'
import trashCar from '../../assets/images/saveNature/trashCar.svg'
import trashCarMobile from '../../assets/images/saveNature/trashCarMobile.svg'

const SaveNature = () => {

    useEffect(() => {
        const animItems = document.querySelectorAll('.' + styles.animItems)

        if (animItems.length > 0) {
            window.addEventListener('scroll', animOnScroll)

            function animOnScroll() {
                for (let index = 0; index < animItems.length; index++) {
                    const animItem = animItems[index]
                    const animItemHeight = animItem.offsetHeight
                    const animItemOffset = offset(animItem).top
                    const animStart = 1.5

                    let animItemPoint = window.innerHeight - animItemHeight / animStart;
                    if (animItemHeight > window.innerHeight) {
                        animItemPoint = window.innerHeight - window.innerHeight / animStart
                    }
                    if ((window.pageYOffset > animItemOffset - animItemPoint) && window.pageYOffset < (animItemOffset + animItemHeight)) {
                        animItem.classList.add(styles.active80left)
                    } else {
                        if (!animItem.classList.contains(styles.animNoHide))
                            animItem.classList.remove(styles.active80)
                    }
                }
            }

            function offset(el) {
                const rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
            }

            setTimeout(() => {
                animOnScroll()
            }, 1500)
        }
    }, [])

    const width = useWindowDimensions()
    const [visible, setVisible] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    return <div style={{overflowX: 'hidden'}}>


        {width > 800
            ? <div className={styles.header}>
                <div>
                    <NavLink to={'/'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={900} lineHeight={18}
                                                              color={'#fff'}>ECOMARKET.RU</SText></div>
                    </NavLink>
                    <NavLink to={'/'}>
                        <div><SText size={14} weight={700} lineHeight={18} color={'#fff'}>ÐÐ° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ</SText>
                        </div>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={'/about'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>Ðž
                            Ð½Ð°Ñ</SText></div>
                    </NavLink>
                    <NavLink to={'/delivery'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°</SText></div>
                    </NavLink>
                    <NavLink to={'/pay'}>
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>ÐžÐ¿Ð»Ð°Ñ‚Ð°</SText></div>
                    </NavLink>
                    <a href="tel:74951599009">
                        <div style={{marginRight: 30}}><SText size={14} weight={400} lineHeight={17}
                                                              color={'#fff'}>8
                            (495) 445-05-05</SText></div>
                    </a>
                </div>
            </div>
            : <div className={styles.burger}>
                <div style={{marginLeft: 15}}>
                    <SText onClick={() => {
                        if (searchParams.get('fromApp')) return
                        navigate('/')
                    }} size={18} weight={900} lineHeight={23}
                           color={'#fff'}>ECOMARKET.RU</SText>

                </div>
                {!searchParams.get('fromApp') && <div style={{marginRight: 30}}>
                    <div className="hamburger-menu">
                        <input id="menu__toggle" type="checkbox" onClick={() => setVisible(prev => !prev)}/>
                        <label className="menu__btn" htmlFor={'menu__toggle'}>
                            <span/>
                        </label>
                        {visible ? <div className={styles.burgerMenu}>
                            <NavLink to={'/'}>
                                <div><SText size={18} weight={900} lineHeight={23} color={'#fff'}>ÐÐ°
                                    Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ</SText>
                                </div>
                            </NavLink>
                            <NavLink to={'/about'}>
                                <div><SText size={18} weight={400} lineHeight={22} color={'#fff'}>Ðž Ð½Ð°Ñ</SText>
                                </div>
                            </NavLink>
                            <NavLink to={'/delivery'}>
                                <div><SText size={18} weight={400} lineHeight={22}
                                            color={'#fff'}>Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°</SText>
                                </div>
                            </NavLink>
                            <NavLink to={'/pay'}>
                                <div><SText size={18} weight={400} lineHeight={22} color={'#fff'}>ÐžÐ¿Ð»Ð°Ñ‚Ð°</SText>
                                </div>
                            </NavLink>
                            <a href="tel:74951599009">
                                <div style={{borderBottom: '2px solid #fff'}}><SText size={18} weight={700}
                                                                                     lineHeight={23} color={'#fff'}>8
                                    (495) 445-05-05</SText></div>
                            </a>
                        </div> : null}
                    </div>
                </div>}
            </div>}


        <div className={styles.wrapper}>


            <div className={styles.globe}>
                <div className={styles.ecoLogo}><img src={ecoLogo}/></div>
                <div style={{
                    marginBottom: width > 650 ? 40 : 35,
                    textAlign: 'center',
                    maxWidth: width > 650 ? 'none' : 290
                }}><SText
                    size={width > 650 ? 70 : 40}
                    weight={900}
                    lineHeight={width > 650 ? 76 : 44}
                    color={'#fff'}>Ð‘Ð•Ð Ð•Ð–Ã‹Ðœ
                    ÐŸÐ Ð˜Ð ÐžÐ”Ð£</SText></div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 310}}><SText
                    size={width > 650 ? 30 : 20} weight={900}
                    lineHeight={width > 650 ? 36 : 26}
                    color={'#72e958'}>ÐŸÐ¾Ð¼Ð¾Ð³Ð°Ð¹Ñ‚Ðµ
                    ÑÐºÐ¾Ð»Ð¾Ð³Ð¸Ð¸,<br/>
                    Ð±ÑƒÐ´ÑŒÑ‚Ðµ Ð°ÐºÑ‚Ð¸Ð²Ð½Ñ‹Ð¼Ð¸, Ð¿Ð¾Ð»ÑƒÑ‡Ð°Ð¹Ñ‚Ðµ Ð±Ð¾Ð½ÑƒÑÑ‹!</SText></div>

                <div className={cn(styles.globeImg, styles.animItems, styles.animNoHide)}><img
                    src={globe}/></div>
                <div className={cn(styles.cloud1, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
                <div className={cn(styles.cloud2, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
                <div className={cn(styles.cloud3, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
            </div>


            <div className={styles.trash}>
                <div style={{marginBottom: width > 650 ? 80 : 40, textAlign: 'center'}}>
                    <SText size={width > 650 ? 76 : 40} weight={900} lineHeight={width > 650 ? 76 : 44}
                           color={'#005983'}>Ð Ð²Ñ‹ Ð·Ð½Ð°Ð»Ð¸?</SText>
                </div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 290}}>
                    <SText size={width > 650 ? 30 : 20} weight={700} lineHeight={width > 650 ? 36 : 26}
                           color={'#000'}>Ð§Ñ‚Ð¾ Ð±Ð¾Ð»ÑŒÑˆÐµ Ñ‚Ñ€ÐµÑ‚Ð¸ Ð²ÑÐµÑ… Ð¾Ñ‚Ñ…Ð¾Ð´Ð¾Ð²,
                        ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ð¾Ð±Ñ€Ð°Ð·ÑƒÑŽÑ‚ÑÑ <br/> Ð² Ð½Ð°ÑˆÐ¸Ñ… Ð´Ð¾Ð¼Ð°Ñ…, Ð¼Ð¾Ð¶Ð½Ð¾ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒ
                        Ð²Ñ‚Ð¾Ñ€Ð¸Ñ‡Ð½Ð¾?</SText>
                </div>

                <div className={cn(styles.cloud4, styles.animItems, styles.animNoHide)}><img
                    src={cloud}/></div>
                <div className={cn(styles.trashImg, styles.animItems, styles.animNoHide)}><img
                    src={trash}/></div>
                <div className={cn(styles.bagImg, styles.animItems, styles.animNoHide)}><img
                    src={bag}/></div>
            </div>


            <div className={styles.bottle}>
                <div style={{textAlign: 'center', marginBottom: 45, maxWidth: width > 650 ? 'none' : 270}}><SText
                    size={width > 650 ? 70 : 32}
                    weight={900}
                    lineHeight={width > 650 ? 76 : 38}
                    color={'#fff'} div>ÐÐÐ¨Ð˜ ÐšÐ£Ð Ð¬Ð•Ð Ð«
                    ÐœÐžÐ“Ð£Ð¢ <br/> Ð—ÐÐ‘Ð ÐÐ¢Ð¬ Ð£ Ð’ÐÐ¡</SText></div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 290}}><SText
                    size={width > 650 ? 30 : 22}
                    lineHeight={width > 650 ? 36 : 26} weight={700}
                    color={'#c2e9ff'}
                    div>ÐŸÐ»Ð°ÑÑ‚Ð¸ÐºÐ¾Ð²Ñ‹Ðµ ÐºÑ€Ñ‹ÑˆÐºÐ¸ Ð¾Ñ‚ Ð±ÑƒÑ‚Ñ‹Ð»Ð¾Ðº, Ñ„Ð»Ð¾Ð¼Ð°ÑÑ‚ÐµÑ€Ñ‹, <br/>
                    Ñ€ÑƒÑ‡ÐºÐ¸, Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð½ÑƒÑŽ Ð¿Ð»Ð°ÑÑ‚Ð¸ÐºÐ¾Ð²ÑƒÑŽ Ñ‚Ð°Ñ€Ñƒ <br/>
                    Ð¾Ñ‚ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚ </SText></div>

                <div className={cn(styles.bottlesImg, styles.animItems, styles.animNoHide)}><img
                    src={bottles}/></div>
                <div className={cn(styles.arrow1Img, styles.animItems, styles.animNoHide)}><img
                    src={arrowFirst}/></div>
                <div className={cn(styles.pensImg, styles.animItems, styles.animNoHide)}><img
                    src={pens}/></div>
                <div className={cn(styles.arrow2Img, styles.animItems, styles.animNoHide)}><img
                    src={arrowSecond}/></div>

                <div className={cn(styles.bottlesAndPensImg, styles.animItems, styles.animNoHide)}><img
                    src={bottlesAndPens}/></div>
                <div className={cn(styles.arrowMobileImg, styles.animItems, styles.animNoHide)}><img
                    src={arrowMobile}/></div>
            </div>


            <div className={styles.trashCar}>
                <div style={{
                    marginBottom: width > 650 ? 45 : 150,
                    textAlign: 'center',
                    maxWidth: width > 650 ? 'none' : 270
                }}><SText
                    size={width > 650 ? 70 : 40} weight={900} lineHeight={width > 650 ? 76 : 44}
                    color={'#f5c15f'}>Ð
                    Ð§Ð¢Ðž Ð–Ð• Ð”ÐÐ›Ð¬Ð¨Ð•?</SText></div>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 40 : 30,
                    maxWidth: width > 650 ? 'none' : 310
                }}><SText size={width > 650 ? 30 : 18}
                          weight={700}
                          lineHeight={width > 650 ? 36 : 22}
                          color={'#fff'} div>ÐŸÐ¾Ð¿Ð°Ð´Ð°Ñ Ðº Ð½Ð°Ð¼ Ð½Ð° ÑÐºÐ»Ð°Ð´,
                    Ñ‡ÐµÑ€ÐµÐ·
                    Ð½ÐµÐºÐ¾Ñ‚Ð¾Ñ€Ð¾Ðµ {width > 650 ? <br/> : null} Ð²Ñ€ÐµÐ¼Ñ Ð¸Ñ… Ð·Ð°Ð±Ð¸Ñ€Ð°ÐµÑ‚ ÑÐ¿ÐµÑ†Ð¸Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð¼ÑƒÑÐ¾Ñ€Ð¾Ð²Ð¾Ð·.</SText></div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 310}}><SText
                    size={width > 650 ? 30 : 18}
                    weight={700}
                    lineHeight={width > 650 ? 36 : 22}
                    color={'#fff'}
                    div>Ð”Ð°Ð»ÐµÐµ
                    ÑÐ¾Ð±Ñ€Ð°Ð½Ð½Ð¾Ðµ Ð²Ñ‚Ð¾Ñ€Ð¸Ñ‡Ð½Ð¾Ðµ ÑÑ‹Ñ€ÑŒÐµ {width > 650 ? <br/> : null} Ð¸ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÑÐµÑ‚ÑÑ Ð½Ð° Ð·Ð°Ð²Ð¾Ð´Ñ‹ Ð¿Ð¾
                    Ð¿ÐµÑ€ÐµÑ€Ð°Ð±Ð¾Ñ‚ÐºÐµ, {width > 650 ? <br/> : null} Ñ‡Ñ‚Ð¾Ð±Ñ‹ ÑÑ‚Ð°Ñ‚ÑŒ Ð½Ð¾Ð²Ñ‹Ð¼Ð¸
                    Ð¿Ð»Ð°ÑÑ‚Ð¸ÐºÐ¾Ð²Ñ‹Ð¼Ð¸ Ð²ÐµÑ‰Ð°Ð¼Ð¸ {width > 650 ? <br/> : null} Ð¸ ÑÐ½Ð¾Ð²Ð° Ð±Ñ‹Ñ‚ÑŒ Ð¿Ð¾Ð»ÐµÐ·Ð½Ñ‹Ð¼Ð¸ Ð½Ð°Ð¼ Ñ Ð²Ð°Ð¼Ð¸.</SText></div>

                <div className={cn(styles.trashCarImg, styles.animItems, styles.animNoHide)}><img
                    src={trashCar}/></div>
                <div className={cn(styles.spoonImg, styles.animItems, styles.animNoHide)}><img
                    src={spoonAndFork}/></div>
                <div className={cn(styles.glassImg, styles.animItems, styles.animNoHide)}><img
                    src={glass}/></div>

                <div className={cn(styles.trashCarMobileImg, styles.animItems, styles.animNoHide)}><img
                    src={trashCarMobile}/></div>
            </div>


            <div className={styles.battery}>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 55 : 35,
                    maxWidth: width > 650 ? 'none' : 275
                }}><SText
                    size={width > 650 ? 70 : 40} weight={900}
                    lineHeight={width > 650 ? 76 : 44}
                    color={'#26920e'}>Ð Ð•Ð©Ð• ÐœÐ« Ð—ÐÐ‘Ð˜Ð ÐÐ•Ðœ</SText>
                </div>
                <div style={{textAlign: 'center', maxWidth: width > 650 ? 'none' : 275}}><SText
                    size={width > 650 ? 30 : 24} weight={700}
                    lineHeight={width > 650 ? 36 : 28} color={'#000'}>Ð˜ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð½Ñ‹Ðµ
                    Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸,<br/>
                    Ð²ÐµÐ´ÑŒ Ð¸Ñ… Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾ Ð±ÐµÐ·Ð¾Ð¿Ð°ÑÐ½Ð¾ ÑƒÑ‚Ð¸Ð»Ð¸Ð·Ð¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ</SText></div>

                <div className={cn(styles.batteryLeftImg, styles.animItems, styles.animNoHide)}><img
                    src={batteryLeft}/></div>
                <div className={cn(styles.batteryRightImg, styles.animItems, styles.animNoHide)}><img
                    src={batteryRight}/></div>
            </div>


            <div className={styles.hedgehogs}>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 40 : 32,
                    position: 'relative',
                    maxWidth: width > 650 ? 'none' : 270
                }}>
                    <SText size={width > 650 ? 60 : 36} weight={900} lineHeight={width > 650 ? 76 : 40}
                           color={'#fff'} div>Ð¡ÐŸÐÐ¡ÐÐ•Ðœ ÐÐ–Ð˜ÐšÐžÐ’ Ð˜ ÐŸÐžÐ›Ð£Ð§ÐÐ•Ðœ <br/>
                        ÐŸÐ Ð˜Ð¯Ð¢ÐÐ«Ð• Ð‘ÐžÐÐ£Ð¡Ð«</SText>
                    <div className={styles.leaf}><img src={leaf}/></div>
                </div>
                <div style={{
                    textAlign: 'center',
                    marginBottom: width > 650 ? 80 : 125,
                    maxWidth: width > 650 ? 'none' : 295
                }}><SText
                    size={width > 650 ? 30 : 20} weight={700}
                    lineHeight={width > 650 ? 36 : 28}
                    color={'#000'} div>1 Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ° = 10
                    ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² <br/> 1 ÐºÑ€Ñ‹ÑˆÐºÐ° = 5 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð² <br/> 1 Ñ€ÑƒÑ‡ÐºÐ°/Ñ„Ð»Ð¾Ð¼Ð°ÑÑ‚ÐµÑ€ = 5 ÑÐºÐ¾Ð±Ð°Ð»Ð»Ð¾Ð²</SText></div>
                <div style={{textAlign: 'center'}}><SText size={width > 650 ? 36 : 24} weight={900}
                                                          lineHeight={width > 650 ? 36 : 28}
                                                          color={'#c2e9ff'}>Ð”Ð°Ð²Ð°Ð¹Ñ‚Ðµ Ð±ÐµÑ€ÐµÑ‡ÑŒ Ð¿Ñ€Ð¸Ñ€Ð¾Ð´Ñƒ {width <= 650 ?
                    <br/> : null}
                    Ð²Ð¼ÐµÑÑ‚Ðµ!</SText></div>

                <div className={cn(styles.hedgehogsImg, styles.animItems, styles.animNoHide)}><img
                    src={hedgehog} alt=""/></div>
            </div>


        </div>


        {width > 650
            ? null /*<div className={styles.commentWrapper}>
                <div className={styles.comment}>
                    <Slider className={'saveNatureSlider'} {...settings}>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={olga}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>ÐžÐ»ÑŒÐ³Ð°</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>ÐŸÐ¾ÐºÑƒÐ¿Ð°ÑŽ Ð² ÑÑ‚Ð¾Ð¼
                                    Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ
                                    Ð´Ð°Ð²Ð½Ð¾. Ð’ÑÐµÐ³Ð´Ð° Ð²ÑÐµ Ð²ÐºÑƒÑÐ½Ð¾Ðµ Ð¸ ÑÐ²ÐµÐ¶ÐµÐµ. ÐžÑ‡ Ð±Ñ‹ÑÑ‚Ñ€Ð°Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ°!
                                    ÐžÑ‚Ð´ÐµÐ»ÑŒÐ½Ð¾ Ñ…Ð¾Ñ‡Ñƒ Ð¾Ñ‚Ð¼ÐµÑ‚Ð¸Ñ‚ÑŒ, Ñ‡Ñ‚Ð¾ Ð² ÑÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚Ðµ ÐµÑÑ‚ÑŒ Ð²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾ÑÑ‚ÑŒ ÑÐ´Ð°Ñ‚ÑŒ Ð¿Ð»Ð°ÑÑ‚Ð¸ÐºÐ¾Ð²Ñ‹Ðµ ÐºÑ€Ñ‹ÑˆÐµÑ‡ÐºÐ¸
                                    Ð¸
                                    Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸ ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ ÑÐºÐ¸Ð´ÐºÑƒ Ð½Ð° Ð±ÑƒÐ´ÑƒÑ‰Ð¸Ðµ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸! Ð§ÐµÐ¼ Ð±Ð¾Ð»ÑŒÑˆÐµ ÑÐ´Ð°ÑˆÑŒ, Ñ‚ÐµÐ¼ Ð±Ð¾Ð»ÑŒÑˆÐµ
                                    ÑÐºÐ¸Ð´ÐºÐ°. Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={natasha}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>ÐÐ°Ñ‚Ð°Ð»ÑŒÑ</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>ÐžÐ±Ð¾Ð¶Ð°ÑŽ Ecomarket!
                                    Ð—Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°ÑŽ ÑƒÐ¶Ðµ Ð²Ñ‚Ð¾Ñ€Ð¾Ð¹ Ð³Ð¾Ð´. Ð’ÑÐµÐ³Ð´Ð° Ð±Ñ‹ÑÑ‚Ñ€Ð°Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ°, ÑÐ²ÐµÐ¶Ð°Ð¹ÑˆÐ¸Ðµ Ð¸
                                    Ð²ÐºÑƒÑÐ½Ñ‹Ðµ, Ð³Ð»Ð°Ð²Ð½Ð¾Ðµ Ð½Ð°Ñ‚ÑƒÑ€Ð°Ð»ÑŒÐ½Ñ‹Ðµ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹! Ð•ÑÑ‚ÑŒ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ñ… Ð² Ð¾Ð±Ñ‹Ñ‡Ð½Ð¾Ð¼ Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ
                                    Ð½ÐµÑ‚.
                                    Ð•Ñ‰Ñ‘ Ð¿Ñ€Ð¸Ð½Ð¸Ð¼Ð°ÑŽÑ‚ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸, Ñ„Ð»Ð¾Ð¼Ð°ÑÑ‚ÐµÑ€Ñ‹ Ð¸ ÐºÑ€Ñ‹ÑˆÐºÐ¸, Ð¸ Ð·Ð° ÑÑ‚Ð¾ Ð½Ð°Ñ‡Ð¸ÑÐ»ÑÑŽÑ‚ Ð±Ð°Ð»Ð»Ñ‹. Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾ Ð’Ð°Ð¼
                                    Ð±Ð¾Ð»ÑŒÑˆÐ¾Ðµ Ð·Ð° Ð’Ð°Ñˆ ÑÐµÑ€Ð²Ð¸Ñ!
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={svetlana}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>Ð¡Ð²ÐµÑ‚Ð»Ð°Ð½Ð°</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>Ð¡ Ð»ÐµÑ‚Ð° Ð¿Ð¾Ð»ÑŒÐ·ÑƒÑŽÑÑŒ
                                    Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹ ! Ð’ÑÐµÐ³Ð´Ð° ÑÐ²ÐµÐ¶Ð¸Ðµ Ð¸ Ð²ÐºÑƒÑÐ½Ñ‹Ðµ Ð¾Ð²Ð¾Ñ‰Ð¸ Ð¸ Ñ„Ñ€ÑƒÐºÑ‚Ñ‹ , Ð±Ð¾Ð»ÑŒÑˆÐ¾Ð¹
                                    Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð². ÐžÑ‡ÐµÐ½ÑŒ Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ ÑÐ¸ÑÑ‚ÐµÐ¼Ð° Ð±Ð¾Ð½ÑƒÑÐ¾Ð² , Ñ‚ÐµÐ¿ÐµÑ€ÑŒ Ð²ÑÐµÐ¹ ÑÐµÐ¼ÑŒÐµÐ¹ ÑÐ¾Ð±Ð¸Ñ€Ð°ÐµÐ¼
                                    ÐºÑ€Ñ‹ÑˆÐºÐ¸
                                    Ð¸ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸ Ð½Ð° Ð¿ÐµÑ€ÐµÑ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ!
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={angelina}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>ÐÐ½Ð³ÐµÐ»Ð¸Ð½Ð°</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>ÐžÑ‡ÐµÐ½ÑŒ Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ
                                    Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚ Ñ‚ÐµÐ¼, Ñ‡Ñ‚Ð¾ Ð´ÐµÐ»Ð°Ñ Ð·Ð°ÐºÐ°Ð· Ð² 12 Ð½Ð¾Ñ‡Ð¸, ÑƒÐ¶Ðµ Ðº 9 ÑƒÑ‚Ñ€Ð° Ð¿Ð¾Ð»ÑƒÑ‡Ð°ÑŽ Ð´Ð¾Ð¼Ð¾Ð¹
                                    Ð³Ð¾Ñ‚Ð¾Ð²Ñ‹Ð¹ Ð·Ð°Ð²Ñ‚Ñ€Ð°Ðº ÑÐ¾ ÑÐ²ÐµÐ¶Ð¸Ð¼Ð¸ ÑÑ‹Ñ€Ð½Ð¸ÐºÐ°Ð¼Ð¸ Ð¸ Ð±Ð»Ð¸Ð½Ñ‡Ð¸ÐºÐ°Ð¼Ð¸) Ð¢Ð°ÐºÐ¶Ðµ Ñƒ Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ð° ÑÑƒÐ¿ÐµÑ€ ÐºÐ¾Ð½Ñ†ÐµÐ¿Ñ†Ð¸Ñ
                                    Ñ
                                    ÐºÑ€Ñ‹ÑˆÐµÑ‡ÐºÐ°Ð¼Ð¸ Ð¸ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ°Ð¼Ð¸, ÐºÐ¾Ñ‚Ð¾Ñ€Ð°Ñ Ð½Ðµ Ð¾ÑÑ‚Ð°Ð²Ð¸Ð»Ð° Ð¼ÐµÐ½Ñ Ñ€Ð°Ð²Ð½Ð¾Ð´ÑƒÑˆÐ½Ð¾Ð¹))
                                </SText>
                            </div>
                        </div>

                        <div className={styles.commentCard}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={anna}/>
                                <SText size={28} weight={900} lineHeight={20} color={'#005983'}>ÐÐ½Ð½Ð°</SText>
                            </div>
                            <div>
                                <SText size={16} weight={400} lineHeight={20} color={'#000'}>Ð”Ð¾Ð±Ñ€Ð¾Ð³Ð¾ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸
                                    ÑÑƒÑ‚Ð¾Ðº!
                                    Ð¯ Ð½Ðµ Ñ‚Ð°Ðº Ñ‡Ð°ÑÑ‚Ð¾ Ð·Ð°ÐºÐ°Ð·Ñ‹Ð²Ð°ÑŽ Ð² ÑÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚Ðµ. ÐžÐ±Ñ‹Ñ‡Ð½Ð¾ ÑÑ‚Ð¾ Ð¾Ð²Ð¾Ñ‰Ð¸ Ð¸
                                    Ð¼Ð¾Ñ€ÐµÐ¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñ‹ (Ñ€Ñ‹Ð±Ð°, ÐºÑ€ÐµÐ²ÐµÑ‚ÐºÐ¸). ÐÐ¾ Ð¾Ð´Ð½Ð¾ Ð¼Ð½Ðµ Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ Ñ‚Ð¾Ñ‡Ð½Ð¾ - ÑÑ‚Ð¾ ÑÐ±Ð¾Ñ€ ÐºÑ€Ñ‹ÑˆÐµÑ‡ÐµÐº,
                                    Ð±Ð°Ñ‚Ð°Ñ€ÐµÐµÐº,
                                    Ð·Ð° ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ð½Ð°Ñ‡Ð¸ÑÐ»ÑÑŽÑ‚ Ð±Ð°Ð»Ð»Ñ‹ Ð½Ð° ÑÐ»ÐµÐ´ÑƒÑŽÑ‰Ð¸Ð¹ Ð·Ð°ÐºÐ°Ð· :)
                                </SText>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>*/
            : <div className={styles.commentMobileWrapper}>

                <div className={styles.commentMobile}>
                    <div className={styles.messageImg}><img src={olga}/></div>
                    <div className={styles.message}>
                        <div style={{marginBottom: 15}}><SText size={22} weight={900} lineHeight={20}
                                                               color={'#005983'}>ÐžÐ»ÑŒÐ³Ð°</SText></div>
                        <div><SText size={14} weight={400} lineHeight={16} color={'#000'}>ÐŸÐ¾ÐºÑƒÐ¿Ð°ÑŽ Ð² ÑÑ‚Ð¾Ð¼
                            Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ
                            Ð´Ð°Ð²Ð½Ð¾. Ð’ÑÐµÐ³Ð´Ð° Ð²ÑÐµ Ð²ÐºÑƒÑÐ½Ð¾Ðµ Ð¸ ÑÐ²ÐµÐ¶ÐµÐµ. ÐžÑ‡ Ð±Ñ‹ÑÑ‚Ñ€Ð°Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ°! ÐžÑ‚Ð´ÐµÐ»ÑŒÐ½Ð¾ Ñ…Ð¾Ñ‡Ñƒ Ð¾Ñ‚Ð¼ÐµÑ‚Ð¸Ñ‚ÑŒ, Ñ‡Ñ‚Ð¾ Ð²
                            ÑÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚Ðµ ÐµÑÑ‚ÑŒ Ð²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾ÑÑ‚ÑŒ ÑÐ´Ð°Ñ‚ÑŒ Ð¿Ð»Ð°ÑÑ‚Ð¸ÐºÐ¾Ð²Ñ‹Ðµ ÐºÑ€Ñ‹ÑˆÐµÑ‡ÐºÐ¸ Ð¸ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸ ÐºÑƒÑ€ÑŒÐµÑ€Ñƒ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ ÑÐºÐ¸Ð´ÐºÑƒ
                            Ð½Ð°
                            Ð±ÑƒÐ´ÑƒÑ‰Ð¸Ðµ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸! Ð§ÐµÐ¼ Ð±Ð¾Ð»ÑŒÑˆÐµ ÑÐ´Ð°ÑˆÑŒ, Ñ‚ÐµÐ¼ Ð±Ð¾Ð»ÑŒÑˆÐµ ÑÐºÐ¸Ð´ÐºÐ°. Ð¡Ð¿Ð°ÑÐ¸Ð±Ð¾
                        </SText></div>
                        <div className={styles.angle}>
                            <img src={messageAngle}/>
                        </div>
                    </div>
                </div>

                <div style={{alignSelf: 'flex-end'}} className={styles.commentMobile}>
                    <div className={styles.message}>
                        <div style={{marginBottom: 15}}><SText size={22} weight={900} lineHeight={20}
                                                               color={'#005983'}>Ð¡Ð²ÐµÑ‚Ð»Ð°Ð½Ð°</SText></div>
                        <div><SText size={14} weight={400} lineHeight={16} color={'#000'}>Ð¡ Ð»ÐµÑ‚Ð° Ð¿Ð¾Ð»ÑŒÐ·ÑƒÑŽÑÑŒ
                            Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹
                            ! Ð’ÑÐµÐ³Ð´Ð° ÑÐ²ÐµÐ¶Ð¸Ðµ Ð¸ Ð²ÐºÑƒÑÐ½Ñ‹Ðµ Ð¾Ð²Ð¾Ñ‰Ð¸ Ð¸ Ñ„Ñ€ÑƒÐºÑ‚Ñ‹ , Ð±Ð¾Ð»ÑŒÑˆÐ¾Ð¹ Ð°ÑÑÐ¾Ñ€Ñ‚Ð¸Ð¼ÐµÐ½Ñ‚ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð². ÐžÑ‡ÐµÐ½ÑŒ Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ
                            ÑÐ¸ÑÑ‚ÐµÐ¼Ð°
                            Ð±Ð¾Ð½ÑƒÑÐ¾Ð² , Ñ‚ÐµÐ¿ÐµÑ€ÑŒ Ð²ÑÐµÐ¹ ÑÐµÐ¼ÑŒÐµÐ¹ ÑÐ¾Ð±Ð¸Ñ€Ð°ÐµÐ¼ ÐºÑ€Ñ‹ÑˆÐºÐ¸ Ð¸ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ¸ Ð½Ð° Ð¿ÐµÑ€ÐµÑ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ!
                        </SText></div>
                        <div className={styles.angleRight}>
                            <img src={messageAngle}/>
                        </div>
                    </div>
                    <div className={styles.messageImg} style={{marginLeft: 20, marginRight: 0}}><img
                        src={svetlana}/></div>

                </div>

                <div className={styles.commentMobile}>
                    <div className={styles.messageImg}><img src={angelina}/></div>
                    <div className={styles.message}>
                        <div style={{marginBottom: 15}}><SText size={22} weight={900} lineHeight={20}
                                                               color={'#005983'}>ÐÐ½Ð³ÐµÐ»Ð¸Ð½Ð°</SText></div>
                        <div><SText size={14} weight={400} lineHeight={16} color={'#000'}>ÐžÑ‡ÐµÐ½ÑŒ Ð½Ñ€Ð°Ð²Ð¸Ñ‚ÑÑ
                            Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚
                            Ñ‚ÐµÐ¼, Ñ‡Ñ‚Ð¾ Ð´ÐµÐ»Ð°Ñ Ð·Ð°ÐºÐ°Ð· Ð² 12 Ð½Ð¾Ñ‡Ð¸, ÑƒÐ¶Ðµ Ðº 9 ÑƒÑ‚Ñ€Ð° Ð¿Ð¾Ð»ÑƒÑ‡Ð°ÑŽ Ð´Ð¾Ð¼Ð¾Ð¹ Ð³Ð¾Ñ‚Ð¾Ð²Ñ‹Ð¹ Ð·Ð°Ð²Ñ‚Ñ€Ð°Ðº ÑÐ¾ ÑÐ²ÐµÐ¶Ð¸Ð¼Ð¸
                            ÑÑ‹Ñ€Ð½Ð¸ÐºÐ°Ð¼Ð¸
                            Ð¸ Ð±Ð»Ð¸Ð½Ñ‡Ð¸ÐºÐ°Ð¼Ð¸) Ð¢Ð°ÐºÐ¶Ðµ Ñƒ Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ð° ÑÑƒÐ¿ÐµÑ€ ÐºÐ¾Ð½Ñ†ÐµÐ¿Ñ†Ð¸Ñ Ñ ÐºÑ€Ñ‹ÑˆÐµÑ‡ÐºÐ°Ð¼Ð¸ Ð¸ Ð±Ð°Ñ‚Ð°Ñ€ÐµÐ¹ÐºÐ°Ð¼Ð¸, ÐºÐ¾Ñ‚Ð¾Ñ€Ð°Ñ Ð½Ðµ
                            Ð¾ÑÑ‚Ð°Ð²Ð¸Ð»Ð°
                            Ð¼ÐµÐ½Ñ Ñ€Ð°Ð²Ð½Ð¾Ð´ÑƒÑˆÐ½Ð¾Ð¹))
                        </SText></div>
                        <div className={styles.angle}>
                            <img src={messageAngle}/>
                        </div>
                    </div>
                </div>

            </div>}


    </div>
}

export default SaveNature