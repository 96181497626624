import React, { useContext, useEffect, useState } from 'react'
import styles from './Cart.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useQueryParams } from "../../components/utils/ModalSpectator";
import HelperContext from "../../redux/HelperContext";
import { fetchUserData, refreshOrders, updateAddress, updateUserData } from "../../redux/reducers/user";
import { useCoolDown, useWindowDimensions } from "../../components/utils/hooks";
import moment from "moment";
import { cartClear } from "../../redux/reducers/cart";
import { getCookie } from "../../mobile/utils";
import sendRequest from "../../api";
import { getCategoryName, prettyDate, roundPrice } from "../../components/utils/other";
import { getDeliverySlots } from "../../redux/reducers/deliverySlots";
import { Helmet } from "react-helmet";
import { User } from "../../components/HeaderComponents/Header";
import AccountNavigationModal from "../UserAccount/AccountNavigationModal";
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import getBack from '../../assets/images/chevron-up.svg'
import Products from "./components/Products";
import AddressBlock from "./components/AddressBlock";
import clock from "../../assets/images/cart/clock.svg";
import arrow from "../../assets/images/userAccount/personalData/changeAddress.svg";
import SlotsModal from "./components/SlotsModal";
import PaymentAndPromocode from "./components/PaymentAndPromocode";
import AddCard from "../AddCardScreen/AddCard";
import OrderBtn from "./components/OrderBtn";

const NewSimpleCart = () => {

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7'
        return () => {
            document.body.style.backgroundColor = '#fff'
        }
    }, [])

    const cartState = useSelector(state => state.cart)
    const cart = cartState.order
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { push } = useQueryParams()
    const [openLk, setOpenLk] = useState(false)
    const [orderButtonClicked, setOrderButtonClicked] = useState(false)

    const prods = useSelector(state => state.catalogue.products)
    const deliverySlots = useSelector(state => state.deliverySlots)
    const selectedSlot = deliverySlots.selectedSlot
    const [disabled, setDisabled] = useState(false)
    const [tips, setTips] = useState({ courier: 0, assembler: 0 })
    const [slotModal, setSlotModal] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const [pay, setPay] = useState(false)
    const [sbp, setSbp] = useState(false)
    const [sberPay, setSberPay] = useState(false)
    const [orderResponse, setOrderResponse] = useState({})

    const {
        totals,
        delivery,
        promoCodeData,
        useBonuses,
        selectedMethod,
        paymentMethods,
        resetPromoCode
    } = useContext(HelperContext)

    function onTipsSelect(type, value) {
        if (selectedMethod === 'cash') {
        } else {
            setTips(prev => ({ ...prev, [type]: value }))
        }
    }

    useEffect(() => {
        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('makeOrder');
        }
    }, [])

    useEffect(() => {
        if (selectedMethod === 'cash') {
            setTips({ courier: 0, assembler: 0 })
        }
    }, [selectedMethod])

    const [errors, setErrors] = useState([])

    const [userInfo, setUserInfo] = useState({
        name: user.name,
        address: user.address,
        phone: user.phone,
    })

    useEffect(() => {
        setUserInfo({
            name: user.name,
            address: user.address,
            phone: user.phone,
        })
    }, [user])

    function compareUserInfo() {
        const isSame = (
            userInfo.name === user.name &&
            userInfo.phone === user.phone &&
            userInfo.address.number === user.address.number &&
            userInfo.address.entrance === user.address.entrance &&
            userInfo.address.floor === user.address.floor &&
            userInfo.address.comment === user.address.comment
        )
        if (isSame) return

        dispatch(updateUserData({ name: userInfo.name, phone: userInfo.phone }))
        dispatch(updateAddress(userInfo.address))
    }

    const delayedCompareFn = useCoolDown(compareUserInfo, 1000)

    useEffect(() => {
        delayedCompareFn()
    }, [userInfo])

    useEffect(() => {
        if (cart?.length === 0)
            navigate(-1)
    }, [cart])

    function preOrderCheck() {
        let foundErrors = []
        /*        if (!userInfo.name)
                    foundErrors.push('name')*/

        if ((!userInfo.phone || userInfo.phone.length < 16))
            foundErrors.push('phone')

        if (
            !selectedSlot.date ||
            !selectedSlot.time ||
            (moment(selectedSlot.date + ' ' + selectedSlot.time.split('-')[1], 'YYYY-MM-DD HH:mm').isBefore(moment()) && selectedSlot.time.split('-')[1] !== '00:00')
        )
            foundErrors.push('slot')

        if (!user.address?.gps?.[0])
            foundErrors.push('address')

        setErrors(foundErrors)
    }

    useEffect(() => {
        preOrderCheck()
    }, [userInfo, selectedSlot, user.address])

    async function onReady(id, security, newUser, number) {

        if (!newUser) {
            window.orderId = id
            window.security = security
        } else if (newUser) {
            window.newUserOrderId = number
            window.newUserPhone = userInfo.phone
        }
        navigate('/')
        setDisabled(false)
    }

    async function onSubmit() {

        setOrderButtonClicked(true)
        if (errors.includes('phone')) {
            window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°')
            return
        }

        /*        if (errors.includes('name')) {
                    window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°ÑˆÐµ Ð¸Ð¼Ñ')
                    return
                }*/
        if (errors.includes('address')) {
            window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð°Ð´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸')
            return
        }
        if (errors.includes('slot')) {
            window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸')
            return
        }
        if (disabled) return
        setDisabled(true)
        const selectedPaymentMethod = paymentMethods.find(m => m.type === selectedMethod)

        const payload = {
            apikey: user.apikey,
            products: cartState.items,
            name: userInfo.name,
            email: user.email || '',
            adress: user.address.name,
            phone: user.phone,
            coupon: promoCodeData.name || '',
            bonuses: useBonuses ? totals.availableBonuses : 0,
            date: selectedSlot.date,
            time: selectedSlot.time,
            delivery: 2,
            source: 0,
            payment: selectedPaymentMethod?.value == 9 ? 3 : selectedPaymentMethod?.value || 1,
            point: false,
            comment: userInfo.address.comment,
            appartment: userInfo.address.number,
            floor: userInfo.address.floor,
            entrance: userInfo.address.entrance,
            mkad: deliverySlots.data.km, // todo ÑƒÐ±Ñ€Ð°Ñ‚ÑŒ Ð½Ð° Ð±ÑÐºÐµ
            delivery_addon: Math.max(0, delivery.regularPrice - delivery.promoCodeDiscount) || false, // todo ÑƒÐ±Ñ€Ð°Ñ‚ÑŒ Ð½Ð° Ð±ÑÐºÐµ
            region: deliverySlots.data.region, // todo ÑƒÐ±Ñ€Ð°Ñ‚ÑŒ Ð½Ð° Ð±ÑÐºÐµ
            gps: user.address.gps,
            card: false,
            confirmationRequired: true,
            test_case: getCookie().test_case,
            build: 1,
            sid: deliverySlots.data?.sid || null,
            cartTimings: cartState.timings,
        }

        const response = await sendRequest('submitOrderFromApp_v2', payload)
        // const response = {
        //     number: 'test',
        //     order: 1311557
        // }
        setOrderResponse(response)

        let products = cartState.order.map(id => {
            const prod = prods[id]
            if (prod) {
                return {
                    'id': prod.id,
                    'name': prod.title,
                    'price': prod.price,
                    'brand': prod.brand || null,
                    'category': getCategoryName(prod.all_groups[0]) || null,
                    'quantity': cartState.items[parseInt(id)]
                }
            } else return {}
        })

        let items = cartState.order.map(id => {
            const prod = prods[id]
            if (prod) {
                return {
                    'item_id': prod.id,
                    'item_name': prod.title,
                    'price': prod.price,
                    'item_brand': prod.brand || null,
                    'item_category': getCategoryName(prod.all_groups[0]) || null,
                    'item_category2': getCategoryName(prod.all_groups[1]) || null,
                    'item_category3': getCategoryName(prod.all_groups[2]) || null,
                    'item_category4': getCategoryName(prod.all_groups[3]) || null,
                    'item_category5': getCategoryName(prod.all_groups[4]) || null,
                    'quantity': cartState.items[parseInt(id)],
                    'discount': prod.old_price ? (prod.old_price - prod.price) : null
                }
            } else return {}
        })

        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('MADE_A_PURCHASE');
        }

        try {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'purchase': {
                        'actionField': {
                            'id': response.number,
                            'revenue': `${totals.finalTotals.actual}`,
                            'shipping': `${delivery.regularPrice}`,
                            'coupon': promoCodeData.name || null
                        },
                        'products': products,
                    },
                    'items': items,
                },
                'event': 'gtm-ee-event',
                'event-GA4': 'purchase',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
                'payment_type': selectedPaymentMethod?.value === 1 ? 'cash' : selectedPaymentMethod?.value === 3 ? 'card' : null,
                'order_type': user.orders.total > 0 ? 'repeat' : 'new',
            });

        } catch (error) {
            console.log(error)
        }

        if (!response.order) {
            window.alert('ÐŸÑ€Ð¾Ð¸Ð·Ð¾ÑˆÐ»Ð° Ð¾ÑˆÐ¸Ð±ÐºÐ°')
            setDisabled(false)
            return
        }

        await dispatch(fetchUserData())
        await dispatch(refreshOrders())
        await dispatch(cartClear())

        resetPromoCode()

        if (selectedPaymentMethod.cardId) {
            const payload = {
                order: response.order,
                card: selectedPaymentMethod.cardId,
                apikey: user.apikey
            }
            await sendRequest('addCardToOrder', payload)
        }

        if ((selectedPaymentMethod.value === 3 || selectedPaymentMethod.value === 13 || selectedPaymentMethod.value === 9) && !selectedPaymentMethod.cardId) {
            if (selectedPaymentMethod.value === 13) {
                setSbp(true)
            }
            if (selectedPaymentMethod.value === 9) {
                setSberPay(true)
            }

            setPay(true)
            setOrderId(response.order)
            setDisabled(false)
            return
        }


        return await onReady(response.order, response.security, response.newUser, response.number)
    }

    function refreshSlots(e) {
        e.stopPropagation()
        dispatch(getDeliverySlots())
    }

    // if (cart.length === 0) {
    //     navigate('/')
    //     return null
    // }

    return <div>
        <Helmet>
            <title>{ 'ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°' }</title>
        </Helmet>

        <div className={ styles.header }>
            <User user={ user } setOpenLk={ setOpenLk } />
            <NavLink className={ styles.logo }
                style={ { lineHeight: '32px !important', cursor: 'pointer', userSelect: 'none' } }
                onClick={ () => window.scrollTo(0, 0) } to={ '/' }>
                { 'Ecomarket' }
            </NavLink>
            <div>
                <SText weight={ 900 } size={ 26 } lineHeight={ 32 } color={ Colors.darkGray }>{ 'ÐžÑ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ðµ Ð·Ð°ÐºÐ°Ð·Ð°' }</SText>
            </div>
        </div>

        <div className={ styles.content }>
            <div style={ { display: "flex" } }>
                <NavLink to={ '/' }>
                    <div className={ styles.getBackBtn }>
                        <img src={ getBack } />
                    </div>
                </NavLink>

                <div className={ styles.products }>
                    <Products />
                </div>
            </div>

            <div>
                <div style={ { marginBottom: 20 } } className={ styles.addressBlock }>
                    <AddressBlock orderButtonClicked={ orderButtonClicked } errors={ errors } data={ userInfo } setter={ setUserInfo } />
                    { !!selectedSlot.date && <div className={ styles.slotsWrapper } onClick={ () => setSlotModal(true) }>
                        <div style={ { display: 'flex', alignItems: 'center' } }>
                            <img style={ { marginRight: 12 } } src={ clock } />
                            { !selectedSlot.date
                                ? <div onClick={ refreshSlots }>
                                    <div><SText size={ 14 } weight={ 700 }
                                        color={ Colors.red }>{ 'ÐÐµ ÑƒÐ´Ð°Ð»Ð¾ÑÑŒ Ð·Ð°Ð³Ñ€ÑƒÐ·Ð¸Ñ‚ÑŒ ÑÑ€Ð¾ÐºÐ¸ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸' }</SText></div>
                                    <div><SText size={ 14 } weight={ 700 }
                                        color={ Colors.red }>{ 'ÐÐ°Ð¶Ð¼Ð¸Ñ‚Ðµ, Ñ‡Ñ‚Ð¾Ð±Ñ‹ Ð¿Ð¾Ð²Ñ‚Ð¾Ñ€Ð¸Ñ‚ÑŒ' }</SText></div>
                                </div>
                                : <div style={ { display: 'flex', alignItems: 'flex-end', gap: 13 } }>
                                    <div>
                                        <SText size={ 16 } weight={ 700 } lineHeight={ 16 }
                                            color={ Colors.darkGray }>{ prettyDate(moment(selectedSlot.date, 'YYYY-MM-DD'), true) }</SText>
                                    </div>
                                    <div>
                                        { selectedSlot.time && <SText size={ 14 } weight={ 700 } gap lineHeight={ 14 }
                                            color={ Colors.darkGray }>{ `c ${selectedSlot.time.replace('-', ' Ð´Ð¾ ')}` }</SText> }
                                    </div>
                                </div> }
                            <img style={ { marginLeft: 12 } } src={ arrow } />
                        </div>
                    </div> }
                </div>

                <div style={ {
                    padding: '20px 48px 24px 21px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    gap: 75,
                    marginBottom: 20
                } } className={ styles.addressBlock }>
                    <div>
                        <PaymentAndPromocode />
                        <div className={ styles.orderBtnWrapper }>
                            <OrderBtn disabled={ (!userInfo.phone || userInfo.phone.length < 16) || !user.address?.gps?.[0] || errors.length !== 0 || disabled } onPress={ onSubmit } />
                        </div>
                    </div>

                    <div className={ styles.priceBlock }>
                        <div style={ { marginBottom: 17 } }><SText size={ 20 } weight={ 700 } color={ Colors.darkGray }
                            lineHeight={ 25 }>{ 'Ð˜Ñ‚Ð¾Ð³Ð¾:' }</SText></div>
                        <div>
                            <div className={ styles.priceLine }><SText size={ 14 } weight={ 500 }
                                lineHeight={ 17 }>{ 'ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°' }</SText> <SText
                                    size={ 14 }
                                    weight={ 500 }
                                    lineHeight={ 17 }>{ `${roundPrice(totals.cart.old)} â‚½` }</SText>
                            </div>
                            <div className={ styles.priceLine }><SText size={ 14 } weight={ 500 }
                                lineHeight={ 17 }>{ 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°' }</SText> <SText
                                    size={ 14 }
                                    weight={ 500 }
                                    lineHeight={ 17 }>{ `${roundPrice(totals.deliveryPrice)} â‚½` }</SText>
                            </div>
                            { (totals.finalTotals.actual - totals.finalTotals.old) !== 0 && <div className={ styles.priceLine }><SText size={ 14 } weight={ 500 }
                                lineHeight={ 17 }>{ 'Ð¡ÐºÐ¸Ð´ÐºÐ°' }</SText>
                                <SText size={ 14 }
                                    weight={ 500 }
                                    lineHeight={ 17 }>{ `${roundPrice(totals.finalTotals.actual - totals.finalTotals.old)} â‚½` }</SText>
                            </div> }
                        </div>
                        <div style={ { textAlign: 'right', marginBottom: 21 } }><SText size={ 22 }
                            weight={ 900 }
                            lineHeight={ 18 }>{ `${roundPrice(totals.finalTotals.actual)} â‚½` }</SText>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        { slotModal && <SlotsModal onClose={ () => setSlotModal(false) } /> }

        { openLk && <AccountNavigationModal onClose={ () => {
            window.scrollTo(0, 0)
            setOpenLk(false)
        } } /> }

        { (pay && orderId) &&
            <AddCard sbp={ sbp } sberPay={ sberPay } orderId={ orderId } onSuccess={ () => onReady(orderResponse.order, orderResponse.security, orderResponse.newUser, orderResponse.number) } onClose={ () => setPay(false) } /> }
    </div>
}

export default NewSimpleCart