import React from "react";
import styles from './NotFound.module.scss'
import SText from "../../components/SText";
import Colors from "../../components/utils/Colors";
import {NavLink} from "react-router-dom";

const NotFound = () => {

    return <div className={styles.wrapper}>
        <div className={styles.title}><SText size={50} weight={900} color={Colors.green}>{'404'}</SText></div>
        <div className={styles.subtitle}><SText size={18} weight={700} color={Colors.darkGray}>{'Ðš ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ, Ð´Ð°Ð½Ð½Ð¾Ð¹ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹ Ð½ÐµÑ‚ Ð½Ð° Ð½Ð°ÑˆÐµÐ¼ ÑÐ°Ð¹Ñ‚Ðµ!'}</SText></div>
        <NavLink to={'/'}><div className={styles.btn}><SText size={16} weight={500} color={Colors.darkGray}>{'ÐÐ° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ'}</SText></div></NavLink>
    </div>
}

export default NotFound